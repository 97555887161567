import {BrowserRouter, Router,  Switch, Route} from 'react-router-dom';
import  App  from './App';
import Calculation from "./containers/Calculation"
import CalculationList from './containers/CalculationList'
import Account from './containers/Account'
import Login from './containers/Login'

import history from './history';

export const AppRouter = () => (
    <BrowserRouter history={history}>
        <App>
            <Route exact path={"/"} component={Login} />
            <Route exact path={"/calc/"} component={Calculation} />
            <Route exact path={"/calc/:projectId/calculation/:calculationId"}
                       render={(props) => <Calculation projectId={props.match.params.projectId} calculationId={props.match.params.calculationId}/> }
                       name="calculation"/>
            <Route exact path={"/calc/:projectId/"}
                       render={(props) => <Calculation projectId={props.match.params.projectId}/> }
                       name="calculation"/>
            <Route exact path={"/calcList"} component={CalculationList} />
            <Route exact path={"/account"} component={Account} />
            <Route exact path={"/login"} component={Login} />
        </App>
    </BrowserRouter>
)