import React from 'react';
import { Link } from 'react-router-dom';
import { RegistrationWrapper } from './services/RegistrationWrapper'

const Register = ({
    wrapperState: {
        password,
        lastname,
        firstname,
        email,
        phoneNumber,
        city,
        country,
        street,
        postCode,
        NIP,
        companyName,
        confirmPassword
    },
    onSubmit,
    setAnyValue,
}) => (
    <form onSubmit={onSubmit}>
        <div>
            <label>Imię</label>            
            <input name='firstname' type="text" value={firstname} onChange={setAnyValue} />
        </div>
        <div>
            <label>Nazwisko</label>            
            <input name='lastname' type="text" value={lastname} onChange={setAnyValue} />
        </div>
        <div>
            <label>Email</label>                
            <input name='email' type="email" value={email} onChange={setAnyValue} />
        </div>
        <div>
            <label>Telefon</label>            
            <input name='phoneNumber' type="phone" value={phoneNumber} onChange={setAnyValue} />
        </div>
        <div>   
            <label>Hasło</label>            
            <input name='password' type="password" value={password} onChange={setAnyValue} />
        </div>
        <div>   
            <label>Powtórz hasło</label>            
            <input name='confirmPassword' type="password" value={confirmPassword} onChange={setAnyValue} />
        </div>
        <div>
            <label>Miasto</label>            
            <input name='city' type="text" value={city} onChange={setAnyValue} />
        </div>
        <div>
            <label>Kraj</label>            
            <input name='country' type="text" value={country} onChange={setAnyValue} />
        </div>
        <div>
            <label>Ulica</label>            
            <input name='street' type="text" value={street} onChange={setAnyValue} />
        </div>
        <div>
            <label>Kod pocztowy</label>            
            <input name='postCode' type="text" value={postCode} onChange={setAnyValue} />
        </div>
        <div>
            <label>NIP</label>            
            <input name='NIP' type="text" value={NIP} onChange={setAnyValue} />
        </div>
        <div>   
            <label>Nazwa firmy</label>            
            <input name="companyName" type="text" value={companyName} onChange={setAnyValue} />
        </div>
        <div>
            <button type='submit' className="buttonLogin">Zarejestruj</button>
        </div>
    </form>
)

const RegisterWithWrapper = props => (
    <RegistrationWrapper
        render={({ state, onSubmit, setAnyValue }) =>
            <Register
                wrapperState={state}
                onSubmit={onSubmit}
                setAnyValue={setAnyValue}
                {...props}
            />
        }
    />
)

export default RegisterWithWrapper