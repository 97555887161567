import React from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import ReactModal from "react-modal";

import "../style.css";
import {
  createProject,
  setCurrentProject,
  resetCalculation,
  selectCalculation,
  removeProject,
  removeCalculations,
  loadCustomerProjects,
} from "../actions/calculationsAction";
import { ReportPopup } from "../components/ReportPopup";
import { getCurrentDate, parseDate } from "../utils/utils";

import { withRouter } from "react-router";
import { useProjectAPIActions } from "../components/services/ProjectWrapper";
import { useCalculationAPIActions } from "../components/services/CalculationWrapper";

class CalculationList extends React.Component {
  componentDidMount() {
    const { projectList } = this.props.calculations;
    let { companyName, street, city, postCode, phoneNumber, email, discount } =
      this.props.account.user;
    let visibility = {};
    projectList.forEach((project) => {
      visibility[project.id] = false;
    });
    this.setState({
      projectVisible: visibility,
      accountData: {
        companyName: companyName,
        address: street,
        address2: `${postCode} ${city}`,
        phone: phoneNumber,
        email: email,
        discount: discount,
      },
    });
  }

  constructor() {
    super();
    this.state = {
      projectVisible: {},
      showNewProjectModal: false,
      projectName: "",
      projectNameForReport: "",

      showPrintModal: false,
      showDeleteModal: false,
      accountData: {
        companyName: "",
        address: "",
        address2: "",
        phone: "",
        email: "",
        discount: "",
      },
      clientData: {
        companyName: "",
        address: "",
        address2: "",
        phone: "",
        email: "",
        discount: "",
      },
      documentType: "",
      documentTitle: "",

      redirect: false,

      selectedProject: -1,
      wholeProjectSelected: false,
      selectedCalculations: [],
      generateReport: false,
      reportDate: getCurrentDate(),
      projectId: "",
      calculationId: "",
      selectedProjectID: "",
    };

    this.toggleProjectVisible = this.toggleProjectVisible.bind(this);
    this.handleOpenNewProjectModal = this.handleOpenNewProjectModal.bind(this);
    this.handleCloseNewProjectModal =
      this.handleCloseNewProjectModal.bind(this);
    this.setProjectName = this.setProjectName.bind(this);
    //print
    this.handleOpenPrintModal = this.handleOpenPrintModal.bind(this);
    this.handleClosePrintModal = this.handleClosePrintModal.bind(this);
    this.handleChangeAccountData = this.handleChangeAccountData.bind(this);
    this.handleChangeClientData = this.handleChangeClientData.bind(this);
    this.handleChangeDocumentType = this.handleChangeDocumentType.bind(this);

    this.handleOpenDeleteModal = this.handleOpenDeleteModal.bind(this);
    this.handleCloseDeleteModal = this.handleCloseDeleteModal.bind(this);

    this.handleSelectWholeProject = this.handleSelectWholeProject.bind(this);
    this.handleSelectCalculation = this.handleSelectCalculation.bind(this);
    this.handleRemoveSelected = this.handleRemoveSelected.bind(this);
    this.handleGenerateReport = this.handleGenerateReport.bind(this);
    this.handleChangeDocumentTitle = this.handleChangeDocumentTitle.bind(this);
    this.getSelectedCalculations = this.getSelectedCalculations.bind(this);
    this.setReportDate = this.setReportDate.bind(this);
    this.setProjectNameForReport = this.setProjectNameForReport.bind(this);
    this.setComment = this.setComment.bind(this);
    this.setSignature = this.setSignature.bind(this);
  }

  setComment(comment) {
    this.setState({
      comment: comment,
    });
  }
  
  setSignature(signature) {
    this.setState({
      signature: signature,
    });
  }

  setProjectNameForReport(name) {
    this.setState({
      projectNameForReport: name,
    });
  }

  setReportDate(date) {
    this.setState({
      reportDate: date,
    });
  }
  getSelectedCalculations() {
    const { projectList } = this.props.calculations;
    const { selectedProject, selectedCalculations, wholeProjectSelected } =
      this.state;
    if (selectedProject !== -1) {
      if (wholeProjectSelected) {
        let project = projectList.find(p => p.id === selectedProject)
        return project && project.calculationList ? project.calculationList : [];
        // return projectList[selectedProject].calculationList;
      } else {
        return projectList.find(p => p.id === selectedProject).calculationList.filter((proj, id) =>
          selectedCalculations.includes(proj.calculationId)
        );
      }
    }
  }

  handleChangeDocumentTitle(title) {
    this.setState({
      documentTitle: title,
    });
  }

  handleGenerateReport() {
    this.setState({
      // generateReport: true,
      showPrintModal: false,
      documentType: "",
    });
  }

  handleRemoveSelected(
    selectedProject,
    selectedCalculations,
    wholeProjectSelected
  ) {
    if (wholeProjectSelected && selectedProject !==  -1) {
      // this.props.removeProject(selectedProject);
      this.setState({
        wholeProjectSelected: false,
        selectedProject: -1,
        selectedCalculations: [],
        showDeleteModal: false,
      });
    } else {
      // this.props.removeCalculations(selectedProject, selectedCalculations);
      this.setState({
        wholeProjectSelected: false,
        selectedCalculations: [],
        showDeleteModal: false,
      });
    }
  }

  handleSelectWholeProject(id) {
    const { projectList } = this.props.calculations;
    const index = projectList.findIndex((proj) => proj.id === id);
    const selectedProjectId = projectList[index].id;

    if (this.state.selectedProject === id) {
      //ten projekt już jest zaznaczony, trzeba go odznaczyć
      this.setState({
        selectedProject: -1,
        selectedProjectID: "",
        wholeProjectSelected: false,
        selectedCalculations: [],
        projectNameForReport: "",
      });
    } else {
      let calculations = projectList[index].calculationList.map(
        (calculation, index) => calculation.id
      );

      this.setState({
        selectedProject: selectedProjectId,
        selectedProjectID: id,
        wholeProjectSelected: true,
        selectedCalculations: calculations,
        projectNameForReport: projectList[index].name,
      });
    }
  }

  handleSelectCalculation(projectId, calculationIndex) {
    const { projectList } = this.props.calculations;
    const index = projectList.findIndex((proj) => proj.id === projectId);
    if (
      projectList[index] &&
      this.state.selectedProject === projectList[index].id
    ) {
      if (this.state.wholeProjectSelected) {
        // był zaznaczone wszystkei w tym projekcie, trzeba zabrać tą jedną
        this.setState({
          selectedCalculations: this.state.selectedCalculations.filter(
            (calcId) => {
              return calcId !== calculationIndex;
            }
          ),
          wholeProjectSelected: false,
        });
      } else {
        if (
          this.state.selectedCalculations &&
          this.state.selectedCalculations.includes(calculationIndex)
        ) {
          this.setState({
            selectedCalculations: this.state.selectedCalculations.filter(
              (calcId) => {
                return calcId !== calculationIndex;
              }
            ),
            wholeProjectSelected: false,
          });
        } else {
          this.setState({
            selectedCalculations: [
              ...this.state.selectedCalculations,
              calculationIndex,
            ],
            wholeProjectSelected:
              this.state.selectedCalculations.length ===
              projectList.find((p) => p.id === this.state.selectedProject)
                .calculationList.length,
          });
        }
      }
    } else {
      // let calculations = projectList[index].calculationList.map((project, index)=> index);
      // console.log(calculations)
      this.setState({
        selectedProject: projectId,
        wholeProjectSelected: false,
        selectedCalculations: [calculationIndex],
      });
    }
  }

  handleOpenPrintModal() {
    this.setState({ showPrintModal: true, generateReport: false });
  }

  handleClosePrintModal() {
    this.setState({
      showPrintModal: false,
      documentType: "",
    });
  }

  handleOpenDeleteModal() {
    if (this.state.selectedProject !== "") {
      this.setState({ showDeleteModal: true });
    }
  }

  handleCloseDeleteModal() {
    this.setState({ showDeleteModal: false });
  }

  handleChangeAccountData(field, value) {
    // console.log(field, value)
    // let {accountData} = this.state;
    // accountData[field] = value;
    // console.log(accountData)
    this.setState({
      accountData: {
        ...this.state.accountData,
        [field]: value,
      },
    });
  }
  handleChangeClientData(field, value) {
    this.setState({
      clientData: {
        ...this.state.clientData,
        [field]: value,
      },
    });
  }
  handleChangeDocumentType(value) {
    this.setState({
      documentType: value,
    });
    if (value !== "") {
      if (this.state.selectedCalculations.length > 0) {
        this.handleOpenPrintModal();
      } else {
        this.setState({
          documentType: "",
        });
      }
    }
  }

  setProjectName(name) {
    this.setState({
      projectNameForReport: name,
    });
  }

  createProject(name) {
    this.props.createProject(name);
    this.setState({
      showNewProjectModal: false,
      projectName: "",
    });
  }

  handleCloseNewProjectModal() {
    this.setState({
      showNewProjectModal: false,
    });
  }
  handleOpenNewProjectModal() {
    this.setState({
      showNewProjectModal: true,
    });
  }

  toggleProjectVisible(projectId) {
    let visibility = this.state.projectVisible;
    visibility[projectId] = !visibility[projectId];
    this.setState({
      projectVisible: visibility,
    });
  }

  getProjectPrice = (project) => {
    let value = 0;
    project.calculationList.forEach((calc) => {
      value += calc.price || 0;
    });
    return value;
  };

  drawTable = (tableData) => {
    tableData.map((row) => this.drawTableRow(row));
  };

  setTdClassName = (index) => {
    if(index === 0) {
      return "checkbox"
    } else if (index === 1) {
      return "projectName"
    }
    return "fixedWidth"
  }

  drawTableRow = (rowData, isHeader = false) => {
    return (
      <table className="projectsList-table">
        <tr className={isHeader ? "th" : ""}>
          {rowData.map((value, index) => (
            <td className={this.setTdClassName(index)} key={index}>
              {value}
            </td>
          ))}
        </tr>
      </table>
    );
  };
  
  drawOneColumnRow = rowData => {
    return (
      <table className="projectsList-table">
        <tr>
          <td className="projectName">
            {rowData}
          </td>
        </tr>
      </table>
    );
  }

  renderProject(project, projectList) {
    // console.log(project)
    let {
      projectVisible,
      selectedProject,
      selectedCalculations,
      wholeProjectSelected,
    } = this.state;
    // console.log(projectVisible,
    //   selectedProject,
    //   selectedCalculations,
    //   wholeProjectSelected)
    if (project) {
      return (
        <li>
          <div
            className={`clickable ${
              projectVisible[project.id] ? "visible" : ""
            } `}
          >
            {this.drawTableRow(
              [
                <input
                  type="checkbox"
                  checked={
                    !(selectedProject === -1) && project.id === selectedProject
                  }
                  onClick={() => this.handleSelectWholeProject(project.id)}
                />,
                <span onClick={() => this.toggleProjectVisible(project.id)}>
                  <span className="name">{project.name}</span><span>{`${projectVisible[project.id] ? "..." : "..."}`}</span>
                </span>,
                "",
                `${this.getProjectPrice(project)},00`,
                parseDate(project.date),
                "",
              ],
              true
            )}
          </div>
          <div
            id="project1"
            className={projectVisible[project.id] ? "" : "hidden"}
          >
            {project.calculationList.map((row, index) =>
              this.drawTableRow([
                <input
                  type="checkbox"
                  checked={
                    !(selectedProject === -1) &&
                    project.id === selectedProject &&
                    selectedCalculations &&
                    selectedCalculations.includes(row.id)
                  }
                  onClick={() =>
                    this.handleSelectCalculation(project.id, row.id)
                  }
                />,
                "",
                <div
                  className="clickable underlineHover"
                  onClick={() => {
                    // this.setState({redirect: true, projectId: project.id, calculationId: row.calculationId})
                    this.props.setCurrentProject(project.id);
                    this.props.selectCalculation(row);
                    this.props.history.push(
                      `/calc/${project.id}/calculation/${row.calculationId}`
                    );
                  }}
                >
                  {row.result.name || ""}
                </div>,
                row.result.price,
                parseDate(row.calculationDate),
                row.calculationName,
              ])
            )}
            {this.drawOneColumnRow(
              <span
                onClick={() => {
                  // this.setState({
                  //     redirect: true,
                  //     projectId: project.id,
                  //     calculationId: '',
                  // })
                  this.props.setCurrentProject(project.id);
                  this.props.resetCalculation();
                  this.props.history.push("/calc/" + project.id);
                }}
              >
                <span className="addCalc">Dodaj kalkulację</span>
                {/* <span className="plus addCalc">+</span> */}
              </span>
            )}
          </div>
        </li>
      );
    } else {
      return "";
    }
  }

  render() {
    const { projectList, currentProject } = this.props.calculations;
    const { deleteProject, deleteManyCalculations } = this.props;
    const {
      accountData,
      clientData,
      documentType,
      redirect,
      projectId,
      calculationId,
      generateReport,
      documentTitle,
      handleChangeDocumentTitle,
    } = this.state;
    const tableHead = [
      // <button onClick={this.handleOpenNewProjectModal} className="buttonAdd">+</button>,
      "",
      "Projekt",
      "Dobór podstwy",
      "Cena podstawy netto PLN",
      "Data",
      "Nazwa kalkulacji",
    ];
    if (redirect) {
      if (projectId === "") {
        return <Redirect to="/calc/" />;
      } else {
        if (calculationId === "") {
          return <Redirect to={`/calc/${projectId}/calculation`} />;
        } else {
          return (
            <Redirect to={`/calc/${projectId}/calculation/${calculationId}`} />
          );
        }
      }
    }
    let calculations = this.getSelectedCalculations();

    return (
      <div className="main-background">
        <div className="calcList-container background-calcList">
          <ReportPopup
            showPrintModal={this.state.showPrintModal}
            handleClosePrintModal={this.handleClosePrintModal}
            accountData={accountData}
            clientData={clientData}
            documentType={documentType}
            handleChangeAccountData={this.handleChangeAccountData}
            handleChangeClientData={this.handleChangeClientData}
            handleChangeDocumentType={this.handleChangeDocumentType}
            handleGenerateReport={this.handleGenerateReport}
            generateReport={generateReport}
            documentTitle={documentTitle}
            handleChangeDocumentTitle={this.handleChangeDocumentTitle}
            calculations={calculations}
            reportDate={this.state.reportDate}
            setReportDate={this.handleSetReportDate}
            projectName={this.state.projectNameForReport}
            setProjectName={this.setProjectNameForReport}
            comment={this.state.comment}
            setComment={this.setComment}
            signature={this.state.signature}
            setSignature={this.setSignature}
          />
          <label className="projectList-header">Projekty</label>
          <ul className="projectList">
            <li className="projectList-head">
              {/* {drawTable([['Projekt','Nazwa urządzenia', 'Dobór podstwy', 'Cena', 'Data']])} */}
              {this.drawTableRow(tableHead, true)}
            </li>
            {projectList.map((project) =>
              this.renderProject(project, projectList)
            )}
          </ul>
          {/* <button onClick={this.handleOpenPrintModal}>Generuj dokument</button> */}
          <ReactModal
            isOpen={this.state.showNewProjectModal}
            contentLabel="Nowy projekt"
            style={{
              content: {
                position: "absolute",
                inset:
                  "calc(50% - 70px) calc(50% - 200px) calc(50% - 70px) calc(50% - 200px)",
              },
            }}
          >
            <div>
              <div className="modalHeader">
                <label className="labelBold">Dodaj projekt</label>
              </div>
              <label className="labelBold">Nazwa</label>
              <input
                // className="input"
                style={{
                  border: "1px solid #707070",
                  "border-radius": "1px",
                  opacity: 1,
                  "box-shadow": "-1px 2px 5px #0000000B",
                }}
                name="projectName"
                value={this.props.newProjectName}
                onChange={(e) => {
                  this.setProjectName(e.target.value);
                  this.props.setProjectName(e);
                }}
              />
            </div>
            <div className="modalButton">
              <button onClick={this.handleCloseNewProjectModal}>Zamknij</button>
              <button
                onClick={(e) => {
                  this.props.addProject(e);
                  this.handleCloseNewProjectModal();
                }}
              >
                Zapisz
              </button>
            </div>
          </ReactModal>
          <div className="buttonSection">
            <button onClick={this.handleOpenNewProjectModal} >Dodaj projekt</button>
            <div className={`${this.state.selectedProject === -1 ? 'inactive' : ''}`}>
              <label className="labelGenerate">Generuj</label>
              <select
                value={documentType}
                onChange={(e) => this.handleChangeDocumentType(e.target.value)}
              >
                <option value=""></option>
                <option value="OC">Potwierdzenie zamówienia</option>
                <option value="O">Oferta</option>
                <option value="ZW">Zamówienie</option>
              </select>
            </div>
            <button onClick={this.handleOpenDeleteModal} className={`${this.state.selectedProject=== -1 ? 'inactive' : ''}`}>Usuń</button>
          </div>
          <ReactModal
              isOpen={
                this.state.showDeleteModal && this.state.selectedProject !== ""
              }
              contentLabel="Nowy projekt"
              style={{
                content: {
                  position: "absolute",
                  inset:
                    "calc(50% - 100px) calc(50% - 200px) calc(50% - 100px) calc(50% - 200px)",
                },
              }}
            >
              <div>
                <div className="modalHeader">
                  <label className="labelBold">
                    Czy na pewno chcesz usunąć zaznaczone pozycje?
                  </label>
                </div>
              </div>
              <div>
                {this.state.wholeProjectSelected
                  ? projectList.find((p) => p.id === this.state.selectedProject)
                      .name
                  : projectList.find(
                      (p) => p.id === this.state.selectedProject
                    ) &&
                    projectList
                      .find((p) => p.id === this.state.selectedProject)
                      .calculationList.map((calc, index) => {
                        if (this.state.selectedCalculations.includes(calc.calculationId)) {
                          return <div>{calc.calculationName}</div>;
                        }
                      })}
              </div>
              <div className="modalButton">
                <button onClick={this.handleCloseDeleteModal}>Anuluj</button>
                <button
                  onClick={() => {
                    !this.state.selectedCalculations.length
                      ? deleteProject(
                          this.state.selectedProjectID,
                          this.handleRemoveSelected(
                            this.state.selectedProject,
                            this.state.selectedCalculations,
                            this.state.wholeProjectSelected
                          )
                        )
                      : deleteManyCalculations(
                          this.state.selectedCalculations,
                          this.setState.selectedProjectID,
                          () => {
                            this.props.getProjects();
                            setTimeout(() => this.handleCloseDeleteModal(), 500);
                          }
                        );
                  }}
                >
                  Usuń
                </button>
              </div>
            </ReactModal>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    calculations: state.calculationsReducer,
    account: state.accountReducer,
  };
};

const mapDispatchToProps = {
  createProject,
  setCurrentProject,
  resetCalculation,
  selectCalculation,
  removeProject,
  removeCalculations,
  loadCustomerProjects,
};

const CalculationListWithWrapper = ({
  loadCustomerProjects,
  calculations,
  ...props
}) => {
  const { addCalculation, deleteCalculation, deleteManyCalculations } =
    useCalculationAPIActions(calculations);
  const { deleteProject, addProject, setAnyValue, getProjects } =
    useProjectAPIActions(loadCustomerProjects);

  return (
    <CalculationList
      addProject={addProject}
      addCalculation={addCalculation}
      deleteCalculation={deleteCalculation}
      deleteManyCalculations={deleteManyCalculations}
      getProjects={getProjects}
      setProjectName={setAnyValue}
      deleteProject={deleteProject}
      calculations={calculations}
      {...props}
    />
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CalculationListWithWrapper)
);
