import React from 'react';

export const Result = ({elem}) => (
    <div className="resultDescription">
        {elem.name ? (
        <>
            {/* <label className="resultSpacing">Proponowana podstawa:</label> */}
            <div className="result_value resultSpacing">
                {elem.name}
            </div>
            <div className="resultSpacing">
            <span>Cena: </span><span className="result_value">{elem.price} PLN (netto)</span>
            </div>
        </>
         ) : ''
        }       
    </div>
)   