import { SESSION_ID } from "../constans/const";

export const getCurrentDate = () => {
  const date = new Date();
  let day = date.getDate().toString();
  day = day.length === 1 ? `0${day}` : day;
  let month = (date.getMonth() + 1).toString();
  month = month.length === 1 ? `0${month}` : month;
  const dateString = day + "." + month + "." + date.getFullYear();
  return dateString;
};

export const getJwt = () => sessionStorage.getItem(SESSION_ID);

export const getAuthHeaders = () => ({
  "auth-token": `Bearer${getJwt()}`,
  "Content-Type": "application/json",
});

export const parseDate = (projectDate) => {
  const date = new Date(projectDate);
  const month = date.getMonth() + 1;
  const parsedDate = date.getDate() + "." + (month < 10 ? `0${month}` : month) + "." + date.getFullYear();

  return parsedDate;
};
