import React from 'react';
import { CanvasSmall } from './CanvasSmall';

export const CalculatorEdit = ({
    selectProducer,
    producersList,
    selectName,
    namesList,
    selectSeries,
    seriesList,
    selectCode,
    codesList,
    height,
    setHeight,
    width,
    setWidth,
    depth,
    setDepth,
    weight,
    setWeight,
    selectCurrentResult,
    currentResult,
    setCurrentResult,
    results,
    heights,
    calculate,
    resetCalculation,
    chooseParamsManually,
    setChooseParamsManually,
}) => (
    <>
<div className="calculatorContent">
          <label className="labelFull">
            Wprowadź urządzenie  
          </label>
          {chooseParamsManually ? 
          <>
          <div className="devicesDiv">
            <label className="labelSection labelSection-inactive clickable" onClick={()=>setChooseParamsManually(false)}>Wg producenta</label>
            <div className="devicesDiv-content content-inactive">
              <div className="dropDown">
                <label className="label-inactive">Producent</label>
                {/* <label>&nbsp;</label> */}
                <select disabled/>
              </div>
              <div className="dropDown">
                <label className="label-inactive">Nazwa</label>
                {/* <label>&nbsp;</label> */}
                <select disabled/>
              </div>
              <div className="dropDown">
                <label className="label-inactive">Seria</label>
                {/* <label>&nbsp;</label> */}
                <select disabled/>
              </div>
              <div className="dropDown">
                <label className="label-inactive">Kod</label>
                {/* <label>&nbsp;</label> */}
                <select disabled/>
              </div>
            </div>
          </div>
          <div className="calculator">
            <label className="labelSection clickable" onClick={()=>setChooseParamsManually(true)}>Wg wymiarów</label>
            <div className="calculator-content">
              <div className="input">
                <label className="label">h (mm)</label>
                <input 
                  className="input"
                  name="height"
                  value={height}
                  onChange={e => setHeight(e.target.value)}
                />
              </div>
              <div className="input">
                <label className="label">a (mm)</label>
                <input 
                  className="input"
                  name="width"
                  value={width}
                  onChange={e => setWidth(e.target.value)}
                />
              </div>
              <div className="input"> 
                <label className="label">b (mm)</label>
                <input
                  className="input"
                  name="depth"
                  value={depth}
                  onChange={e => setDepth(e.target.value)}
                />
              </div>
              <div className="input">
                <label className="label">waga (kg)</label>
                <input
                  className="input"
                  name="weight"
                  value={weight}
                  onChange={e => setWeight(e.target.value)}
                />
              </div>
            </div>
            <CanvasSmall width={2000} height={1000} depth={1000}/>
          </div>
          </>
           : 
           <>
          <div className="devicesDiv">
            <label className="labelSection clickable" onClick={()=>setChooseParamsManually(false)}>Wg producenta</label>
            <div className="devicesDiv-content">
              <div className="dropDown">
                <label>Producent</label>
                <select
                  onChange={e=> selectProducer(e.target.value)}
                >
                  <option key="0" value=""></option>
                  {producersList.map((producer, index) => (
                    <option 
                      key={index}
                      value={producer}
                    >
                        {producer}
                    </option>
                  ))}
                </select>
              </div>
              <div className="dropDown">
                <label>Nazwa</label>
                <select
                  onChange={e=> selectName(e.target.value)}
                >
                  <option key="0" value=""></option>
                  {namesList.map((name, index) => (
                    <option 
                      key={index}
                      value={name}
                    >
                        {name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="dropDown">
                <label>Seria</label>
                <select
                  onChange={e=> selectSeries(e.target.value)}
                >
                  <option key="0" value=""></option>
                  {seriesList.map((series, index) => (
                    <option 
                      key={index}
                      value={series}
                    >
                        {series}
                    </option>
                  ))}
                </select>
              </div>
              <div className="dropDown">
                <label>Kod</label>
                <select
                  onChange={e=> selectCode(e.target.value)}
                >
                  <option key="0" value=""></option>
                  {codesList.map((code, index) => (
                    <option 
                      key={index}
                      value={code}
                    >
                        {code}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="calculator">
          <label className="labelSection labelSection-inactive clickable" onClick={()=>setChooseParamsManually(true)}>Wg wymiarów</label>
            <div className="calculator-content content-inactive">
              <div className="input">
                <label className="label label-inactive">h (mm)</label>
                {/* <label>{height}&nbsp;</label>  */}
                <input disabled value={height}/>
              </div>
              <div className="input">
                <label className="label label-inactive">a (mm)</label>
                {/* <label>{width}&nbsp;</label> */}
                <input disabled value-={width}/>
              </div>
              <div className="input"> 
                <label className="label label-inactive">b (mm)</label>
                {/* <label>{depth}&nbsp;</label> */}
                <input disabled value={depth}/>
              </div>
              <div className="input">
                <label className="label label-inactive">waga (kg)</label>
                {/* <label>{weight}&nbsp;</label> */}
                <input disabled value={weight}/>
              </div>
            </div>
          </div>
          </> 
          }
          <label className="labelFull label_notop">
            Wybierz typ podstawy
          </label>
          {/* <div className="dropDown calcParam">
            <label>Wysokość podstawy</label>
            <select
              className="input"
              // name="weight"
              // value={this.props.calculations.weight}
              onChange={e => selectCurrentResult(e.target.value)}
            >
              {heights.map(height => (
              <option 
                key={height.id}
                value={height.id}
              >
                  {height.value}
              </option>
            ))}
            </select>
          </div> */}
          <div className="dropDown calcParam" style={{marginTop: 20}}>
              {/* <label>Wybierz rodzaj podstawy</label> */}
              <select
                className="input"
                // name="weight"
                value={currentResult}
                onChange={e => setCurrentResult(e.target.value)}
              >
                {results.map(option => (
                <option 
                  key={option.id}
                  value={option.id}
                >
                    {option.value}
                </option>
              ))}
              </select>
            </div>
            <div className="dropDown calcParam"></div>
          
            {/* <div className="clear">
              <button onClick={resetCalculation}>Wyczysć wszystko</button>          
            </div> */}
          </div>
          <div className="calculate">
            <button onClick={calculate}>licz</button>
          </div>
      </>
);