import * as CONST from "../constans/const";

const accountReducer = (
  state = {
    // loginView: true,
    // email: 'piotr@castel-engeneering.com',
    // password: '',
    // confirmPassword: '',
    // companyName: 'Castel Engeneering Sp. z o.o.',
    // address:'Tomaszowice Kolonia 126',
    // address2:'21-002 Tomaszowice',
    // phone: '+48 81 50 32 822',
    // discount: '40',
    // logged: false,
    user: false,
  },
  action
) => {
  switch (action.type) {
    case CONST.SET_EMAIL: {
      return {
        ...state,
        email: action.payload,
      };
    }
    case CONST.SET_COMPANY_NAME: {
      return {
        ...state,
        companyName: action.payload,
      };
    }
    case CONST.SET_ADDRESS: {
      return {
        ...state,
        address: action.payload,
      };
    }
    case CONST.SET_ADDRESS_2: {
      return {
        ...state,
        address2: action.payload,
      };
    }
    case CONST.SET_PHONE: {
      return {
        ...state,
        phone: action.payload,
      };
    }
    case CONST.SET_PASSWORD: {
      return {
        ...state,
        password: action.payload,
      };
    }
    case CONST.SET_CONFIRM_PASSWORD: {
      return {
        ...state,
        confirmPassword: action.payload,
      };
    }
    case CONST.IS_LOGIN_VIEW: {
      return {
        ...state,
        loginView: action.payload,
      };
    }
    case CONST.LOG_OUT: {
      sessionStorage.removeItem(CONST.SESSION_ID);

      return {
        ...state,
        user: false,
        logged: false,
      };
    }
    case CONST.LOG_IN: {
      return {
        ...state,
        user: action.payload,
        logged: true,
      };
    }

    case CONST.SET_USER: {
      return {
        ...state,
        user: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};

export default accountReducer;
