export const AccountView = ({
    companyName,
    city,
    country,
    postCode,
    street,
    lastname,
    firstname,
    phoneNumber,
    email,
    discount,
    NIP,
    setValue,
    setEditMode,
}) => (
    <div className="accountForm loginContainer-content accountContainer-content">
          Konto użytkownika
          <div className="accountSection">
            <div className="sectionTitle">Dane firmy</div>
            <div className="accountParam">
              <label className="paramLabel">Nazwa firmy</label>
              <label className="labelBold">{companyName}</label>
            </div>
            <div className="accountParam">
              <label className="paramLabel">NIP</label>
              <label className="labelBold">{NIP}</label>
            </div>
            <div className="accountParam">
              <label className="paramLabel">kraj</label>
              <label className="labelBold">{country}</label>
            </div>
            <div className="accountParam">
              <label className="paramLabel">Miasto</label>
              <label className="labelBold">{city}</label>
            </div>
            <div className="accountParam">
              <label className="paramLabel">Ulica</label>
              <label className="labelBold">{street}</label>
            </div>
            <div className="accountParam">
              <label className="paramLabel">kod pocztowy</label>
              <label className="labelBold">{postCode}</label>
            </div>
          </div>
          <div className="accountSection">
            <div className="sectionTitle">Dane kontaktowe</div>
            <div className="accountParam">
              <label className="paramLabel">Imię</label>
              <label className="labelBold">{firstname}</label>
            </div>
            <div className="accountParam">
              <label className="paramLabel">Nazwisko</label>
              <label className="labelBold">{lastname}</label>
            </div>
            <div className="accountParam">
              <label className="paramLabel">Nr tel</label>
              <label className="labelBold">{phoneNumber}</label>
            </div>
            <div className="accountParam">
              <label className="paramLabel">Email</label>
              <label className="labelBold">{email}</label>
            </div>
          </div>
          <div className="accountSection">
            <div className="sectionTitle">Dane biznesowe</div>
            <div className="accountParam">
              <label className="paramLabel">Rabat</label>
              <label className="labelBold">{discount}</label>
            </div>
          </div>
          <div className="accountSection">
            <button onClick={()=>setEditMode(true)}>Edytuj</button>
          </div>
        </div>
)