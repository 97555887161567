export const SET_HEIGHT = "SET_HEIGHT";
export const SET_WIDTH = "SET_WIDTH";
export const SET_DEPTH = "SET_DEPTH";
export const SET_WEIGHT = "SET_WEIGHT";
export const SET_RB_WIDTH = "SET_RB_WIDTH";
export const SET_RB_HEIGHT = "SET_RB_HEIGHT";
export const SET_FF_HEIGHT = "SET_FF_HEIGHT";
export const SET_CURRENT_RESULT = "SET_CURRENT_RESULT";

export const SET_DEVICE = "SET_DEVICE";
export const SET_CALCULATION_NAME = "SET_CALCULATION_NAME";
export const SET_CALCULATION_DATE = "SET_CALCULATION_DATE";
export const SET_RESULT = "SET_RESULT";
export const SAVE_CALCULATION = "SAVE_CALCULATION";
export const ACTUALIZE_CALCULATION = "ACTUALIZE_CALCULATION";

export const CREATE_PROJECT = "CREATE_PROJECT";
export const CREATE_PROJECT_WITH_CALCULATION =
  "CREATE_PROJECT_WITH_CALCULATION";
export const SET_CURRENT_PROJECT = "SET_CURRENT_PROJECT";
export const SET_CURRENT_CALCULATION = "SET_CURRENT_CALCULATION";
export const REMOVE_PROJECT = "REMOVE_PROJECT";
export const REMOVE_CALCULATIONS = "REMOVE_CALCULATIONS";
export const COPY_CALCULATION = "COPY_CALCULATION";
export const SAVE_CALCULATION_TO_PROJECT = "SAVE_CALCULATION_TO_PROJECT";

export const SET_EMAIL = "SET_EMAIL";
export const SET_COMPANY_NAME = "SET_COMPANY_NAME";
export const SET_ADDRESS = "SET_ADDRESS";
export const SET_ADDRESS_2 = "SET_ADDRESS_2";
export const SET_PHONE = "SET_PHONE";
export const SET_PASSWORD = "SET_PASSWORD";
export const SET_CONFIRM_PASSWORD = "SET_CONFIRM_PASSWORD";
export const IS_LOGIN_VIEW = "IS_LOGIN_VIEW";
export const LOG_IN = "LOG_IN";
export const LOG_OUT = "LOG_OUT";
export const SELECT_CALCULATION = "SELECT_CALCULATION";
export const RESET_CALCULATION = "RESET_CALCULATION";
export const SET_USER = "SET_USER";
export const LOAD_CUSTOMER_PROJECTS = "LOAD_CUSTOMER_PROJECTS";
//SessionStorage key
export const SESSION_ID = "SESSION_ID";
