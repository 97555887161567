import { initialRegistrationState as initialState } from "./RegistrationWrapper";

import { useState, useCallback, useEffect } from "react";
import { CUSTOMER_REQUESTS } from "../../constans/requests";
import { useHistory } from "react-router-dom";
import { getAuthHeaders } from "../../utils/utils";

const resultInitialState = {
  success: false,
  error: false,
};

export const AccountWrapper = ({ setAccount, render, user, ...props }) => {
  const [state, setValue] = useState(initialState);
  const [resultState, setResultState] = useState(resultInitialState);
  const history = useHistory();

  useEffect(() => setValue({ ...user }), [user]);

  const setAnyValue = (e) => {
    setValue({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const clearState = () => {
    setValue({ ...initialState });
    setResultState({ ...resultInitialState });
  };

  const createOptionsToChange = useCallback(
    () => ({
      lastname: state.lastname,
      firstname: state.firstname,
      email: state.email,
      phoneNumber: state.phoneNumber,
      city: state.city,
      country: state.country,
      street: state.street,
      postCode: state.postCode,
      NIP: state.NIP,
      companyName: state.companyName,
    }),
    [state]
  );

  const updateAccount = useCallback(
    (e) => {
      const body = {
        optionsToChange: createOptionsToChange(),
        options: {
          id: state.id,
        },
      };
      e.preventDefault();

      fetch(CUSTOMER_REQUESTS.PUT.UPDATE_ACCOUNT, {
        method: "PUT",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { ...getAuthHeaders() },
        body: JSON.stringify({ ...body }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.success) {
            setResultState({ ...resultState, success: data.success });
            setAccount(data.customer);
            history.push("/calc/");

            clearState();
          }

          if (data.token) {
            sessionStorage.setItem("SESSION_ID", data.token);
          }

          if (!data.success) {
            setResultState({ ...resultState, error: data.message });
            if(data.clear) {
              sessionStorage.removeItem('SESSION_ID')
              history.push("/")
            }
            // your code here...
          }
        })
        .catch((error) => console.log("error", error));
    },
    [state, resultState, history, setAccount, createOptionsToChange]
  );

  return render({
    resultState,
    state,
    updateAccount,
    setAnyValue,
    clearState,
    ...props,
  });
};
