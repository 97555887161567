import React from 'react';
import { connect } from 'react-redux';

import LoginComponent from '../components/Login';
import RegisterComponent from '../components/Register';

import { logIn } from '../actions/accountAction'

class Login extends React.Component {
  constructor() {
    super();

    this.state = {
      loginView: true,
    }
  }

  handleClick = ({ target }) =>  {
    this.setState({
      loginView: target.name === 'login'
    })
  }

  isLoginView(isLoginView) {
    this.setState({
      loginView: isLoginView,
    })
  }

  render() {
    const {email, password, passwordRepeat} = this.props.account;
    const {setEmail, setCompanyName, setPassword, setConfirmPassword, logIn} = this.props;
        
    const {loginView} = this.state;
    return (
      // <div className="loginContainer">
      //   <button name="login" onClick={this.handleClick} className="buttonLogin">logowanie</button>
      //   <button name="register" onClick={this.handleClick} className="buttonLogin">rejestracja</button>
      //   {this.state.loginView ?
      //     <LoginComponent logIn={this.props.logIn}/> :
      //     <RegisterComponent />
      //   }
      // </div>
      <div className="background-login">
        <div className="loginContainer">
            <div className="loginContainer-content">
                <a name="login" onClick={()=>this.isLoginView(true)} className={`login ${loginView ? 'active' : ''}`}>logowanie</a>
                <a name="register" onClick={()=>this.isLoginView(false)} className={`register ${loginView ? '' : 'active'}`}>rejestracja</a>
                <div className="inputs">
                {loginView ? 
                    <LoginComponent email={email} password={password} setEmail={setEmail} setPassword={setPassword} logIn={logIn}/> : 
                    <RegisterComponent email={email} password={password} passwordRepeat={passwordRepeat} setEmail={setEmail} setCompanyName={setCompanyName} setPassword={setPassword} setConfirmPassword={setConfirmPassword} />
                }
                </div>
            </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    account: state.accountReducer,
  }
};

const mapDispatchToProps = ({
  logIn
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);

