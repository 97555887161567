import { createAsyncThunk } from "@reduxjs/toolkit";

// let dividor = 10;
// let dividorSmall = 20;
// let dividor = 5;
// let dividorSmall = 10;
const startX = 20;
const startY = 20;
const halfWidth = 400;
const halfHeight = 300;
const distance = 50;

export function getHeight(wx, wy, h, dividorSmall) {
    wx = wx !== '' ? wx : 1000;
    wy = wy !== '' ? wy : 1000;
    h = h !== '' ? h : 1000;
    // x = x/dividorSmall + 300;
    // y = y/dividorSmall + 350;
    wx = wx/dividorSmall;
    wy = wy/dividorSmall;
    h = h/dividorSmall;

    return h + (wx * 0.5 + wy * 0.5) + 30;
}

export function drawCube( canvasId, x, y, wx, wy, h, dividor) {
    let dividorSmall = dividor*2;
    y = getHeight(wx, wy, h, dividorSmall);
    wx = wx !== '' ? wx : 1000;
    wy = wy !== '' ? wy : 1000;
    h = h !== '' ? h : 1000;
    // // x = x/dividorSmall + 300;
    // // y = y/dividorSmall + 350;
    wx = wx/dividorSmall;
    wy = wy/dividorSmall;
    h = h/dividorSmall;

    // x = 
    
    
    let canvas = document.getElementById(canvasId);
    if(canvas && canvas.getContext) {
        x = canvas.width/2;
        const ctx = canvas.getContext('2d');
        ctx.clearRect(0,0,canvas.width, canvas.height);
        // ctx.fillStyle = '#ff0000';
        // ctx.fillRect(0,0,canvas.width, canvas.height)
        // left face
        ctx.beginPath();
        ctx.moveTo(x, y);
        ctx.lineTo(x - wx, y - wx * 0.5);
        ctx.lineTo(x - wx, y - h - wx * 0.5);
        ctx.lineTo(x, y - h * 1);
        ctx.closePath();
        // ctx.fillStyle = "#838357"
        // ctx.strokeStyle = "#7a7a51";
        // ctx.fillStyle = "#000000"
        ctx.strokeStyle = "#ffffff";
        ctx.stroke();
        // ctx.fill();
        
        // for(let i=y-10;i>y-h;i-=10) {
          for(let i=y-10;i>y-h+5;i-=5) {
            ctx.lineWidth="0.5";
            ctx.beginPath();
            ctx.moveTo(x-wx+5, i-wx*0.5+2.5);
            ctx.lineTo(x-5, i-2.5);
            ctx.closePath();
            ctx.stroke();
        }

        const dividor = wx/10;
        const m = wx*0.5/dividor;
        let counter = -0.5;
        // for(let i=x-10;i>x-wx;i-=10) {
          for(let i=x-10;i>x-wx+5;i-=5) {
            ctx.lineWidth="0.5";
            counter +=0.5;
            ctx.beginPath();
            ctx.moveTo(i, y-m*counter-10);
            ctx.lineTo(i, y-h-m*counter);
            ctx.closePath();
            ctx.stroke();
        }
        ctx.lineWidth="1";
        // right face
        ctx.beginPath();
        ctx.moveTo(x, y);
        ctx.lineTo(x + wy, y - wy * 0.5);
        ctx.lineTo(x + wy, y - h - wy * 0.5);
        ctx.lineTo(x, y - h * 1);
        ctx.closePath();
        // ctx.fillStyle = "#6f6f49";
        // ctx.strokeStyle = "#676744";
        // ctx.fillStyle = "#000000"
        ctx.strokeStyle = "#ffffff";
        ctx.stroke();
        // ctx.fill();

        // center face
        ctx.beginPath();
        ctx.moveTo(x, y - h);
        ctx.lineTo(x - wx, y - h - wx * 0.5);
        ctx.lineTo(x - wx + wy, y - h - (wx * 0.5 + wy * 0.5));
        ctx.lineTo(x + wy, y - h - wy * 0.5);
        ctx.closePath();
        // ctx.fillStyle = "#989865";
        // ctx.strokeStyle = "#8e8e5e";
        // ctx.fillStyle = "#000000"
        ctx.strokeStyle = "#ffffff";
        ctx.stroke();
        // ctx.fill();

        ctx.font = "14px Arial";
        ctx.fillStyle="#ffffff";
        ctx.fillText("h", x+2, y - h*0.5);
        ctx.fillText("a", x - wx/2-10, y - wx * 0.2+5);
        ctx.fillText("b", x + wy/2+5, y - wy * 0.2+5);
    }
}

export const cleanCanvas = canvasId => {
    let canvas = document.getElementById(canvasId);
    if(canvas && canvas.getContext) {
        const ctx = canvas.getContext('2d');
        ctx.clearRect(0,0,canvas.width, canvas.height);
    }
}

// export const drawDevice = (canvasId, width, depth, height) => {
//     console.log('draw device')
//     let canvas = document.getElementById(canvasId);
//     if(canvas && canvas.getContext) {
//         const x = (canvas.width - width/dividorSmall)/2
//         const y = (canvas.height - height/dividorSmall)/2
//         const ctx = canvas.getContext('2d');
//         ctx.clearRect(0,0,canvas.width, canvas.height);
//         ctx.lineWidth="2";
//         ctx.strokeStyle='#000000';
//         ctx.strokeRect(x, y, width/dividorSmall, height/dividorSmall)  
//         ctx.strokeRect(x, y, width/dividorSmall, height/dividorSmall)  
        
//         ctx.font = "14px Arial";
//         ctx.fillText("h", x-12, y + height/dividorSmall/2);
//         ctx.fillText("a", x+width/dividorSmall/2, y + height/dividorSmall + 12);
//         ctx.fillText("b", x+width/dividorSmall + depth/dividorSmall/6 + 6, y + height/dividorSmall - depth/dividorSmall/6 + 6);
        
        
//         ctx.beginPath();
//         ctx.moveTo(x, y);
//         ctx.lineTo(x + depth/dividorSmall/3, y-depth/dividorSmall/3);
//         ctx.lineTo(x + depth/dividorSmall/3+width/dividorSmall, y-depth/dividorSmall/3);
//         ctx.lineTo(x +width/dividorSmall, y);
//         ctx.closePath();
//         ctx.lineWidth="1";
//         ctx.strokeStyle='#000000';
//         ctx.stroke();
        
//         ctx.beginPath();
//         ctx.moveTo(x + depth/dividorSmall/3+width/dividorSmall, y-depth/dividorSmall/3);
//         ctx.lineTo(x + depth/dividorSmall/3+width/dividorSmall, y-depth/dividorSmall/3 + height/dividorSmall);
//         // ctx.lineTo(x + depth/dividorSmall/3+width/dividorSmall, y-depth/dividorSmall/3);
//         ctx.lineTo(x +width/dividorSmall, y+height/dividorSmall);
//         ctx.lineTo(x +width/dividorSmall, y);
//         ctx.closePath();
//         ctx.lineWidth="1";
//         ctx.strokeStyle='#000000';
//         ctx.stroke();
//     }
// }

//----------------------------------------------------------------

export const drawFF = (canvasFrontId, elem, width, depth, height, ffHeight, dividor) => {
    // widok od przodu
    // let y = startY + getHeight(width, depth, height, dividor * 2) + distance;
    let y = startY;
    let canvas = document.getElementById(canvasFrontId);
    if(canvas && canvas.getContext) {
        let x = (canvas.width - width/dividor)/2;
        const ctx = canvas.getContext('2d');
        // ctx.clearRect(0,y,canvas.width, canvas.height - y);
        ctx.clearRect(0,0,canvas.width, canvas.height);
        if(elem.width) {
            let delta = elem.width - width;
            drawElementFront(width/dividor, height/dividor, x, y, 6, ctx);
            x = x - delta/2/dividor;
            y = y + height/dividor;
            drawFFFront(elem, x, y, width, ffHeight, ctx, dividor);         
        }
    }
    // widok z boku   
    canvas = document.getElementById(canvasFrontId);
    if(canvas && canvas.getContext) {
        let x = (canvas.width - depth/dividor)/2;
        y = parseInt(y) + parseInt(height/dividor)+parseInt(ffHeight === '1' ? 400/dividor : 800/dividor)+distance;
        const ctx = canvas.getContext('2d');
        // ctx.clearRect(0,0,canvas.width, canvas.height);
        if(elem.width) {
            let delta = elem.depth - depth;
            drawElementSide(depth/dividor, height/dividor, x, y, 6, ctx);
            x = x - delta/2/dividor;
            y = y + height/dividor;
            drawFFSide(elem, x, y, depth, ffHeight, ctx, dividor);         
        }
    }
    // widok z góry 
    canvas = document.getElementById(canvasFrontId);
    if(canvas && canvas.getContext) {
        let x = (canvas.width - width/dividor)/2;
        // let y = startY + 700;
        y = parseInt(y) + parseInt(height/dividor)+parseInt(ffHeight === '1' ? 400/dividor : 800/dividor)+distance;
        const ctx = canvas.getContext('2d');
        // ctx.clearRect(0,0,canvas.width, canvas.height);
        if(elem.width) {
            let deltaWidth = (elem.width - width)/2/dividor;
            let deltaDepth = (elem.depth - depth)/2/dividor;
            drawFFTop(elem, x, y, deltaWidth, deltaDepth, width, depth, ctx, dividor);         
            drawElementTop(width/dividor, depth/dividor, x, y, ctx);
        }
    }   
}

const drawElementFront = (width, height, x, y, gap, ctx, rotated) => {
    ctx.lineWidth="2";
    ctx.strokeStyle='#000000';
    ctx.strokeRect(x,y-gap,width, height)
    
    if(!rotated) {
        const r = (width < height ? width : height)/2 * 0.8;
        ctx.beginPath();
        const startX = x + width/2;
        const startY = y-gap + height/2;
        ctx.arc(startX, startY , r, 0, 2*Math.PI);
        ctx.stroke();   
        for(var i=x+10; i<=x+width-10;i+=5) {
            ctx.lineWidth="0.5";
            ctx.beginPath();
            ctx.moveTo(i, y-gap+5)
            ctx.lineTo(i, y-gap+height-5);
            ctx.closePath();
            ctx.stroke();
        }
        
        for(var i=y-gap+10; i<=y-gap+height-10;i+=5) {
            ctx.lineWidth="0.5";
            ctx.beginPath();
            ctx.moveTo(x+5, i)
            ctx.lineTo(x+width-5, i);
            ctx.closePath();
            ctx.stroke();
        }
    }

}

const drawElementSide = (depth, height, x, y, gap, ctx, rotated) => {
    ctx.lineWidth="2";
    ctx.strokeStyle='#000000';
    ctx.strokeRect(x,y-gap,depth, height)  
    
    if(rotated) {
        const r = (depth < height ? depth : height)/2 * 0.8;
        ctx.beginPath();
        const startX = x + depth/2;
        const startY = y-gap + height/2;
        ctx.arc(startX, startY , r, 0, 2*Math.PI);
        ctx.stroke();   
        for(var i=x+10; i<=x+depth-10;i+=5) {
            ctx.lineWidth="0.5";
            ctx.beginPath();
            ctx.moveTo(i, y-gap+5)
            ctx.lineTo(i, y-gap+height-5);
            ctx.closePath();
            ctx.stroke();
        }
        
        for(var i=y-gap+10; i<=y-gap+height-10;i+=5) {
            ctx.lineWidth="0.5";
            ctx.beginPath();
            ctx.moveTo(x+5, i)
            ctx.lineTo(x+depth-5, i);
            ctx.closePath();
            ctx.stroke();
        }
    }
}

const drawElementTop = (width, depth, x, y, ctx) => {
    ctx.lineWidth="2";
    ctx.strokeStyle='#000000';
    ctx.fillStyle="#ffffff"
    ctx.fillRect(x,y,width, depth)        
    ctx.strokeRect(x,y,width, depth)        
}

const drawFFFront = (elem, x, y, deviceWidth, ffHeight, ctx, dividor) => {
    const delta = (elem.width/dividor - deviceWidth/dividor)/2;
    //rysowanie nóg
    const gap = elem.width/(elem.rowCount - 1)/dividor;
    if(ffHeight === '2') {
        ctx.beginPath();
        ctx.moveTo(x, y + 400/dividor);
        ctx.lineTo(x + elem.width/dividor, y + 400/dividor);
        ctx.closePath();
        ctx.lineWidth="4";
        ctx.strokeStyle='#aaaaaa';
        ctx.stroke();
    }
    for(let i = 0;i < elem.rowCount; i++) {
        let innerX = x+ i*gap;
        const distance  = 100/dividor;
        ctx.fillStyle="#000000";
        if (i === 0) {
            ctx.fillRect(x + delta, y-6, 6, 6);    
        } else if (i === elem.rowCount - 1) {
            ctx.fillRect(x + elem.width/dividor - delta - 6, y-6, 6, 6);
        } else {
            ctx.fillRect(innerX - distance, y-6, 6, 6);
            ctx.fillRect(innerX + distance - 6, y-6, 6, 6);
        }
        drawFoot(innerX, y, ffHeight, ctx, dividor);
    }

    ctx.beginPath();
    ctx.moveTo(x, y);
    ctx.lineTo(x + elem.width/dividor, y);
    ctx.closePath();
    ctx.lineWidth="4";
    ctx.strokeStyle='#666666';
    ctx.stroke(); 

}
const drawFFSide = (elem, x, y, deviceDepth, ffHeight, ctx, dividor) => {
    const delta = (elem.depth/dividor - deviceDepth/dividor)/2;
    ctx.beginPath();
    ctx.moveTo(x, y);
    ctx.lineTo(x + elem.depth/dividor, y);
    ctx.closePath();
    ctx.lineWidth="4";
    ctx.strokeStyle='#666666';
    ctx.stroke(); 

    //rysowanie nóg
    const gap = elem.depth/(elem.rows - 1)/dividor;
    if(ffHeight === '2') {
        ctx.beginPath();
        ctx.moveTo(x, y + 400/dividor);
        ctx.lineTo(x + elem.depth/dividor, y + 400/dividor);
        ctx.closePath();
        ctx.lineWidth="4";
        ctx.strokeStyle='#aaaaaa';
        ctx.stroke();
    }
    for(let i = 0;i < elem.rows; i++) {
        let innerX = x+ i*gap;
        const distance  = 100/dividor;
        if (i === 0) {
            ctx.fillRect(x + delta, y-6, 6, 6);    
        } else if (i === elem.rows - 1) {
            ctx.fillRect(x + elem.depth/dividor - delta - 6, y-6, 6, 6);
        } else {
            ctx.fillRect(innerX - distance, y-6, 6, 6);
            ctx.fillRect(innerX + distance - 6, y-6, 6, 6);
        }
        drawFoot(innerX, y, ffHeight, ctx, dividor);
    }
}
const drawFFTop = (elem, x, y, deltaX, deltaY, width, depth, ctx, dividor) => {
    ctx.beginPath();
    ctx.moveTo(x - deltaX, y);
    ctx.lineTo(x - deltaX + elem.width/dividor, y);
    ctx.closePath();
    ctx.lineWidth="4";
    ctx.strokeStyle='#444444';
    ctx.stroke();

    ctx.beginPath();
    ctx.moveTo(x - deltaX, y+depth/dividor);
    ctx.lineTo(x - deltaX + elem.width/dividor, y+depth/dividor);
    ctx.closePath();
    ctx.lineWidth="4";
    ctx.strokeStyle='#444444';
    ctx.stroke();

    if(elem.rows === 3) {
        ctx.beginPath();
        ctx.moveTo(x - deltaX, y+depth/dividor/2);
        ctx.lineTo(x - deltaX + elem.width/dividor, y+depth/dividor/2);
        ctx.closePath();
        ctx.lineWidth="4";
        ctx.strokeStyle='#444444';
        ctx.stroke();

        // ctx.beginPath();
        // ctx.moveTo(x - deltaX, y+depth/dividor/2 + distance);
        // ctx.lineTo(x - deltaX + elem.width/dividor, y+depth/dividor/2 + distance);
        // ctx.closePath();
        // ctx.lineWidth="4";
        // ctx.strokeStyle='#444444';
        // ctx.stroke();
    }
    //rysowanie nóg
    const gap = elem.width/(elem.rowCount - 1)/dividor;
    const distance  = 100/dividor;
    for(let i = 0;i < elem.rowCount; i++) {
        let innerX = x -deltaX + i*gap;
        if(i === 0 || i === elem.rowCount-1) {
        ctx.beginPath();
        ctx.moveTo(innerX, y - deltaY);
        ctx.lineTo(innerX, y+depth/dividor + deltaY);
        ctx.closePath();
        ctx.lineWidth="4";
        ctx.strokeStyle='#444444';
        ctx.stroke();
        } else {
            ctx.beginPath();
            ctx.moveTo(innerX - distance, y - deltaY);
            ctx.lineTo(innerX - distance, y+depth/dividor + deltaY);
            ctx.closePath();
            ctx.lineWidth="4";
            ctx.strokeStyle='#444444';
            ctx.stroke();
            ctx.beginPath();
            ctx.moveTo(innerX + distance, y - deltaY);
            ctx.lineTo(innerX + distance, y+depth/dividor + deltaY);
            ctx.closePath();
            ctx.lineWidth="4";
            ctx.strokeStyle='#444444';
            ctx.stroke();        
        }
        drawFootTop(innerX, y - deltaY, ctx, dividor);
        drawFootTop(innerX, y+depth/dividor + deltaY, ctx, dividor);
        if(elem.rows === 3) {
            drawFootTop(innerX, y+depth/dividor/2, ctx, dividor);
        }
    }

}

const drawFoot = (x, y, ffHeight, ctx, dividor) => {
    const height = ffHeight === '1' ? 400/dividor : 800/dividor;
    const width = 300/dividor
    const footHeight = 100/dividor;
    const footWidth = 20/dividor;

    //linia pionowa
    ctx.beginPath();
    ctx.moveTo(x, y);
    ctx.lineTo(x, y+height);
    ctx.closePath();
    ctx.lineWidth="1";
    ctx.strokeStyle='#000000';
    ctx.stroke();
    
    //zgrubienie
    ctx.strokeRect(x-footWidth/2, y + height - 200/dividor, footWidth, 200/dividor);

    //trójkąt
    ctx.beginPath();
    ctx.moveTo(x-width/2, y+height);
    ctx.lineTo(x+width/2, y+height);
    ctx.lineTo(x, y+height - footHeight);
    ctx.closePath();
    ctx.lineWidth="1";
    ctx.strokeStyle='#000000';
    ctx.stroke();
    ctx.fillStyle='#666666';
    ctx.fill();
}

const drawFootTop = (x, y, ctx, dividor) => {
    const width = 300/dividor   
    const delta1= 100/dividor
    const delta2= 200/dividor

    ctx.lineWidth="1";
    ctx.strokeStyle='#000000';
    ctx.strokeRect(x - width/2, y - width/2, width, width)

    ctx.beginPath();
    ctx.moveTo(x-delta1, y-delta1);
    ctx.lineTo(x + width - delta2, y + width - delta2);
    ctx.closePath();
    ctx.lineWidth="1";
    ctx.strokeStyle='#000000';
    ctx.stroke();

    ctx.beginPath();
    ctx.moveTo(x-delta1, y+delta1);
    ctx.lineTo(x + width - delta2, y - delta1);
    ctx.closePath();
    ctx.lineWidth="1";
    ctx.strokeStyle='#000000';
    ctx.stroke();

    // ctx.beginPath();
    // ctx.moveTo(x-10, y);
    // ctx.lineTo(x + width - 20, y);
    // ctx.closePath();
    // ctx.lineWidth="1";
    // ctx.strokeStyle='#000000';
    // ctx.stroke();

    // ctx.beginPath();
    // ctx.moveTo(x, y+10);
    // ctx.lineTo(x, y - 10);
    // ctx.closePath();
    // ctx.lineWidth="1";
    // ctx.strokeStyle='#000000';
    // ctx.stroke();
}

// ------------------------------------------------
export function drawFFMini(canvasFrontId, elem, width, depth, height, rotated, dividor) {
    if(rotated) {
        let temp = width;
        width = depth;
        depth = temp;
    }
    let canvas = document.getElementById(canvasFrontId);
    // let y = startY + getHeight(width, depth, height, dividor * 2) + distance;
    let y = startY;
    if(canvas && canvas.getContext) {
        // widok od przodu
        let x = (canvas.width - width/dividor)/2;
        // let y = startY;
        const ctx = canvas.getContext('2d');
        // ctx.clearRect(0,y,canvas.width, canvas.height - y);
        ctx.clearRect(0,0,canvas.width, canvas.height);
        if(elem.width) {
            let delta = elem.width - width;
            drawElementFront(width/dividor, height/dividor, x, y, 2, ctx, rotated);
            x = x - delta/2/dividor;
            y = y + height/dividor;
            drawFFMiniFront(elem, x, y, width, ctx, dividor);         
        }
        // widok z boku   
        x = (canvas.width - depth/dividor)/2;
        y = parseInt(y) + parseInt(height/dividor)+parseInt(350/dividor)+distance;
        // y = startY + 350;
        // ctx.clearRect(0,0,canvas.width, canvas.height);
        if(elem.width) {
            let delta = elem.depth - depth;
            drawElementSide(depth/dividor, height/dividor, x, y, 2, ctx, rotated);
            // x = x - delta/2/dividor;
            y = y + height/dividor;
            drawFFMiniSide(elem, x, y, depth, ctx, dividor);         
        }
        // widok z góry 
        x = (canvas.width - width/dividor)/2;
        y = parseInt(y) + parseInt(height/dividor)+parseInt(350/dividor)+distance;
        // ctx.clearRect(0,0,canvas.width, canvas.height);
        if(elem.width) {
            let deltaWidth = (elem.width - width)/2/dividor;
            let deltaDepth = (elem.depth - depth)/2/dividor;
            drawFFMiniTop(elem, x, y, deltaWidth, deltaDepth, width, depth, ctx, dividor);         
            drawElementTop(width/dividor, depth/dividor, x, y, ctx);
        }
    }
}

const drawFFMiniFront = (elem, x, y, deviceWidth, ctx, dividor) => {
    const delta = (elem.width/dividor - deviceWidth/dividor)/2;
    const height = 350/dividor;
    const round = 100/dividor;
    
    ctx.beginPath();
    ctx.moveTo(x, y+height);
    ctx.lineTo(x, y+round)
    ctx.quadraticCurveTo(x, y, x+round, y);
    ctx.lineTo(x + elem.width/dividor-round, y);
    ctx.quadraticCurveTo(x + elem.width/dividor, y, x + elem.width/dividor, y + round);
    ctx.lineTo(x + elem.width/dividor, y+height)
    // ctx.closePath();
    ctx.lineWidth="4";
    ctx.strokeStyle='#666666';
    ctx.stroke(); 

    drawFFMiniFoot(x, y+height, ctx, dividor);
    drawFFMiniFoot(x+elem.width/dividor, y+height, ctx, dividor);

}
const drawFFMiniSide = (elem, x, y, deviceDepth, ctx, dividor) => {
    const delta = (elem.depth/dividor - deviceDepth/dividor)/2;
    const height = 350/dividor;
    ctx.beginPath();
    ctx.moveTo(x, y+height);
    ctx.lineTo(x, y);
    // ctx.closePath();
    ctx.lineWidth="3";
    ctx.strokeStyle='#666666';
    ctx.stroke(); 

    ctx.beginPath();
    ctx.moveTo(x + deviceDepth/dividor, y+height);
    ctx.lineTo(x + deviceDepth/dividor, y);
    ctx.lineWidth="3";
    ctx.strokeStyle='#666666';
    ctx.stroke(); 
    
    ctx.beginPath();
    ctx.moveTo(x, y+height/2);
    ctx.lineTo(x + deviceDepth/dividor, y+height/2);
    ctx.lineWidth="4";
    ctx.strokeStyle='#666666';
    ctx.stroke(); 

    drawFFMiniFoot(x, y+height, ctx, dividor);
    drawFFMiniFoot(x+deviceDepth/dividor, y+height, ctx, dividor);
    
}
const drawFFMiniTop = (elem, x, y, deltaX, deltaY, width, depth, ctx, dividor) => {
    
    //rysowanie nóg
    drawFFMiniFootTop(x - deltaX, y, ctx, dividor);
    drawFFMiniFootTop(x - deltaX + elem.width/dividor, y, ctx, dividor);
    drawFFMiniFootTop(x - deltaX, y+depth/dividor, ctx, dividor);
    drawFFMiniFootTop(x - deltaX + elem.width/dividor, y+depth/dividor, ctx, dividor);
    
    
    ctx.beginPath();
    ctx.moveTo(x - deltaX, y);
    ctx.lineTo(x - deltaX + elem.width/dividor, y);
    ctx.closePath();
    ctx.lineWidth="3";
    ctx.strokeStyle='#444444';
    ctx.stroke();

    ctx.beginPath();
    ctx.moveTo(x - deltaX, y+depth/dividor);
    ctx.lineTo(x - deltaX + elem.width/dividor, y+depth/dividor);
    ctx.closePath();
    ctx.lineWidth="3";
    ctx.strokeStyle='#444444';
    ctx.stroke();

    ctx.beginPath();
    ctx.moveTo(x - deltaX + elem.width/dividor, y+depth/dividor);
    ctx.lineTo(x - deltaX + elem.width/dividor, y);
    ctx.closePath();
    ctx.lineWidth="4";
    ctx.strokeStyle='#444444';
    ctx.stroke();

    ctx.beginPath();
    ctx.moveTo(x - deltaX, y+depth/dividor);
    ctx.lineTo(x - deltaX, y);
    ctx.closePath();
    ctx.lineWidth="4";
    ctx.strokeStyle='#444444';
    ctx.stroke();

    
}

const drawFFMiniFoot = (x, y, ctx, dividor) => {
    const height = 70/dividor;
    const width = 200/dividor
    
    //trójkąt
    ctx.beginPath();
    ctx.moveTo(x-width/2, y);
    ctx.lineTo(x+width/2, y);
    ctx.lineTo(x+width/2 - width/4, y-height);
    ctx.lineTo(x-width/4, y-height);
    ctx.closePath();
    ctx.lineWidth="3";
    ctx.strokeStyle='#000000';
    ctx.stroke();
    ctx.fillStyle='#ffffff';
    ctx.fill();
}

const drawFFMiniFootTop = (x, y, ctx, dividor) => {
    const width = 200/dividor
    const innerWidth = 100/dividor
    
    ctx.lineWidth="1";
    ctx.strokeStyle='#000000';
    ctx.strokeRect(x - width/2, y - width/2, width, width)
    
    ctx.beginPath();
    ctx.moveTo(x-width/2, y-width/2);
    ctx.lineTo(x + width/2, y +width/2);
    ctx.closePath();
    ctx.lineWidth="1";
    ctx.strokeStyle='#000000';
    ctx.stroke();

    ctx.beginPath();
    ctx.moveTo(x-width/2, y+width/2);
    ctx.lineTo(x +width/2, y - width/2);
    ctx.closePath();
    ctx.lineWidth="1";
    ctx.strokeStyle='#000000';
    ctx.stroke();

    ctx.lineWidth="1";
    ctx.strokeStyle='#000000';
    ctx.fillStyle="#ffffff"
    ctx.fillRect(x - innerWidth/2, y - innerWidth/2, innerWidth, innerWidth)
    ctx.strokeRect(x - innerWidth/2, y - innerWidth/2, innerWidth, innerWidth)
}
//-------------------------------------------------
export function drawRB(canvasId, elem, width, depth, height, rbWidth, rbHeight, dividor) {
    rbWidth = parseInt(rbWidth);
    let canvas = document.getElementById(canvasId);
    // let y = startY + getHeight(width, depth, height, dividor + 2) + distance;
    let y = startY;
    if(canvas && canvas.getContext) {
        // widok od przodu
        let x = (canvas.width - width/dividor)/2;
        // let y = startY;
        const ctx = canvas.getContext('2d');
        // ctx.clearRect(0,y,canvas.width, canvas.height-y);
        ctx.clearRect(0,0,canvas.width, canvas.height);
        if(elem.depth) {
            drawElementFront(width/dividor, height/dividor, x, y, 2, ctx);
            // x = x - delta/2/dividor;
            y = y + height/dividor;
            drawRbFront(elem, x, y, width/dividor, rbWidth, rbHeight, ctx, dividor);         
            drawRbFront(elem, x + width/dividor - rbWidth/dividor, y, width/dividor, rbWidth, rbHeight, ctx, dividor);         
            if(elem.count > 2) {
                const gap = width/(elem.count - 1)/dividor -  rbWidth/(elem.count - 1)/dividor;
                let innerX = x;
                for(let i = 0; i< elem.count - 2; i++) {
                    innerX += gap; 
                    drawRbFront(elem, innerX, y, width/dividor, rbWidth, rbHeight, ctx, dividor);
                }
            }
        }
        // widok z boku   
        x = (canvas.width - depth/dividor)/2;
        y = parseInt(y) + parseInt(height/dividor)+distance;
        // ctx.clearRect(0,0,canvas.width, canvas.height);
        if(elem.depth) {
            let delta = elem.depth - depth;
            drawElementSide(depth/dividor, height/dividor, x, y, 2, ctx);
            x = x - delta/2/dividor;
            y = y + height/dividor;
            drawRbSide(elem, x, y, elem.depth/dividor, width/dividor, rbHeight, ctx, dividor);         
        }
        // widok z góry 
        x = (canvas.width - width/dividor)/2;
        y = parseInt(y) + parseInt(height/dividor)+distance;
        // ctx.clearRect(0,0,canvas.width, canvas.height);
        if(elem.depth) {
            let deltaWidth = (elem.width - width)/2/dividor;
            let deltaDepth = (elem.depth - depth)/2/dividor;
            drawRbTop(elem, x, y, rbWidth, elem.depth/dividor, ctx, dividor);         
            drawRbTop(elem, x + width/dividor - rbWidth/dividor, y, rbWidth, elem.depth/dividor, ctx, dividor);         
            if(elem.count > 2) {
                const gap = width/(elem.count - 1)/dividor -  rbWidth/(elem.count - 1)/dividor;
                let innerX = x;
                for(let i = 0; i< elem.count - 2; i++) {
                    innerX += gap; 
                    drawRbTop(elem, innerX, y, rbWidth, elem.depth/dividor, ctx, dividor);
                }
            }
            drawElementTop(width/dividor, depth/dividor, x, y + deltaDepth, ctx);
        }
    }
}

const drawRbFront = (elem, x, y, width, rbWidth, rbHeight, ctx, dividor) => {
        // let x = 200;
        // let y = 200;
        // let dividor = 5;

        rbWidth = rbWidth/dividor;
        let height = 95/dividor;
        let heightSmall = 30/dividor;
        let widthTop = 92/dividor;
        let deltaX = ((rbWidth - widthTop)/2)
        let topCut = 12/dividor;
        
        y += height;
        // console.log(width, height, heightSmall, widthTop, deltaX, dividor)
        // const ctx = canvas.getContext('2d');
        // ctx.clearRect(0,0,canvas.width, canvas.height);
        // console.log(x,y,width, elem)
        
        
        // if(elem.width) {
        // let delta = elem.width - width;
        ctx.beginPath();
        ctx.moveTo(x, y);
        ctx.lineTo(x+rbWidth, y);
        ctx.lineTo(x+rbWidth, y-heightSmall);
        ctx.lineTo(x+rbWidth-deltaX, y-height);
        ctx.lineTo(x+deltaX, y-height);
        ctx.lineTo(x, y-heightSmall);
        ctx.closePath();
        ctx.lineWidth="2";
        ctx.strokeStyle='#000000';
        ctx.stroke();   
        //podwyższona noga
        if(rbHeight === "2") {
            let addOnWidth = 190/dividor;
            let addOnDelta = (addOnWidth - rbWidth)/2;
            let addOnHeight = 105/dividor;
            ctx.beginPath();
            ctx.moveTo(x,y);
            ctx.lineTo(x+rbWidth, y);
            ctx.lineTo(x+rbWidth+addOnDelta, y+addOnHeight);
            ctx.lineTo(x-addOnDelta, y+addOnHeight);
            ctx.closePath();
            ctx.lineWidth="2";
            ctx.strokeStyle='#000000';
            ctx.stroke();
        }
};
const drawRbSide = (elem, x, y, depth, width, rbHeight, ctx, dividor) => {
    let height = 95/dividor;
    let heightSmall = 30/dividor;

    ctx.lineWidth="2";
    ctx.strokeStyle='#000000';
    ctx.strokeRect(x, y, depth, height);
    ctx.beginPath();
    ctx.moveTo(x, y+height - heightSmall);
    ctx.lineTo(x+depth, y+height - heightSmall);
    ctx.lineWidth="1";
    ctx.stroke();
    if(rbHeight === "2") {
        ctx.lineWidth="2";
        ctx.strokeStyle='#000000';
        ctx.strokeRect(x, y+height, depth, 105/dividor);
    }
};
const drawRbTop = (elem, x, y, rbWidth, depth, ctx, dividor) => {
    let widthTop = 92/dividor;
    rbWidth = rbWidth/dividor;
    let delta = (rbWidth - widthTop)/2;

    
    ctx.lineWidth="2";
    ctx.strokeStyle='#000000';
    ctx.strokeRect(x, y, rbWidth, depth);
    ctx.lineWidth="1";
    ctx.strokeStyle='#000000';
    ctx.strokeRect(x+delta, y, widthTop, depth);

    ctx.beginPath();
    ctx.moveTo(x + rbWidth/2, y);
    ctx.lineTo(x + rbWidth/2, y + depth)
    ctx.lineWidth="2";
    ctx.strokeStyle='#000000';
    ctx.stroke();
    
};