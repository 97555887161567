import React, {useState} from "react";
import { Link, withRouter } from "react-router-dom";
import "./App.css";
import "./style.css";
import { resetCalculation } from "./actions/calculationsAction";
import { connect } from "react-redux";
import { logOut, logIn } from "./actions/accountAction";
import logo from "./assets/img/logo.png";
import fundusze from "./assets/img/fundusze.png";
import rp from "./assets/img/rp.png";
import unia from "./assets/img/unia.png";
import lubelskie from "./assets/img/lubelskie.png";
import castel from "./assets/img/castel.png";
import useLoginAPIActions from "./components/services/LoginWrapper";
import ReactModal from "react-modal";

const App = (props) => {
  const location = props.location.pathname;
  const { children, logOut, logIn, logged } = props;
  const { companyName } = props.account;
  const [showConsent, setShowConsent] = useState(true);
  const [showAgreement, setShowAgreement] = useState(true);

  const saveCookieConsent = () => {
    localStorage.setItem('cookieConsent', true);
    setShowConsent(false);
  }
  const saveAgreement = () => {
    localStorage.setItem('agreement', true);
    setShowAgreement(false);
  }

  useLoginAPIActions({ logIn, logged });
  return (
    <div>
      <div className="header-gradient">
        <div className="header">
          <div class="header-name">
            <img src={logo} alt="" />
          </div>
          {sessionStorage.getItem("SESSION_ID") ? (
            <nav>
              <Link
                to="/calc/"
                className={`navLink ${
                  location.includes("/calc/") ? "navLink-selected" : ""
                }`}
                onClick={() => resetCalculation()}
              >
                <div>Kalkulator</div>
              </Link>
              <Link
                to="/calcList"
                className={`navLink ${
                  location === "/calcList" ? "navLink-selected" : ""
                }`}
              >
                <div>Projekty</div>
              </Link>
              <div className="dropdown">
                <span
                  className={`dropbtn navLink ${
                    location === "/account" ? "navLink-selected" : ""
                  }`}
                >
                  <div>{companyName}</div>
                </span>
                <div className="dropdown-content">
                  <Link
                    to="/account"
                    className={`navLink ${
                      location === "/account" ? "navLink-selected" : ""
                    }`}
                  >
                    Mój profil
                  </Link>
                  <Link to="/login" className="navLink" onClick={logOut}>
                    Wyloguj
                  </Link>
                </div>
              </div>
            </nav>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="app-container">{children}</div>
      <div className="footer">
        <img src={lubelskie} alt="" />
        <img src={unia} alt="" />
        <img src={rp} alt="" />
        <img src={fundusze} alt="" />
        <img src={castel} alt="" />
      </div>
      <ReactModal
        style={{
          content: {
            position: "absolute",
            inset:
              "calc(50% - 100px) calc(50% - 250px) calc(50% - 100px) calc(50% - 250px)",
          },
        }}
        isOpen={showAgreement && !localStorage.getItem('agreement')}
        contentLabel="Warning"
      >
        <div>
          <label className="labelBold modalLabel">
            Zamykając to okno akceptujesz fakt, że przedstawione w tym dokumencie kalkulacje są tylko poglądowe, oraz że pełną odpowiedzialność za jakość i bezpieczeństwo zastosowanych rozwiązań ponosi ich ostateczny wykonawca. Dostarczyciel tego oprogramowania, będący jednocześnie dostawcą elementów montażowych, jest z tej odpowiedzialności zwolniony.
          </label>
        </div>
        <div className="modalButton">
          <button onClick={() => saveAgreement()}>OK</button>
        </div>
      </ReactModal>
      {showConsent && !localStorage.getItem('cookieConsent') ?
      <div className='cookiesBanner'>
        <div className="content">
          {/* Ta strona do prawidłowego działania korzysta z plików cookies i podobnych technologii. Dalsze korzystanie ze strony oznacza, że zgadzasz się na ich użycie. */}
          Ten serwis wykorzystuje pliki cookies i inne technologie. Korzystanie z witryny oznacza zgodę na ich zapis lub odczyt według ustawień przeglądarki.
        </div>
        <div className="close" onClick={()=>saveCookieConsent()}>
          X
        </div>
      </div>
      : ''
      }
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    account: state.accountReducer.user,
    logged: state.accountReducer.logged,
  };
};

const mapDispatchToProps = {
  resetCalculation,
  logOut,
  logIn,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App));
