import { useState, useCallback, useEffect } from "react";
import { BASE_REQUESTS, CUSTOMER_REQUESTS } from "../../constans/requests";
import { useHistory } from "react-router-dom";
import { getAuthHeaders, getJwt } from "../../utils/utils";

const initialState = {
  password: "",
  confirmPassword: "",
  email: "",
  success: false,
  error: false,
};

export const useLoginAPIActions = ({ logIn, logged }) => {
  const [state, setValue] = useState(initialState);
  const history = useHistory();
  const isUser = getJwt();

  const setAnyValue = (e) => {
    setValue({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const clearState = () => {
    setValue({ ...initialState });
  };

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();

      const { password, email } = state;

      // if (confirmPassword === password && password.length > 0) {
      if (password.length > 0) {
        fetch(BASE_REQUESTS.POST.LOGIN, {
          method: "POST",
          mode: "cors",
          cache: "no-cache",
          credentials: "same-origin",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ password, email }),
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.success && data.user) {
              setValue({ ...state, success: data.success });
              logIn(data.user);
              sessionStorage.setItem("SESSION_ID", data.token);
              setTimeout(() => history.push("/calc/"), 1000);

              clearState();
            }

            if (!data.success) {
              setValue({ ...state, error: data.message });
              if (data.clear) {
                sessionStorage.removeItem("SESSION_ID");
                history.push("/");
              }
              // your code here...
            }
          })
          .catch((error) => console.log("error", error));
      }
    },
    [state, history, logIn]
  );

  const getUser = useCallback(() => {
    fetch(CUSTOMER_REQUESTS.GET.GET_USER, {
      method: "GET",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      headers: { ...getAuthHeaders() },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success && data.user) {
          setValue({ ...state, success: data.success });
          logIn(data.user);

          clearState();
        }

        if (!data.success) {
          setValue({ ...state, error: data.message });
          if (data.clear) {
            sessionStorage.removeItem("SESSION_ID");
            history.push("/");
          }
          // your code here...
        }

        if (data.token) {
          sessionStorage.setItem("SESSION_ID", data.token);
        }
      })
      .catch((error) => console.log("error", error));
  }, [logIn, state, history]);

  useEffect(() => {
    if (isUser && logIn && !logged) {
      getUser();
    }
  }, []);

  return {
    getUser,
    state,
    onSubmit,
    setAnyValue,
    clearState,
  };
};

export default useLoginAPIActions;
