import React, { useState, useEffect } from 'react';
import ReactModal from 'react-modal';
import {Report} from './Report';
import {pdf} from '@react-pdf/renderer';
import {projectList} from '../utils/const';
import { saveAs } from 'file-saver';
import close from '../assets/img/close-black.svg'

const generatePdfDocument = async (
    accountData, 
    clientData, 
    documentType, 
    documentTitle, 
    calculations, 
    dateString,
    projectName,
    comment,
    signature
    ) => {
        const blob = await pdf((
        <Report 
            accountData={accountData} 
            clientData={clientData} 
            documentType={documentType}
            data={calculations}
            documentTitle={documentTitle}
            dateString={dateString}
            projectName={projectName}
            comment={comment}
            signature={signature}
        />
    )).toBlob();
    saveAs(blob, 'report');
};

export const ReportPopup = ({
    showPrintModal, 
    handleClosePrintModal, 
    accountData, 
    clientData, 
    documentType, 
    handleChangeAccountData, 
    handleChangeClientData, 
    documentTitle, 
    handleChangeDocumentTitle, 
    calculations, 
    handleGenerateReport, 
    reportDate, 
    setReportDate, 
    projectName, 
    setProjectName,
    comment, 
    setComment,
    signature,
    setSignature,
}) => {
    let type = '';
    if(documentType === 'OC') {
        type = 'Potwierdzenie Zamówienia';
    } else if (documentType === 'O') {
        type = 'Oferta';
    } else if(documentType === 'ZW'){
        type = 'Zamówienie'
    }
    let sum = 0;
    calculations && calculations.map(row => sum += row.result ? parseInt(row.result.price) : 0);

    const date = new Date();
    const month = date.getMonth()+1;
    const dateString = date.getDate() + '.' + month + '.' + date.getFullYear();
            
    const customStyles = {
        content: {
            padding: '40px 40px 40px 40px',
            overflow: 'none',
        }
    }
    return (
        
        <ReactModal 
            isOpen={showPrintModal}
            contentLabel="Drukuj"
            style={customStyles}
          >
            <div className="closeButton" onClick={handleClosePrintModal}>
                <img src={close}/>
            </div>
            <div className="reportContainer">
            <div className="leftPosition labelBig full">Od:</div>
                <div className="leftColumn">
                        <div>
                            <input value={accountData.companyName} onChange={e=>handleChangeAccountData("companyName", e.target.value)}/>
                        </div>
                        <div>
                            <input value={accountData.address} onChange={e=>handleChangeAccountData("address", e.target.value)}/>
                        </div>
                        <div>
                            <input value={accountData.address2} onChange={e=>handleChangeAccountData("address2", e.target.value)}/>
                        </div>
                </div>
                <div className="rightColumn">
                    <div><span className="popupDesc">data:</span><input value={reportDate} onChange={e=>setReportDate(e.target.value)}/></div>
                    <div><span className="popupDesc">tel:</span><input value={accountData.phone}onChange={e=>handleChangeAccountData("phone", e.target.value)}/></div>
                    <div><span className="popupDesc">email:</span><input value={accountData.email} onChange={e=>handleChangeAccountData("email", e.target.value)}/></div>
                </div>
                <div className="full centerPosition marginTop">
                    <div>    
                        <div className="leftPosition">{type} nr </div>
                        <input value={accountData.documentTitle} onChange={e=>handleChangeDocumentTitle(e.target.value)}/>
                    </div>
                </div>
                <div className="leftColumn marginTop">
                    <div className="labelBig">Dla:</div>
                    <div>
                        <input value={clientData.companyName} placeholder="Nazwa firmy" onChange={e=>handleChangeClientData("companyName", e.target.value)}/>
                    </div>
                    <div>
                        <input value={clientData.address} placeholder="Adres" onChange={e=>handleChangeClientData("address", e.target.value)}/>
                    </div>
                    <div>
                        <input value={clientData.address2} placeholder="Adres cd" onChange={e=>handleChangeClientData("address2", e.target.value)}/>
                    </div>
                </div>
                <div className="leftColumn marginTop">
                    <div>Projekt: </div>
                    <div>
                    <input value={projectName} onChange={e=>setProjectName(e.target.value)}/>
                    </div>
                </div>
                {calculations !== undefined ? (
                <div className="full marginTop">
                    <table className="projectsList-table centerPosition">
                        <thead>
                            <tr> 
                                <th>lp</th> 
                                <th>Podstawa</th> 
                                <th>Ilość</th> 
                                <th>Cena podstawy netto</th> 
                                <th>Wartość netto</th>
                                <th>Wybrane urządzenie</th>  
                            </tr>
                        </thead>
                        <tbody>
                        {calculations && calculations.length > 0 && calculations.map((row, index) => {
                            if(row.result!== undefined)  return (
                            <tr> 
                            <td>{parseInt(index)+1}</td> 
                            <td>{row.result.name}</td> 
                            <td>1</td> 
                            <td>{row.result.price} zł</td> 
                            <td>{parseInt(row.result.price)*1} zł</td> 
                            <td>{row.deviceName}</td> 
                        </tr> 
                        )})}
                        </tbody>
                        <tfoot>
                            <tr> 
                                <td></td> 
                                <td></td> 
                                <td></td> 
                                <td>Suma</td> 
                                <td>{sum} zł</td>
                                <td></td> 
                            </tr>
                        </tfoot> 
                    </table>
                </div>
                )       
                : ''
                }
                <div className="full marginTop leftPosition">
                    <div>
                        <label>Uwagi: </label>
                    </div>
                    <textarea className="area" value={comment} onChange={e=>setComment(e.target.value)}></textarea>
                </div>
                <div className="leftColumn marginTop">
                    <div className="labelBig">
                        Wystawił:
                    </div>
                    <div>
                        <input value={signature} onChange={e=>setSignature(e.target.value)}/>
                    </div>
                </div>
                <div className="rightColumn marginTop">
                    {/* <div>
                    ______________________________________
                    </div>
                    <div className="labelBig">
                        Odebrał
                    </div> */}
                </div>
            </div>
            <div className="marginTop">
                <button onClick={()=>   {generatePdfDocument(accountData, clientData, documentType, documentTitle, calculations, dateString, projectName, comment, signature); handleGenerateReport()}}>
                    generuj pdf
                </button>
            </div>
          </ReactModal>
    
    )
}