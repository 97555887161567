import React from "react";
import { connect } from "react-redux";
import ReactModal from "react-modal";
import { withRouter } from "react-router";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faPlus, faTrash, faSave, faTimes } from '@fortawesome/free-solid-svg-icons'
import ReactTooltip from "react-tooltip"
import "../App.css";
import "../style.css";
import {
  calcFrameFoot,
  calcFrameFootMini,
  calcRB,
  calcRiser,
  calcBasePad,
} from "../utils/calc";
import { devices, heights, resultOptions } from "../utils/const";
import { Result } from "../components/Result";
import { CanvasSmall } from "../components/CanvasSmall";
import {
  drawDevice,
  drawCube,
  drawFF,
  drawFFMini,
  drawRB,
  cleanCanvas,
  setDividor,
} from "../utils/draw";
import { CalculatorEdit } from "../components/CalculatorEdit";
import { CalculatorView } from "../components/CalculatorView";
import { getCurrentDate } from "../utils/utils";

import { Print } from "../components/Print";
import { pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import { useProjectAPIActions } from "../components/services/ProjectWrapper";
import { useCalculationAPIActions } from "../components/services/CalculationWrapper";

import {
  setHeight,
  setDepth,
  setWeight,
  setWidth,
  setRbWidth,
  setRbHeight,
  setFfHeight,
  setCurrentResult,
  setDevice,
  setCalculationDate,
  setCalculationName,
  setResult,
  saveCalculation,
  actualizeCalculation,
  selectCalculation,
  resetCalculation,
  createProjectWithCalculation,
  setCurrentCalculation,
  copyCalculation,
  setCurrentProject,
  removeCalculations,
  saveCalculationToProject,
  loadCustomerProjects,
  createProject,
} from "../actions/calculationsAction";
import { ReportPopup } from "../components/ReportPopup";
import _ from "lodash";
import { parseDate } from "../utils/utils";

const generatePrint = async () => {
  const blob = await pdf(<Print />).toBlob();
  saveAs(blob, "report");
};

class Calculation extends React.Component {
  constructor() {
    super();
    this.state = {
      producer: "",
      name: "",
      series: "",
      model: "",
      producersList: [],
      namesList: [],
      seriesList: [],
      codesList: [],
      showSaveModal: false,
      showPrintModal: false,
      showDeleteModal: false,
      showResultModal: false,
      showWarningModal: false,
      device: {},
      results: [],

      accountData: {
        accountName: "",
        address: "",
        address2: "",
        phone: "",
        email: "",
        discount: "",
      },
      clientData: {
        accountName: "",
        address: "",
        address2: "",
        phone: "",
        email: "",
        discount: "",
      },
      documentType: "",
      projectName: "",
      generateReport: false,
      reportDate: getCurrentDate(),

      //tryb podglądu czy edycji
      editMode: true,
      generateReport: false,
      documentTitle: false,
      hideCube: false,
      //flaga czy podajemy parametry przez wybór urządzenia czy ręcznie
      chooseParamsManually: false,
      projectToSave: "",
      warningMessage: "",
      dividor: 10,
      signature: '',
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    // this.selectDevice = this.selectDevice.bind(this);
    this.calculate = this.calculate.bind(this);
    this.handleOpenSaveModal = this.handleOpenSaveModal.bind(this);
    this.handleCloseSaveModal = this.handleCloseSaveModal.bind(this);
    this.handleOpenPrintModal = this.handleOpenPrintModal.bind(this);
    this.handleClosePrintModal = this.handleClosePrintModal.bind(this);
    this.handleOpenDeleteModal = this.handleOpenDeleteModal.bind(this);
    this.handleCloseDeleteModal = this.handleCloseDeleteModal.bind(this);
    this.handleOpenResultModal = this.handleOpenResultModal.bind(this);
    this.handleCloseResultModal = this.handleCloseResultModal.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleOpenWarningModal = this.handleOpenWarningModal.bind(this);
    this.handleCloseWarningModal = this.handleCloseWarningModal.bind(this);
    // this.handleSave = this.handleSave.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    // this.handleSelectCalculation = this.handleSelectCalculation.bind(this);

    this.selectProducer = this.selectProducer.bind(this);
    this.selectName = this.selectName.bind(this);
    this.selectSeries = this.selectSeries.bind(this);
    this.selectCode = this.selectCode.bind(this);
    this.selectCurrentResult = this.selectCurrentResult.bind(this);

    this.handleChangeAccountData = this.handleChangeAccountData.bind(this);
    this.handleChangeClientData = this.handleChangeClientData.bind(this);
    this.handleChangeDocumentType = this.handleChangeDocumentType.bind(this);

    this.setProjectName = this.setProjectName.bind(this);
    this.setEditMode = this.setEditMode.bind(this);
    this.resetCalculation = this.resetCalculation.bind(this);
    this.handleCopyCalculation = this.handleCopyCalculation.bind(this);
    this.setChooseParamsManually = this.setChooseParamsManually.bind(this);
    this.handleChangeProjectToSave = this.handleChangeProjectToSave.bind(this);
    this.handleGenerateReport = this.handleGenerateReport.bind(this);

    this.handleChangeDocumentTitle = this.handleChangeDocumentTitle.bind(this);
    this.setProjectNameForReport = this.setProjectNameForReport.bind(this);
    this.setReportDate = this.setReportDate.bind(this);
    this.setComment = this.setComment.bind(this);
    this.setSignature = this.setSignature.bind(this);

    this.addCalculation = this.addCalculation.bind(this);
  }

  handleGenerateReport() {
    this.setState({
      // generateReport: true,
      showPrintModal: false,
      documentType: "",
    });
  }

  addCalculation() {
    this.props.resetCalculation();
    this.props.history.push(`/calc/${this.props.projectId}`);
  }

  //akcje dla raportu
  setComment(comment) {
    this.setState({
      comment: comment,
    });
  }

  setSignature(signature) {
    this.setState({
      signature: signature,
    })
  }

  setProjectNameForReport(name) {
    this.setState({
      projectNameForReport: name,
    });
  }

  setReportDate(date) {
    this.setState({
      reportDate: date,
    });
  }
  // getSelectedCalculations() {
  //     const {projectList} = this.props.calculations;
  //     const {selectedProject, selectedCalculations, wholeProjectSelected} = this.state;
  //     console.log('get selected', selectedProject, selectedCalculations, wholeProjectSelected)
  //     if(selectedProject > -1) {
  //         if(wholeProjectSelected) {
  //             return projectList[selectedProject].calculationList;
  //         } else {
  //             console.log(projectList[selectedProject], projectList[selectedProject].calculationList, selectedCalculations)
  //             return projectList[selectedProject].calculationList.filter((proj, id) => selectedCalculations.includes(id));
  //         }
  //     }
  // }

  handleChangeDocumentTitle(title) {
    this.setState({
      documentTitle: title,
    });
  }

  handleChangeProjectToSave(projectId) {
    this.setState({
      projectToSave: projectId,
      projectName: "",
    });
  }

  setChooseParamsManually(chooseParamsManually) {
    this.setState({
      chooseParamsManually: chooseParamsManually,
    });
    this.props.setWidth('');
    this.props.setHeight('');
    this.props.setDepth('');
    this.props.setWeight('');
  }

  handleCopyCalculation() {
    this.props.copyCalculation(this.props.calculations.currentCalculation);
    this.setState({
      editMode: true,
    });
  }

  resetCalculation() {
    this.selectProducer("");
    this.selectName("");
    this.selectSeries("");
    this.selectCode("");
    this.props.resetCalculation();
    cleanCanvas("CanvasFront");
    this.setState({
      hideCube: false,
    });
    // this.props.history.push("/calc/");
  }

  setEditMode() {
    this.setState({
      editMode: true,
      chooseParamsManually: true,
    });
  }

  setProjectName(name) {
    this.setState({
      projectName: name,
      projectToSave: "",
    });
  }

  handleOpenSaveModal() {
    console.log('handle open save modal')
    // if(this.props.calculations.currentCalculation.calculationName !== "") {
      if(this.props.calculations.currentCalculation.result.price) {
        if (this.props.calculations.currentProject !== -1) {
          this.props.calculationId
            ? this.props.updateCalculation(
                this.props.calculations.currentCalculation
              )
            : this.props.addCalculation(this.state.projectToSave);
        } else {
          this.setState({
            showSaveModal: true
          })
        }
      } else {
        this.setState({
          showWarningModal: true,
          warningMessage: "Kalkulacja nie jest poprawna"
        })  
      }
    // } else {
    //   this.setState({
    //     showWarningModal: true,
    //     warningMessage: "Nie podano nazwy kalkulacji"
    //   })
    // }
  }

  handleCloseSaveModal() {
    this.setState({ showSaveModal: false });
  }

  handleOpenPrintModal() {
    this.setState({ showPrintModal: true, generateReport: false });
  }

  handleClosePrintModal() {
    this.setState({ showPrintModal: false });
  }

  handleOpenDeleteModal() {
    this.setState({ showDeleteModal: true });
  }

  handleCloseDeleteModal() {
    this.setState({ showDeleteModal: false });
  }

  handleOpenResultModal() {
    this.setState({ showResultModal: true });
  }

  handleCloseResultModal() {
    this.setState({ showResultModal: false });
  }
  
  handleOpenWarningModal() {
    this.setState({ showWarningModal: true });
  }

  handleCloseWarningModal() {
    this.setState({ showWarningModal: false });
  }

  handleSave() {
    if (this.state.projectName !== "") {
      this.props.createProjectWithCalculation(this.state.projectName);
    } else if (this.state.projectToSave !== "") {
      this.props.saveCalculationToProject(
        this.state.projectToSave,
        this.props.calculations.currentCalculation
      );
    }
    // console.log('zapis', this.props.calculations.currentCalculation.calculationName)
    this.setState({
      showSaveModal: false,
      editMode: false,
      // calcName: this.props.calculations.currentCalculation.calculationName
    });
  }

  handleDelete() {
    const projectIndex = this.props.calculations.projectList.findIndex(
      (project) => project.id == this.props.projectId
    );
    const calculationIndex = this.props.calculations.projectList[
      projectIndex
    ].calculationList.findIndex(
      (calc) => calc.calculationId == this.props.calculationId
    );
    this.props.removeCalculations(projectIndex, [calculationIndex]);
    this.setState({ showDeleteModal: false });
  }

  handleChange({ target }) {
    this.setState({
      [target.name]: target.value,
    });
  }

  handleClick({ target }) {
    let result = 0;
    if (target.name === "frameFoot") {
      result = 1;
    } else if (target.name === "miniFrameFoot") {
      result = 2;
    } else {
      result = 3;
    }
    this.props.setCurrentResult(result);
  }

  handleSelect(target) {
    this.setState({
      currentResult: target.value,
    });
  }

  selectProducer(producer) {
    let deviceNames = new Set();
    this.props.calculations.devices.forEach((device) => {
      if (device.producer === producer) deviceNames.add(device.name);
    });

    this.setState({
      producer: producer,
      namesList: Array.from(deviceNames),
      seriesList: [],
      codesList: [],
    });
    this.props.setResult({});
  }
  selectName(name) {
    let deviceSeries = new Set();
    this.props.calculations.devices.forEach((device) => {
      if (device.producer === this.state.producer && device.name === name)
        deviceSeries.add(device.series);
    });

    this.setState({
      name: name,
      seriesList: Array.from(deviceSeries),
      codesList: [],
    });
    this.props.setResult({});
  }
  selectSeries(series) {
    let deviceCodes = this.props.calculations.devices.filter((device) =>
      device.producer === this.state.producer &&
      device.name === this.state.name &&
      device.series === series
        ? device.code
        : null
    );

    this.setState({
      series: series,
      codesList: deviceCodes.map((device) => device.code),
    });
    this.props.setResult({});
  }

  selectCode(code) {
    if (code !== "") {
      let device = this.props.calculations.devices.filter(
        (device) =>
          device.producer === this.state.producer &&
          device.name === this.state.name &&
          device.series === this.state.series &&
          device.code === code
      );
      this.props.setDevice(device[0]);
      // this.props.setCalculationName(code);
    }
    this.props.setResult({});
  }

  selectCurrentResult(currentResult) {
    let results = resultOptions;
    if (currentResult !== "0") {
      switch (currentResult) {
        case "1": {
          results = resultOptions.filter(
            (opt) => opt.id === 1 || opt.id === 2 || opt.id === 0
          );
          break;
        }
        case "2": {
          results = resultOptions.filter((opt) => opt.id === 3 || opt.id === 0);
          break;
        }
        case "3": {
          results = resultOptions.filter((opt) => opt.id === 6 || opt.id === 0);
          break;
        }
        case "4": {
          results = resultOptions.filter((opt) => opt.id === 4 || opt.id === 0);
          break;
        }
        case "5": {
          results = resultOptions.filter((opt) => opt.id === 5 || opt.id === 0);
          break;
        }
        case "1": {
          results = resultOptions.filter(
            (opt) => opt.id === 1 || opt.id === 2 || opt.id === 0
          );
          break;
        }
      }
    }
    this.setState({
      results: results,
    });
    // this.props.setCurrentResult(currentResult);
    this.props.setResult({});
  }

  handleChangeAccountData(field, value) {
    let { accountData } = this.state;
    accountData[field] = value;
    this.setState({
      accountData: accountData,
    });
  }
  handleChangeClientData(field, value) {
    let { clientData } = this.state;
    clientData[field] = value;
    this.setState({
      clientData: clientData,
    });
  }

  handleChangeDocumentType(value) {
    if (this.props.calculations.currentCalculation.result.width) {
      this.setState({
        documentType: value,
      });
      if (value !== "") {
        this.handleOpenPrintModal();
      }
    } else {
      this.handleOpenResultModal();
      this.setState({
        documentType: "",
      });
    }
  }

  // handleSelectCalculation(id) {
  //   console.log(id)
  //   console.log(this.props.calculations.currentProject.calculationList)
  //   let result = this.props.calculations.currentProject.calculationList.find(elem => elem.calculationId === id)
  //   console.log(result)
  //   // console.log(this.props.selectCalculation(this.props.calculations.currentProject.calculationList.find(calc=> calc.calculationId === id)))
  // }

  calculate() {
    let dividor = this.state.dividor;
    let {
      width,
      depth,
      weight,
      height,
      ffHeight,
      rbWidth,
      rbHeight,
      currentResult,
    } = this.props.calculations.currentCalculation;
    let { ffDevices, ffMiniDevices, ffMiniDevices300, ffMiniDevices500, rbDevices, rbBDevices, rbSANDevices, rbSANBDevices, rbSTRDevices, riserDevices, basePadDevices } = this.props.calculations;
    let { companyName, address, address2, email, phone } = this.props.account;
    let result = {};
    this.setState({
      device: {
        width: width,
        height: height,
        depth: depth,
        ffHeight: ffHeight,
        rbWidth: rbWidth,
        rbHeight: rbHeight,
      },
    });
    // zmiana rozmiaru obrazków dla mniejszych kalkulacji
    // if(width <= 500) {
    //   this.setState({
    //     dividor: 5
    //   })
    //   dividor = 5;
    // } else {
    //   this.setState({
    //     dividor: 10
    //   })
    //   dividor = 10;
    // }
    // drawCube("CanvasFront", 300, 300, width, depth, height, dividor);
    if (currentResult !== "0") {
      // if (parseInt(width) >= 350 && parseInt(depth) >= 150) {
        if (currentResult === "1") {
          result = calcRB(width, depth, weight, "130", rbDevices);
          drawRB("CanvasFront", result, width, depth, height, "130", rbHeight, dividor);
        } else if (currentResult === "2") {
          result = calcRB(width, depth, weight, "180", rbDevices);
          drawRB("CanvasFront", result, width, depth, height, "180", rbHeight, dividor);
        } else if (currentResult === "7") {
          result = calcRB(width, depth, weight, "130", rbBDevices);
          drawRB("CanvasFront", result, width, depth, height, "130", rbHeight, dividor);
        } else if (currentResult === "8") {
          result = calcRB(width, depth, weight, "180", rbBDevices);
          drawRB("CanvasFront", result, width, depth, height, "180", rbHeight, dividor);
        } else if (currentResult === "9") {
          result = calcRB(width, depth, weight, "130", rbSTRDevices);
          drawRB("CanvasFront", result, width, depth, height, "130", rbHeight, dividor);
        } else if (currentResult === "10") {
          result = calcRB(width, depth, weight, "180", rbSTRDevices);
          drawRB("CanvasFront", result, width, depth, height, "180", rbHeight, dividor);
        } else if (currentResult === "15") {
          result = calcRB(width, depth, weight, "130", rbSANDevices);
          drawRB("CanvasFront", result, width, depth, height, "130", rbHeight, dividor);
        } else if (currentResult === "16") {
          result = calcRB(width, depth, weight, "130", rbSANBDevices);
          drawRB("CanvasFront", result, width, depth, height, "130", rbHeight, dividor);
        } else if (currentResult === "3") {
          result = calcRiser(
            width,
            depth,
            weight,
            "180",
            // rbDevices.filter((device) => device.depth >= 600)
            riserDevices
          );
          drawRB("CanvasFront", result, width, depth, height, "180", "2", dividor);
        } else if (currentResult === "4") {
          result = calcFrameFoot(width, depth, weight, "1", ffDevices);
          drawFF("CanvasFront", result, width, depth, height, "1", dividor);
        } else if (currentResult === "5") {
          result = calcFrameFoot(width, depth, weight, "2", ffDevices);
          drawFF("CanvasFront", result, width, depth, height, "2", dividor);
          // drawFF("CanvasFront", result, width, depth, height, "2");
          // drawFF("CanvasFront", result, width, depth, height, "2");
        } else if (currentResult === "6") {
          result = calcFrameFootMini(width, depth, weight, ffMiniDevices);
          drawFFMini("CanvasFront", result, width, depth, height, result.rotated, dividor);
        } else if (currentResult === "13") {
          result = calcFrameFootMini(width, depth, weight, ffMiniDevices300);
          drawFFMini("CanvasFront", result, width, depth, height, result.rotated, dividor);
        } else if (currentResult === "14") {
          result = calcFrameFootMini(width, depth, weight, ffMiniDevices500);
          drawFFMini("CanvasFront", result, width, depth, height, result.rotated, dividor);
        } else if (currentResult === "11") {
          result = calcBasePad(width, depth, weight, basePadDevices);
          // drawFFMini("CanvasFront", result, width, depth, height, result.rotated, dividor);
        } else if (currentResult === "12") {
          result = calcBasePad(width, depth, weight, basePadDevices);
          // drawFFMini("CanvasFront", result, width, depth, height, result.rotated, dividor);
        }
        this.setState({
          // hideCube: result.width ? true : false,
          showResultModal: result.width ? false : true,
        });
      // } else {
      //   result = {
      //     name: "Minimalna szerokość elementu to 350mm, minimalna głębokość to 150mm",
      //   };
      //   cleanCanvas("CanvasFront");
      //   this.setState({
      //     hideCube: false,
      //     showResultModal: true,
      //   });
      // }
    } else {
      result = { name: "Należy wybrać rodzaj podstawy" };
      this.setState({
        // hideCube: false,
        showResultModal: true,
      });
    }
    // console.log('result', result)
    this.props.setResult(result);
  }

  componentDidMount() {
    let {dividor } = this.state;
    let { depth, width, height, currentResult, calculationId } =
      this.props.calculations.currentCalculation;
    let { devices } = this.props.calculations;
    let { companyName, street, postCode, city, phoneNumber, email, discount } =
      this.props.account.user;
    const { currentProject, projectList } = this.props.calculations;
    // drawDevice("CanvasSmall", 300, 300, width, depth, height);
    // drawCube("CanvasFront", 300, 300, width, depth, height, this.state.dividor);
    if (
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.projectId
    ) {
      this.setState({ projectToSave: this.props.match.params.projectId });
    }

    if (this.props.projectId && this.props.projectId !== "") {
        this.props.setCurrentProject(this.props.projectId);
        this.setState({
          editMode: true,
        });

        if(projectList.length > 0) {
          if (this.props.calculationId && this.props.calculationId !== "") {
          this.props.setCurrentCalculation(
            this.props.projectId,
            this.props.calculationId
          );
          this.setState({
            editMode: false,
          }); 
        }
      }
    } else {
      this.props.resetCalculation();
      this.props.history.push("/calc/");
      this.setState({
        editMode: true,
      });
    }

    let deviceProducers = new Set();
    Array.isArray(devices) &&
      devices.forEach((device) => deviceProducers.add(device.producer));

    this.setState({
      producersList: Array.from(deviceProducers),
      namesList: [],
      seriesList: [],
      codesList: [],
      results: resultOptions,
      accountData: {
        companyName: companyName,
        address: street,
        address2: `${postCode} ${city}`,
        phone: phoneNumber,
        email: email,
        discount: discount,
        forceRedirect: false,
        redirectTo: {},
      },
      calcName:
        currentProject > -1
          ? _.cloneDeep(projectList[currentProject].calculationName)
          : "",
      dividor: 10,    
    });
    //jeżeli jest wczytany wynik to przelicz
    // if (calculationId !== "" && calculationId !== 0) {
    if (this.props.match && this.props.match.params && this.props.match.params.calculationId) {
      this.calculate();
    }
    drawCube("CanvasSmall",10,10,  2000, 1000, 1000 ,dividor)
    
  }

  componentDidUpdate(prevProps, prevState) {
    const { redirectTo, forceRedirect, projectList } = this.props.calculations;
    const { width, height, depth } = this.props.calculations.currentCalculation;
    
    if (this.props.calculationId && this.props.calculationId !== "" && projectList.length > 0 && projectList.length !== prevProps.calculations.projectList.length) {
      this.props.setCurrentCalculation(
        this.props.projectId,
        this.props.calculationId
      );
      this.setState({
        editMode: false,
      });
      // this.calculate();
      // console.log('dwa')
    }
    
    if (
      prevProps.calculations.currentCalculation.width !==
        this.props.calculations.currentCalculation.width ||
      prevProps.calculations.currentCalculation.height !==
        this.props.calculations.currentCalculation.height ||
      prevProps.calculations.currentCalculation.depth !==
        this.props.calculations.currentCalculation.depth
    ) {
      // drawCube("CanvasFront", 300, 300, width, depth, height, this.state.dividor);
    }
    if (
      prevProps.calculations.forceRedirect !== forceRedirect &&
      forceRedirect === true
    ) {
      let redirectString = "/calc/";
      if (redirectTo.projectId) {
        redirectString += redirectTo.projectId;
        if (redirectTo.calculationId) {
          redirectString += "/calculation/" + redirectTo.calculationId;
        }
      }
      this.props.history.push(redirectString);
    }
    if (this.props.projectId !== prevProps.projectId) {
      if (
        this.props.projectId &&
        this.props.projectId !== "" &&
        this.props.calculationId &&
        this.props.calculationId !== ""
      ) {
        this.props.setCurrentCalculation(
          this.props.projectId,
          this.props.calculationId
        );
      } else {
        this.props.resetCalculation();
      }
    }
    let { calculationId } = this.props.calculations.currentCalculation;
    //po usunięciu wykrywamy że nie ma kalkulacji
    const currentProject = this.props.calculations.projectList.find(
      (proj) => proj.id == this.props.projectId
    );
    if (
      this.props.calculationId !== undefined &&
      currentProject &&
      currentProject.calculationList.findIndex(
        (calc) => calc.calculationId == this.props.calculationId
      ) == -1
    ) {
      this.props.history.push("/calc/");
    }
    if (
      calculationId === this.props.calculationId &&
      calculationId !== prevProps.calculations.currentCalculation.calculationId
    ) {
      this.calculate();
      this.setState({
        calcName: this.props.calculations.currentCalculation
        .calculationName
      })
    }
    if(
        this.props.calculations.currentCalculation.calculationId !== 
        prevProps.calculations.currentCalculation.calculationId &&
        this.props.calculations.currentCalculation.calculationId !== ""
      ) {
      this.calculate();
      this.setState({
        calcName: this.props.calculations.currentCalculation
        .calculationName
      })
    }
    drawCube("CanvasSmall",5 ,5, 1200, 600, 600 ,this.state.dividor)
  }

  render() {
    let {
      depth,
      width,
      height,
      currentResult,
      weight,
      ffHeight,
      rbWidth,
      calculationName,
      calculationDate,
      calculationId,
      result,
    } = this.props.calculations.currentCalculation;
    let { currentProject, projectList } = this.props.calculations;
    let {
      device,
      producersList,
      namesList,
      seriesList,
      codesList,
      results,
      projectName,
      chooseParamsManually,
      projectToSave,
    } = this.state;
    let { accountData, clientData, documentType } = this.state;
    return (
      <>
        <div className="background-calc">
          <ReportPopup
            showPrintModal={this.state.showPrintModal}
            handleClosePrintModal={this.handleClosePrintModal}
            accountData={accountData}
            clientData={clientData}
            documentType={documentType}
            handleChangeAccountData={this.handleChangeAccountData}
            handleChangeClientData={this.handleChangeClientData}
            handleChangeDocumentType={this.handleChangeDocumentType}
            handleGenerateReport={this.handleGenerateReport}
            generateReport={this.state.generateReport}
            documentTitle={this.state.documentTitle}
            handleChangeDocumentTitle={this.handleChangeDocumentTitle}
            calculations={[this.props.calculations.currentCalculation]}
            reportDate={this.state.reportDate}
            setReportDate={this.handleSetReportDate}
            projectName={this.state.projectNameForReport}
            setProjectName={this.setProjectNameForReport}
            comment={this.state.comment}
            setComment={this.setComment}
            signature={this.state.signature}
            setSignature={this}
          />
          {/* {currentProject > -1 ? ( */}
            <div className="projectNavigation-background">
              <div className="projectNavigation">
                <div className="nav_params">
                  <div className="param">
                    {currentProject > -1 ? 
                    <>
                    <label className="label">Projekt:</label>
                    <b><label className="label">
                      {projectList[currentProject].name}
                    </label>
                    </b>
                    </>
                    : ''}
                  </div>
                  {/* <div className="param param1">
                    <label className="label">Nazwa kalkulacji</label>
                    <label className="label labelBold">
                      {this.state.calcName}
                    </label>
                  </div> */}
                  {/* <div className="param param2">
                    <label className="label">Data</label>
                    <label className="label">
                      {parseDate(projectList[currentProject].date)}
                    </label>
                  </div> */}
                  <div className="param param3">
                    {currentProject >-1 ?
                    <>
                    <label className="label">Kalkulacja:</label>
                    
                    </>
                    : 
                    <label className="label">Nowa kalkulacja</label>
                      }
                  {currentProject > -1 && this.props.calculationId && this.props.calculationId !== "" ? 
                    <select
                    className="input"
                    // name="weight"
                    value={this.props.calculationId}
                    onChange={(e) => {
                      this.props.selectCalculation(
                        this.props.calculations.projectList[
                          currentProject
                        ].calculationList.find(
                          (calc) => calc.calculationId == e.target.value
                        ) || {}
                      )
                      this.props.history.push(
                        `/calc/${this.props.projectId}/calculation/${e.target.value}`
                      )
                      }
                    }
                  >
                    {/* <option></option> */}
                    {projectList[currentProject].calculationList.map((calc) => (
                      <option
                        value={calc.calculationId}
                        key={calc.calculationId}
                      >
                        {calc.calculationName}
                      </option>
                    ))}
                  </select>
                   : ''
                  }    
                  </div>
                  {calculationDate ? 
                    <div className="calculationDate param">              
                      <label className="">Data utworzenia: </label>
                      <label className="">{new Date(calculationDate).toLocaleDateString()}</label>
                    </div>
                    : '' }
                </div>
                {currentProject > -1 ? 
                <div className="nav_buttons">
                  <button onClick={() => this.addCalculation()} alt="dodaj"
                    data-for="main"
                    data-tip="Dodaj kalkulację"
                    data-iscapture="true"
                  >
                    <FontAwesomeIcon icon={faPlus}/>
                  </button>
                  {this.state.editMode ? 
                  <button onClick={() => this.handleOpenSaveModal()}
                    data-for="main"
                    data-tip="Zapisz kalkulację"
                    data-iscapture="true"
                  >
                      <FontAwesomeIcon icon={faSave} />
                  </button>
                  
                    : 
                    <button onClick={() => this.setEditMode()}
                    data-for="main"
                    data-tip="Edytuj kalkulację"
                    data-iscapture="true"
                  >
                    <FontAwesomeIcon icon={faEdit} />
                  </button>
                  }  
                  <button onClick={this.handleOpenDeleteModal}
                    data-for="main"
                    data-tip="Usuń kalkulację"
                    data-iscapture="true"
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </button>
                </div>
                 : '' 
                }
              </div>
              <ReactTooltip
                id="main"
                place="top"
                type="dark"
                effect="float"
                multiline={true}
              />
            </div>
          // ) : (
          //   ""
          // )}
          <div className="App">
            {/* {calculationName !== '' ? (
        <div className="mainInfo">
          <div>
            <label className="labelBold">Nazwa</label>
            <label>{calculationName}</label>
          </div>
          <div>
            <label className="labelBold">Data</label>
            <label>{calculationDate}</label>
          </div>
        </div>
        ) : ''
      } */}      
            <div className="calculatorContainer">
            {/* <div className="calculationName">
              {this.state.editMode ? 
              <label className="calculator-header">Nowa kalkulacja: </label>
              : (
                <>
                <label className="calculator-header">Kalkulacja: </label>
                <label className="labelBold">
                  {
                    this.props.calculations.currentCalculation
                      .calculationName
                  }
                </label>
                </>
                )}
              <label className="calculator-header">{calculationName}</label>
            </div> */}
            {/* {calculationDate ? 
            <div className="calculationDate">              
              <label className="">Data utworzenia: </label>
              <label className="">{new Date(calculationDate).toLocaleDateString()}</label>
            </div>
            : '' } */}
              {this.state.editMode ? (
                <CalculatorEdit
                  selectProducer={this.selectProducer}
                  producersList={producersList}
                  selectName={this.selectName}
                  namesList={namesList}
                  selectSeries={this.selectSeries}
                  seriesList={seriesList}
                  selectCode={this.selectCode}
                  codesList={codesList}
                  height={height}
                  setHeight={this.props.setHeight}
                  width={width}
                  setWidth={this.props.setWidth}
                  depth={depth}
                  setDepth={this.props.setDepth}
                  weight={weight}
                  setWeight={this.props.setWeight}
                  selectCurrentResult={this.selectCurrentResult}
                  heights={heights}
                  currentResult={currentResult}
                  setCurrentResult={this.props.setCurrentResult}
                  calculate={this.calculate}
                  results={results}
                  resetCalculation={this.resetCalculation}
                  chooseParamsManually={chooseParamsManually}
                  setChooseParamsManually={this.setChooseParamsManually}
                />
              ) : (
                <CalculatorView
                  selectProducer={this.selectProducer}
                  producersList={producersList}
                  selectName={this.selectName}
                  namesList={namesList}
                  selectSeries={this.selectSeries}
                  seriesList={seriesList}
                  selectCode={this.selectCode}
                  codesList={codesList}
                  height={height}
                  width={width}
                  depth={depth}
                  weight={weight}
                  heights={heights}
                  currentResult={currentResult}
                  results={results}
                  edit={this.setEditMode}
                />
              )}
              <Result elem={result} />
              <div className="paramButtons">
                <button onClick={this.resetCalculation}
                  data-for="main"
                  data-tip="Wyczyść parametry"
                  data-iscapture="true"
                >
                  <FontAwesomeIcon icon={faTimes} />
                </button>
                <button onClick={this.handleOpenSaveModal}
                  data-for="main"
                  data-tip="Zapisz kalkulację"
                  data-iscapture="true"
                >
                  <FontAwesomeIcon icon={faSave} />
                </button>
                <div className="generateReport">
                  <label className="labelBold" style={{width: 'auto'}}>Generuj</label>
                  <select
                    value={documentType}
                    onChange={(e) =>
                      this.handleChangeDocumentType(e.target.value)
                    }
                  >
                    <option value=""></option>
                    <option value="OC">Potwierdzenie zamówienia</option>
                    <option value="O">Oferta</option>
                    <option value="ZW">Zamówienie</option>
                  </select>
                </div>
                <ReactTooltip
                  id="main"
                  place="top"
                  type="dark"
                  effect="float"
                  multiline={true}
                />
              </div>
            </div>
            <div className="result">
              {/* <div className={`resultNavigation ${!this.props.calculations.currentCalculation.result.name ? 'hidden' : ''}`}>
                <div className="resultNavigation-param">
                  <div className="resultNavigation-name">
                    <label>Nazwa kalkulacji: </label>
                    {this.state.editMode ? (
                      <input
                        value={
                          this.props.calculations.currentCalculation
                            .calculationName
                        }
                        onChange={(e) =>
                          this.props.setCalculationName(e.target.value)
                        }
                      />
                    ) : (
                      <label className="labelBold">
                        {
                          this.props.calculations.currentCalculation
                            .calculationName
                        }
                      </label>
                    )}
                  </div>
                  <div className="resultNavigation-params">
                    <button onClick={this.handleOpenSaveModal}> Zapisz</button>
                    {currentProject !== -1 ? (
                      <>
                        <button onClick={this.handleOpenDeleteModal}>
                          {" "}
                          Usuń
                        </button>
                      </>
                    ) : (
                      ""
                    )}
                    <div className="generateReport">
                      <label className="labelBold" style={{width: 'auto'}}>Generuj</label>
                      <select
                        value={documentType}
                        onChange={(e) =>
                          this.handleChangeDocumentType(e.target.value)
                        }
                      >
                        <option value=""></option>
                        <option value="OC">Potwierdzenie zamówienia</option>
                        <option value="O">Oferta</option>
                        <option value="ZW">Zamówienie</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div> */}
              <ReactModal
                  style={{
                    content: {
                      position: "absolute",
                      inset:
                        "calc(50% - 100px) calc(50% - 200px) calc(50% - 100px) calc(50% - 200px)",
                    },
                  }}
                  isOpen={this.state.showSaveModal}
                  contentLabel="Zapisz"
                >
                  <div>
                  <div className="modalHeader">
                    <label className="labelBold">
                      Zapisz kalkulację {this.props.calculations.currentCalculation.calculationName}
                    </label>
                  </div>
                  <div>
                    <label className="labelBold">Podaj nawzę kalkulacji:</label>
                    <input
                      // className="input"
                      name="name"
                      value={calculationName}
                      onChange={(e) => {
                        // this.setCalculationName(e.target.value);
                        this.props.setCalculationName(e.target.value);
                      }}
                    />
                  </div>
                  <div>
                    <label className="labelBold">Stwórz nowy projekt:</label>
                    <input
                      // className="input"
                      name="name"
                      value={projectName}
                      onChange={(e) => {
                        this.setProjectName(e.target.value);
                        this.props.setProjectName(e);
                      }}
                    />
                  </div>
                  <div>
                    <label className="labelBold">
                      Lub wybierz istniejący
                    </label>
                    <select
                      value={projectToSave}
                      onChange={(e) =>
                        this.handleChangeProjectToSave(e.target.value)
                      }
                    >
                      <option> </option>
                      {projectList.map((project) => (
                        <option key={project.id} value={project.id}>
                          {project.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="modalButton">
                    <button onClick={this.handleCloseSaveModal}>
                      Anuluj
                    </button>
                    <button
                      onClick={(e) => {
                        // this.handleSave();
                        this.state.projectName
                          ? this.props.addProject(
                              e,
                              this.props.addCalculation
                            )
                          : this.props.addCalculation(
                              this.state.projectToSave
                            );
                      }}
                    >
                      Zapisz
                    </button>
                  </div>
                </div>
                </ReactModal>
                <ReactModal
                  style={{
                    content: {
                      position: "absolute",
                      inset:
                        "calc(50% - 70px) calc(50% - 200px) calc(50% - 70px) calc(50% - 200px)",
                    },
                  }}
                  isOpen={this.state.showDeleteModal}
                  contentLabel="Usuń"
                >
                  <div>
                    <label className="labelBold modalLabel">
                      Czy na pewno chcesz usunąć?
                    </label>
                  </div>
                  <div className="modalButton">
                    <button onClick={this.handleCloseDeleteModal}>
                      Anuluj
                    </button>
                    <button
                      onClick={() => {
                        this.props.deleteCalculation(
                          this.props.calculationId
                        );
                        this.handleDelete();
                      }}
                    >
                      Usuń
                    </button>
                  </div>
                </ReactModal>
                <ReactModal
                  style={{
                    content: {
                      position: "absolute",
                      inset:
                        "calc(50% - 70px) calc(50% - 200px) calc(50% - 70px) calc(50% - 200px)",
                    },
                  }}
                  isOpen={this.state.showResultModal}
                  contentLabel="Wynik"
                >
                  <div>
                    <label className="labelBold modalLabel">
                      {result && result.name
                        ? result.name
                        : "Nie wygenerowano kalkulacji"}
                    </label>
                  </div>
                  <div className="modalButton">
                    <button onClick={this.handleCloseResultModal}>OK</button>
                  </div>
                </ReactModal>
                <ReactModal
                  style={{
                    content: {
                      position: "absolute",
                      inset:
                        "calc(50% - 70px) calc(50% - 200px) calc(50% - 70px) calc(50% - 200px)",
                    },
                  }}
                  isOpen={this.state.showWarningModal}
                  contentLabel="Warning"
                >
                  <div>
                    <label className="labelBold modalLabel">
                      {this.state.warningMessage}
                    </label>
                  </div>
                  <div className="modalButton">
                    <button onClick={this.handleCloseWarningModal}>OK</button>
                  </div>
                </ReactModal>
              {/* {this.props.calculations.currentCalculation.result.name ?  */}
              <>
                {!this.state.hideCube ? (
                  <div class="imageContainer">
                    {/* <img src={wymiary} class="image" /> */}
                    {/* <CanvasSmall width={this.props.calculations.currentCalculation.width} height={this.props.calculations.currentCalculation.height} depth={this.props.calculations.currentCalculation.depth}/> */}
                  </div>
                ) : (
                  ""
                )}
                <div className="result-component">
                  <div>
                    <canvas
                      className="canvas"
                      id="CanvasFront"
                      width="760"
                      height="1400"
                    />
                  </div>
                </div>
              </>
              {/* : '' */}
              {/* } */}
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    calculations: state.calculationsReducer,
    account: state.accountReducer,
  };
};

const mapDispatchToProps = {
  setHeight,
  setDepth,
  setWeight,
  setWidth,
  setRbWidth,
  setRbHeight,
  setFfHeight,
  setCurrentResult,
  setDevice,
  setCalculationDate,
  setCalculationName,
  setResult,
  saveCalculation,
  actualizeCalculation,
  selectCalculation,
  resetCalculation,
  createProjectWithCalculation,
  setCurrentCalculation,
  copyCalculation,
  setCurrentProject,
  removeCalculations,
  saveCalculationToProject,
  loadCustomerProjects,
  createProject,
};

const CalculationWithWrapper = ({
  createProject,
  loadCustomerProjects,
  calculations,
  ...props
}) => {
  const {
    addCalculation,
    deleteCalculation,
    deleteManyCalculations,
    updateCalculation,
  } = useCalculationAPIActions(calculations);
  const { deleteProject, addProject, setAnyValue } =
    useProjectAPIActions(loadCustomerProjects);

  return (
    <Calculation
      addProject={addProject}
      setProjectName={setAnyValue}
      addCalculation={addCalculation}
      calculations={calculations}
      deleteProject={deleteProject}
      deleteCalculation={deleteCalculation}
      deleteManyCalculations={deleteManyCalculations}
      updateCalculation={updateCalculation}
      {...props}
    />
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CalculationWithWrapper)
);
