import { useState, useCallback, useEffect } from "react";
import { CUSTOMER_REQUESTS } from "../../constans/requests";
import { useHistory } from "react-router-dom";
import { getAuthHeaders } from "../../utils/utils";

const resultInitialState = {
  success: false,
  error: false,
};

const initialState = {
  projectName: "",
};

export const useProjectAPIActions = (loadCustomerProjects, createProject) => {
  const [state, setValue] = useState(initialState);
  const [resultState, setResultState] = useState(resultInitialState);
  const history = useHistory();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getProjects(), []);

  const setAnyValue = (e, projectName) => {
    setValue({
      ...state,
      projectName: projectName || e.target.value,
    });
  };

  const clearState = () => {
    setValue({ ...initialState });
    setResultState({ ...resultInitialState });
  };

  const addProject = useCallback(
    (e, handleSuccess) => {
      e.preventDefault();

      fetch(CUSTOMER_REQUESTS.POST.ADD_PROJECT, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { ...getAuthHeaders() },
        body: JSON.stringify({ name: state.projectName }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.success) {
            setResultState({ ...resultState, success: data.success });
            handleSuccess && handleSuccess(data.project.id);
            getProjects();
            clearState();
          }

          if (data.token) {
            sessionStorage.setItem("SESSION_ID", data.token);
          }

          if (!data.success) {
            setResultState({ ...resultState, error: data.message });
            if (data.clear) {
              sessionStorage.removeItem("SESSION_ID");
              history.push("/");
            }
            // your code here...
          }
        })
        .catch((error) => console.log("error", error));
    },
    [state, resultState, history]
  );

  const getProjects = useCallback(() => {
    fetch(CUSTOMER_REQUESTS.POST.GET_ALL_PROJECTS, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      headers: { ...getAuthHeaders() },
      body: JSON.stringify({}),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setResultState({ ...resultState, success: data.success });
          loadCustomerProjects(data.projects);
          clearState();
        }

        if (data.token) {
          sessionStorage.setItem("SESSION_ID", data.token);
        }

        if (!data.success) {
          setResultState({ ...resultState, error: data.message });
          if (data.clear) {
            sessionStorage.removeItem("SESSION_ID");
            history.push("/");
          }
          // your code here...
        }
      })
      .catch((error) => console.log("error", error));
  }, [resultState, loadCustomerProjects]);

  const deleteProject = (projectId, successHandler) => {
    fetch(CUSTOMER_REQUESTS.DELETE.DELETE_PROJECT, {
      method: "DELETE",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      headers: { ...getAuthHeaders() },
      body: JSON.stringify({ projectId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setResultState({ ...resultState, success: data.success });
          getProjects();
          clearState();
          successHandler();
        }

        if (data.token) {
          sessionStorage.setItem("SESSION_ID", data.token);
        }

        if (!data.success) {
          setResultState({ ...resultState, error: data.message });
          if (data.clear) {
            sessionStorage.removeItem("SESSION_ID");
            history.push("/");
          }
          // your code here...
        }
      })
      .catch((error) => console.log("error", error));
  };

  return {
    state,
    addProject,
    getProjects,
    setAnyValue,
    clearState,
    deleteProject,
  };
};
