import React from 'react';
import { Document, Page, Text, View, StyleSheet, Font, BlobProvider, Image } from '@react-pdf/renderer';
import source from '../assets/fonts/Roboto-Regular.ttf'
import sourceBold from '../assets/fonts/Roboto-Bold.ttf'
import logo from '../assets/img/logo-pdf.png'

// Create styles
const BORDER_COLOR = '#bfbfbf'
const BORDER_STYLE = 'solid'
const COL1_WIDTH = 10
const COLN_WIDTH = (100 - COL1_WIDTH) / 5
Font.register({ family: 'Roboto', src: source });
Font.register({ family: 'Roboto-Bold', src: sourceBold });

const styles = StyleSheet.create({
    body: {
        padding: 25,
        fontFamily: 'Roboto',
        fontSize: 10,
      },
    container: {
        flexDirection: 'row',
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1,
      },
      section2: {
        margin: 10,
        padding: 10,
        width: '50%',
      },
      section3: {
        margin: 10,
        padding: 10,
        width: '33%',
        textAlign: 'center',
      },

      table: { 
        display: "table", 
        width: "auto", 
        borderStyle: BORDER_STYLE, 
        borderColor: BORDER_COLOR,
        borderWidth: 1, 
        borderRightWidth: 0, 
        borderBottomWidth: 0 
      }, 
      tableRow: { 
        margin: "auto", 
        flexDirection: "row" 
      }, 
      tableCol1Header: { 
        width: COL1_WIDTH + '%', 
        borderStyle: BORDER_STYLE, 
        borderColor: BORDER_COLOR,
        borderBottomColor: '#000',
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0
      },     
      tableColHeader: { 
        width: COLN_WIDTH + "%", 
        borderStyle: BORDER_STYLE, 
        borderColor: BORDER_COLOR,
        borderBottomColor: '#000',
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0
      },   
      tableCol1: { 
        width: COL1_WIDTH + '%', 
        borderStyle: BORDER_STYLE, 
        borderColor: BORDER_COLOR,
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0 
      },   
      tableCol: { 
        width: COLN_WIDTH + "%", 
        borderStyle: BORDER_STYLE, 
        borderColor: BORDER_COLOR,
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0 
      }, 
      tableCellHeader: {
        margin: 5, 
        fontSize: 10,
        fontWeight: 500
      },  
      tableCell: { 
        margin: 5, 
        fontSize: 10 
      },
      label: {
        fontFamily:'Roboto-Bold',
      },
      title: {
        fontSize: 16,
        textAlign: 'center',
      },
      logo: {
        width: 200
      }
});

// Create Document Component
export const Print = ({}) => {
  return(
    <Document>
    <Page style={styles.body}>
      <Image 
        src={logo}
        style={styles.logo}
      ></Image>
      {/* <View style={styles.container}>
        <View style={styles.section2}>
            <View style={styles.label}>
              <Text>
                  {calculationName}
              </Text>
            </View>
        </View>  

        <View style={[styles.section2, {textAlign: 'right'}]}>
            <Text>{dateString}</Text>
            <Text>tel: {accountData.phone}</Text>
            <Text>email: {accountData.email}</Text>
        </View>  
      </View>
      <View style={[styles.title, styles.label]}>
        <Text>{`${type} nr ${documentTitle}`}</Text>
      </View>
      <View style={styles.container}>
        <View style={styles.section}>
            <View style={styles.label}>
              <Text>
                  Dla:
              </Text>
            </View>
            <Text>{clientData.companyName}</Text>
            <Text>{clientData.address}</Text>
            <Text>{clientData.address2}</Text>
        </View>    
      </View>
      <View style={styles.section2}>
        <Text>Projekt: {projectName}</Text>
      </View>
      <View>
        <View style={styles.table}> 
            <View style={styles.tableRow}> 
              <View style={styles.tableCol1Header}> 
                  <Text style={styles.tableCellHeader}>lp</Text> 
              </View> 
              <View style={styles.tableColHeader}> 
                  <Text style={styles.tableCellHeader}>Podstawa</Text> 
              </View> 
              <View style={styles.tableColHeader}> 
                  <Text style={styles.tableCellHeader}>Ilość</Text> 
              </View> 
              <View style={styles.tableColHeader}> 
                  <Text style={styles.tableCellHeader}>Cena podstawy netto</Text> 
              </View> 
              <View style={styles.tableColHeader}> 
                  <Text style={styles.tableCellHeader}>Wartość netto</Text> 
              </View> 
              <View style={styles.tableColHeader}> 
                  <Text style={styles.tableCellHeader}>Wybrane urządzenie</Text> 
              </View>
            </View>
            {data && data.map((row, index) => (
              <View style={styles.tableRow}> 
                <View style={styles.tableCol1}> 
                    <Text style={styles.tableCell}>{parseInt(index)+1}</Text> 
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{row.result.name}</Text> 
                </View>
                <View style={styles.tableCol}> 
                    <Text style={styles.tableCell}>1</Text> 
                </View> 
                <View style={styles.tableCol}> 
                    <Text style={styles.tableCell}>{row.result.price} zł</Text> 
                </View> 
                <View style={styles.tableCol}> 
                    <Text style={styles.tableCell}>{parseInt(row.result.price)*1} zł</Text> 
                </View> 
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{row.calculationName}</Text> 
                </View>
              </View> 
            ))}
            <View style={styles.tableRow}> 
              <View style={styles.tableCol1Header}> 
                  <Text style={styles.tableCellHeader}></Text> 
              </View> 
              <View style={styles.tableColHeader}> 
                  <Text style={styles.tableCellHeader}></Text> 
              </View> 
              <View style={styles.tableColHeader}> 
                  <Text style={styles.tableCellHeader}></Text> 
              </View> 
              <View style={styles.tableColHeader}> 
                  <Text style={styles.tableCellHeader}>Suma</Text> 
              </View> 
              <View style={styles.tableColHeader}> 
                  <Text style={styles.tableCellHeader}>{sum} zł</Text> 
              </View> 
              <View style={styles.tableColHeader}> 
                  <Text style={styles.tableCellHeader}></Text> 
              </View> 
            </View>        
        </View>
      </View>
      <View style={styles.section}>
          <Text>Uwagi:</Text>    
          <Text>{comment}</Text>
      </View>
      <View style={[styles.container, {marginTop: 100}]}>
        <View style={[styles.section2, , {textAlign: 'left'}]}>
          <Text>______________________</Text>
          <Text>Wystawił</Text>
        </View>
        <View style={[styles.section2, , {textAlign: 'right'}]}>
          <Text>______________________</Text>
          <Text>Odebrał</Text>
        </View>
      </View> */}
    </Page>
  </Document>
)
};