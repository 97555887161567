import * as CONST from "../constans/const";
import {
  ffDevices,
  ffMiniDevices,
  ffMiniDevices300,
  ffMiniDevices500,
  rbDevices,
  rbBDevices,
  rbSTRDevices,
  rbSANDevices,
  rbSANBDevices,
  riserDevices,
  basePadDevices,
  // calculationList,
  // projectList,
  devices,
} from "../utils/const";
import _ from "lodash";
import { setCurrentCalculation } from "../actions/calculationsAction";

const calculationsReducer = (
  state = {
    // calculationList: calculationList,
    projectList: [],

    currentProject: -1,
    // currentProject: {
    //     id: 0,
    //     name: '',
    //     date: '',
    //     calculationList: [],
    // },

    currentCalculation: {
      calculationId: "",
      calculationName: "",
      calculationDate: "",
      deviceName: "",
      width: "",
      depth: "",
      height: "",
      weight: "",
      resultType: 1,
      ffHeight: "1",
      rbWidth: "130",
      rbHeight: "1",
      currentResult: "0",
      result: {},
    },
    // resultFF: {
    //     name: '',
    //     width: 0,
    //     depth: 0,
    //     weight: 0,
    //     feets: 0,
    // },
    // resultFFmini: {},
    // resultRB: {},
    ffDevices: ffDevices,
    ffMiniDevices: ffMiniDevices,
    ffMiniDevices300: ffMiniDevices300,
    ffMiniDevices500: ffMiniDevices500,
    rbDevices: rbDevices,
    rbBDevices: rbBDevices,
    rbSANDevices: rbSANDevices,
    rbSANBDevices: rbSANBDevices,
    rbSTRDevices: rbSTRDevices,
    riserDevices:riserDevices,
    basePadDevices: basePadDevices,
    devices: devices,
    forceRedirect: false,
    redirectTo: {},
    isUpdated: false,
  },
  action
) => {
  switch (action.type) {
    case CONST.LOAD_CUSTOMER_PROJECTS: {
      return {
        ...state,
        projectList: [...action.payload],
      };
    }
    case CONST.SET_HEIGHT: {
      return {
        ...state,
        currentCalculation: {
          ...state.currentCalculation,
          height: action.payload,
          result: {},
        },
      };
    }
    case CONST.SET_WIDTH: {
      return {
        ...state,
        currentCalculation: {
          ...state.currentCalculation,
          width: action.payload,
          result: {},
        },
      };
    }
    case CONST.SET_DEPTH: {
      return {
        ...state,
        currentCalculation: {
          ...state.currentCalculation,
          depth: action.payload,
          result: {},
        },
      };
    }
    case CONST.SET_WEIGHT: {
      return {
        ...state,
        currentCalculation: {
          ...state.currentCalculation,
          weight: action.payload,
          result: {},
        },
      };
    }
    case CONST.SET_RB_WIDTH: {
      return {
        ...state,
        currentCalculation: {
          ...state.currentCalculation,
          rbWidth: action.payload,
          result: {},
        },
      };
    }
    case CONST.SET_RB_HEIGHT: {
      return {
        ...state,
        currentCalculation: {
          ...state.currentCalculation,
          rbHeight: action.payload,
          result: {},
        },
      };
    }
    case CONST.SET_FF_HEIGHT: {
      return {
        ...state,
        currentCalculation: {
          ...state.currentCalculation,
          ffHeight: action.payload,
          result: {},
        },
      };
    }
    case CONST.SET_CURRENT_RESULT: {
      return {
        ...state,
        currentCalculation: {
          ...state.currentCalculation,
          currentResult: action.payload,
          result: {},
        },
      };
    }
    case CONST.SET_DEVICE: {
      return {
        ...state,
        currentCalculation: {
          ...state.currentCalculation,
          width: action.payload.width,
          depth: action.payload.depth,
          height: action.payload.height,
          weight: action.payload.weight,
          deviceName: `${action.payload.producer}/${action.payload.series}/${action.payload.code}`,
        },
      };
    }
    case CONST.SET_CALCULATION_NAME: {
      return {
        ...state,
        currentCalculation: {
          ...state.currentCalculation,
          calculationName: action.payload,
        },
      };
    }
    case CONST.SET_CALCULATION_DATE: {
      return {
        ...state,
        currentCalculation: {
          ...state.currentCalculation,
          calculationDate: action.payload,
        },
      };
    }
    case CONST.SET_RESULT: {
      return {
        ...state,
        currentCalculation: {
          ...state.currentCalculation,
          result: action.payload,
        },
      };
    }
    case CONST.SELECT_CALCULATION: {
      return {
        ...state,
        currentCalculation: action.payload,
      };
    }
    case CONST.RESET_CALCULATION: {
      return {
        ...state,
        currentProject: -1,
        currentCalculation: {
          depth: "",
          width: "",
          height: "",
          weight: "",
          calculationName: "",
          calculationDate: "",
          calculationId: "",
          currentResult: "0",
          result: {},
        },
      };
    }
    case CONST.SAVE_CALCULATION: {
      console.log('save calc', action.payload)
      const date = new Date();
      const month = date.getMonth() + 1;
      const dateString =
        date.getDate() + "." + month + "." + date.getFullYear();

      const current = state.projectList[state.currentProject];
      
      if (state.currentCalculation.calculationId > 0) {
        const calcIndex = current.calculationList.findIndex(
          (calc) => calc.calculationId == state.currentCalculation.calculationId
        );
        current.calculationList = [
          ...current.calculationList.slice(0, calcIndex),
          state.currentCalculation,
          ...current.calculationList.slice(calcIndex + 1),
        ];
      } else {
        const result = _.cloneDeep(state.currentCalculation);
        current.calculationList.push({
          ...result,
          calculationId: current.calculationList.length + 1,
          calculationDate: dateString,
          // calculationName:
          //     state.currentCalculation.deviceName ||
          //     `${state.currentCalculation.width}/${state.currentCalculation.depth}-${state.currentCalculation.weight}`
        });
      }
      return {
        ...state,
        // calculationId: state.calculationList.length+1,
        projectList: [
          ...state.projectList.slice(0, state.currentProject),
          current,
          ...state.projectList.slice(state.currentProject + 1),
        ],
        forceRedirect: true,
        redirectTo: {
          projectId: current.id,
          calculationId: current.calculationList.length,
        },
        // currentCalculation: {
        //     ...state.currentCalculation,
        //     // calculationName: state.currentCalculation.deviceName ||
        //     // `${state.currentCalculation.width}/${state.currentCalculation.depth}-${state.currentCalculation.weight}`

        // }
      };
    }
    case CONST.CREATE_PROJECT: {
      const date = new Date();
      const month = date.getMonth() + 1;
      const dateString =
        date.getDate() + "." + month + "." + date.getFullYear();
      const project = {
        id: state.projectList.length + 1,
        name: action.payload,
        date: dateString,
        calculationList: [],
      };
      return {
        ...state,
        currentProject: state.projectList.length,
        projectList: [...state.projectList, project],
      };
    }
    case CONST.CREATE_PROJECT_WITH_CALCULATION: {
      const date = new Date();
      const month = date.getMonth() + 1;
      const dateString =
        date.getDate() + "." + month + "." + date.getFullYear();
      const project = {
        id: state.projectList.length + 1,
        name: action.payload,
        date: dateString,
        calculationList: [_.cloneDeep(state.currentCalculation)],
      };
      return {
        ...state,
        currentProject: state.projectList.length,
        projectList: [...state.projectList, project],
        forceRedirect: true,
        redirectTo: {
          projectId: project.id,
          calculationId: state.currentCalculation.calculationId,
        },
      };
    }
    case CONST.SET_CURRENT_PROJECT: {
      return {
        ...state,
        currentProject: state.projectList.findIndex(
          (project) => project.id == action.payload
        ),
      };
    }
    case CONST.REMOVE_PROJECT: {
      return {
        ...state,
        projectList: [
          ...state.projectList.slice(0, action.payload),
          ...state.projectList.slice(action.payload + 1),
        ],
      };
    }
    case CONST.REMOVE_CALCULATIONS: {
      const current = state.projectList[action.payload.project];
      action.payload.calculations.forEach((element) => {
        current.calculationList = [
          ...current.calculationList.slice(0, element),
          ...current.calculationList.slice(element + 1),
        ];
      });
      return {
        ...state,
        // calculationId: state.calculationList.length+1,
        projectList: [
          ...state.projectList.slice(0, action.payload.project),
          current,
          ...state.projectList.slice(action.payload.project + 1),
        ],
        currentCalculation: {},
      };
    }
    case CONST.SET_CURRENT_CALCULATION: {
      const currentProject = state.projectList.find(
        (project) => project.id == action.payload.projectId
      );
      const currentProjectId = state.projectList.findIndex(
        (project) => project.id == action.payload.projectId
      );
      const currentCalculation = currentProject.calculationList.find(
        (calc) => calc.calculationId == action.payload.calculationId
      );
      // const currentCalculation = state.projectList[currentProjectId].calculationList.find(calc=> {
      //     console.log(calc.calculationId, action.payload.calculationId)
      //     return calc.calculationId == action.payload.calculationId
      // }) || {};
      return {
        ...state,
        currentProject: currentProjectId,
        currentCalculation: _.cloneDeep(currentCalculation),
      };
    }
    case CONST.SAVE_CALCULATION_TO_PROJECT: {
      const current = state.projectList.find(
                                                                  (project) => project.id == action.payload.projectId
      );
      const currentIndex = state.projectList.findIndex(
        (project) => project.id == action.payload.projectId
      );
      current.calculationList.push(action.payload.calculation);
      return {
        ...state,
        // calculationId: state.calculationList.length+1,
        projectList: [
          ...state.projectList.slice(0, currentIndex),
          current,
          ...state.projectList.slice(currentIndex + 1),
        ],
        currentCalculation: action.payload.calculation,
        currentProject: currentIndex,
        forceRedirect: true,
        redirectTo: {
          projectId: action.payload.projectId,
          calculationId: action.payload.calculation.calculationId,
        },
      };
    }
    case CONST.COPY_CALCULATION: {
      const newCalculation = _.cloneDeep(action.payload);
      newCalculation.calculationId = -1;
      newCalculation.calculationName = "";
      newCalculation.date = "";
      return {
        ...state,
        currentCalculation: newCalculation,
      };
    }
    //     case CONST.ACTUALIZE_CALCULATION: {
    //         const date = new Date();
    //         const month = date.getMonth()+1;
    //         const dateString = date.getDate() + '.' + month + '.' + date.getFullYear();
    //         const calc = {
    //             id: state.calculationList.length+1,
    //             depth: state.depth,
    //             width: state.width,
    //             height: state.height,
    //             weight: state.weight,
    //             calculationName: state.calculationName,
    //             calculationDate: dateString,
    //         }
    //         return {
    //             ...state,
    //             calculationList: {
    //                 [action.payload]: {

    //                 }
    //             }

    //             [
    //                 ...state.calculationList,

    //             ]
    //         }
    //     }
    default: {
      return state;
    }
  }
};

export default calculationsReducer;
