export const BASE_API_PREFIX = "api";
export const ADMIN_API_PREFIX = "api/admin";
export const CUSTOMER_API_PREFIX = "api/customer";
// export const PROXY = "http://localhost:8080";
export const PROXY = "https://castelengineeringdev.xaa.pl";

export const CUSTOMER_REQUESTS = {
  GET: {
    GET_USER: `${PROXY}/${CUSTOMER_API_PREFIX}/get-user`,
  },
  POST: {
    GET_USER_CALCULATION: `${PROXY}/${CUSTOMER_API_PREFIX}/calculations`,
    ADD_CALCULATION: `${PROXY}/${CUSTOMER_API_PREFIX}/add-calculation`,
    GET_ALL_CALLCULATIONS_OF_PROJECT: `${PROXY}/${CUSTOMER_API_PREFIX}/get-calculations`,
    GET_ALL_PROJECTS: `${PROXY}/${CUSTOMER_API_PREFIX}/projects`,
    GET_PROJECT: `${PROXY}/${CUSTOMER_API_PREFIX}/get-project`,
    ADD_PROJECT: `${PROXY}/${CUSTOMER_API_PREFIX}/add-project`,
  },
  PUT: {
    UPDATE_CALCULATION: `${PROXY}/${CUSTOMER_API_PREFIX}/update-calculation`,
    UPDATE_PROJECT: `${PROXY}/${CUSTOMER_API_PREFIX}/update-project`,
    UPDATE_ACCOUNT: `${PROXY}/${CUSTOMER_API_PREFIX}/update-account`,
  },
  DELETE: {
    DELETE_CALCULATION: `${PROXY}/${CUSTOMER_API_PREFIX}/delete-calculation`,
    DELETE_PROJECT: `${PROXY}/${CUSTOMER_API_PREFIX}/delete-project`,
    DELETE_MANY_CALCULATIONS: `${PROXY}/${CUSTOMER_API_PREFIX}/delete-many-calculations`,
  },
};

export const BASE_REQUESTS = {
  POST: {
    SIGN_UP: `${PROXY}/${BASE_API_PREFIX}/register`,
    LOGIN: `${PROXY}/${BASE_API_PREFIX}/login`,
    FORGOT_PASSWORD: `${PROXY}/${BASE_API_PREFIX}/forgot-password`,
    RESET_PASSWORD: `${PROXY}/${BASE_API_PREFIX}/reset-password`,
  },
};

export const ADMIN_REQUESTS = {
  GET: {
    GET_CUSTOMERS: `${PROXY}/${ADMIN_API_PREFIX}/get-customers`,
  },
  POST: {
    GET_ALL_PROJECTS: `${PROXY}/${ADMIN_API_PREFIX}/all-projects`,
    GET_USER_PROJECTS: `${PROXY}/${ADMIN_API_PREFIX}/user-projects`,
  },
  PUT: {
    ACTIVATE_CUSTOMER: `${PROXY}/${ADMIN_API_PREFIX}/activate-customer`,
    MAKE_ADMIN_ACCOUNT: `${PROXY}/${ADMIN_API_PREFIX}/make-admin-account`,
  },
};
