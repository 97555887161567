export const devices = [
  {
    id: 1,
    producer: "Hisense",
    name: "Hisense",
    series: "Systemy VRF Hi-Smart seria H",
    code: "AVW-28HJFH",
    weight: 72,
    height: 800,
    width: 950,
    depth: 370,
  },
  {
    id: 2,
    producer: "Hisense",
    name: "Hisense",
    series: "Systemy VRF Hi-Smart seria H",
    code: "AVW-34HJFH",
    weight: 81,
    height: 800,
    width: 950,
    depth: 370,
  },
  {
    id: 3,
    producer: "Hisense",
    name: "Hisense",
    series: "Systemy VRF Hi-Smart seria H",
    code: "AVW-43HJFH",
    weight: 86,
    height: 800,
    width: 950,
    depth: 370,
  },
  {
    id: 4,
    producer: "Hisense",
    name: "Hisense",
    series: "Systemy VRF Hi-Smart seria H",
    code: "AVW-43HJFH",
    weight: 96,
    height: 800,
    width: 950,
    depth: 370,
  },
  {
    id: 5,
    producer: "Hisense",
    name: "Hisense",
    series: "Systemy VRF Hi-Smart seria H",
    code: "AVW-38HJFH",
    weight: 111,
    height: 1380,
    width: 950,
    depth: 370,
  },
  {
    id: 6,
    producer: "Hisense",
    name: "Hisense",
    series: "Systemy VRF Hi-Smart seria H",
    code: "AVW-48HJFH",
    weight: 111,
    height: 1380,
    width: 950,
    depth: 370,
  },
  {
    id: 6,
    producer: "Hisense",
    name: "Hisense",
    series: "Systemy VRF Hi-Smart seria H",
    code: "AVW-54HJFH",
    weight: 111,
    height: 1380,
    width: 950,
    depth: 370,
  },
  {
    id: 7,
    producer: "Hisense",
    name: "Hisense",
    series: "Systemy VRF Hi-Smart seria H",
    code: "AVW-48HKFH",
    weight: 118,
    height: 1380,
    width: 950,
    depth: 370,
  },
  {
    id: 8,
    producer: "Hisense",
    name: "Hisense",
    series: "Systemy VRF Hi-Smart seria H",
    code: "AVW-54HKFH",
    weight: 118,
    height: 1380,
    width: 950,
    depth: 370,
  },
  {
    id: 9,
    producer: "Hisense",
    name: "Hisense",
    series: "Systemy VRF Hi-Smart seria H",
    code: "AVW-76HKFH",
    weight: 179,
    height: 1650,
    width: 1100,
    depth: 390,
  },
  {
    id: 10,
    producer: "Hisense",
    name: "Hisense",
    series: "Systemy VRF Hi-Smart seria H",
    code: "AVW-96HKFH",
    weight: 194,
    height: 1650,
    width: 1100,
    depth: 390,
  },
  {
    id: 10,
    producer: "Hisense",
    name: "Hisense",
    series: "Systemy VRF Hi-Smart seria H",
    code: "AVW-114HKFH",
    weight: 194,
    height: 1650,
    width: 1100,
    depth: 390,
  },
  {
    id: 11,
    producer: "Hisense",
    name: "Hisense",
    series: "Systemy mini VRF Hi-Smart seria E",
    code: "AVW-28UCSB",
    weight: 75,
    height: 800,
    width: 950,
    depth: 370,
  },
  {
    id: 12,
    producer: "Hisense",
    name: "Hisense",
    series: "Systemy mini VRF Hi-Smart seria E",
    code: "AVW-34UCSB",
    weight: 83,
    height: 800,
    width: 950,
    depth: 370,
  },
  {
    id: 13,
    producer: "Hisense",
    name: "Hisense",
    series: "Systemy mini VRF Hi-Smart seria E",
    code: "AVW-43UCSB",
    weight: 88,
    height: 800,
    width: 950,
    depth: 370,
  },
  {
    id: 14,
    producer: "Hisense",
    name: "Hisense",
    series: "MINI VRF SERIA L",
    code: "AVW-38UCSC",
    weight: 106,
    height: 1380,
    width: 950,
    depth: 370,
  },
  {
    id: 15,
    producer: "Hisense",
    name: "Hisense",
    series: "MINI VRF SERIA L",
    code: "AVW-48UCSC",
    weight: 108,
    height: 1380,
    width: 950,
    depth: 370,
  },
  {
    id: 16,
    producer: "Hisense",
    name: "Hisense",
    series: "MINI VRF SERIA L",
    code: "AVW-54UCSC",
    weight: 110,
    height: 1380,
    width: 950,
    depth: 370,
  },
];

export const frameFoot = {
  lengths: [
    {
      name: "FF4",
      length: 1000,
      weight: 500,
    },
    {
      name: "FF6",
      length: 2000,
      weight: 1040,
    },
    {
      name: "FF8",
      length: 3000,
      weight: 1630,
    },
  ],
  widths: {
    standard: 1200,
  },

  heights: {
    standard: 400,
    "DTU-R": 800,
  },
};

export const frameFootMini = {
  lengths: [
    {
      name: "small",
      minLength: 120,
      maxLength: 520,
      minWidth: 450,
      maxWidth: 755,
      weight: 500,
    },
    {
      name: "big",
      minLength: 120,
      maxLength: 820,
      minWidth: 690,
      maxWidth: 1250,
      weight: 500,
    },
  ],
};

export const RB = {
  lengths: [
    {
      name: "RB25",
      length: 250,
      weight: 120,
    },
    {
      name: "RB40",
      length: 400,
      weight: 180,
    },
    {
      name: "RB60",
      length: 600,
      weight: 350,
    },
    {
      name: "RB100",
      length: 1000,
      weight: 350,
    },
  ],
  widths: {
    standard: 1200,
  },
};

export const RISER = {
  lengths: [
    {
      name: "RB25 + RISER25",
      length: 250,
      weight: 120,
    },
    {
      name: "RB40 + RISER40",
      length: 400,
      weight: 180,
    },
    {
      name: "RB60 + RISER60",
      length: 600,
      weight: 350,
    },
    {
      name: "RB100 + RISER100",
      length: 1000,
      weight: 350,
    },
  ],
  widths: {
    standard: 1200,
  },
};

export const projectList = [
  {
    id: 1,
    name: "Perła",
    date: "27.03.2021",
    calculationList: [
      {
        calculationId: 1,
        calculationName: "perla 1",
        calculationDate: "01.01.2021",
        depth: 200,
        width: 1200,
        height: 1200,
        weight: 500,
        currentResult: "4",
        result: {
          name: "FF4 1200/2000",
          price: 2000,
        },
      },
      {
        calculationId: 2,
        calculationName: "perla 2",
        calculationDate: "23.01.2021",
        depth: 500,
        width: 1200,
        height: 500,
        weight: 500,
        currentResult: "1",
        result: {
          name: "RB 1200/500 - 500",
          price: 2300,
        },
      },
      {
        calculationId: 3,
        calculationName: "perla 3",
        calculationDate: "23.01.2021",
        depth: 160,
        width: 500,
        height: 1200,
        weight: 649,
        currentResult: "2",
        result: {
          name: "RB 120/500 - 649",
          price: 2300,
        },
      },
      {
        calculationId: 4,
        calculationName: "perla 4",
        calculationDate: "23.01.2021",
        depth: 500,
        width: 500,
        height: 500,
        weight: 500,
        currentResult: "2",
        result: {
          name: "RB 1234",
          price: 2300,
        },
      },
      {
        calculationId: 5,
        calculationName: "perla 5",
        calculationDate: "23.01.2021",
        depth: 500,
        width: 500,
        height: 500,
        weight: 500,
        currentResult: "1",
        result: {
          name: "RB 1234",
          price: 2300,
        },
      },
    ],
  },
  {
    id: 2,
    name: "kalkulacja zamek",
    date: "23.01.2021",
    calculationList: [
      {
        calculationId: 1,
        calculationName: "zamek 1",
        calculationDate: "01.01.2021",
        depth: 200,
        height: 500,
        width: 1200,
        weight: 500,
        currentResult: "4",
        result: {
          name: "FF4 1200/2000",
          price: 100,
        },
      },
      {
        calculationId: 2,
        calculationName: "zamek 2",
        calculationDate: "23.01.2021",
        depth: 1200,
        height: 1345,
        width: 1200,
        weight: 649,
        currentResult: "2",
        result: {
          name: "RB 1234",
          price: 117,
        },
      },
    ],
  },
];

export const columns = [
  {
    header: "Nazwa",
    accessor: "name",
  },
  {
    header: "Data",
    accessor: "date",
  },
];

export const ffDevices = [
  {
    name: "FF4 L1000/W1200",
    width: 1000,
    depth: 1200,
    weight: 500,
    feets: 4,
    rows: 2,
    rowCount: 2,
    price: 1540,
  },
  {
    name: "FF4 L1500/W1200",
    width: 1500,
    depth: 1200,
    weight: 500,
    feets: 4,
    rows: 2,
    rowCount: 2,
    price: 1656.60,
  },
  {
    name: "FF4 L1500/W1500",
    width: 1500,
    depth: 1500,
    weight: 500,
    feets: 4,
    rows: 2,
    rowCount: 2,
    price: 1749,
  },
  {
    name: "FF6 L1000/W1200",
    width: 1000,
    depth: 1200,
    weight: 1040,
    feets: 6,
    rows: 2,
    rowCount: 3,
    price: 2527.80,
  },
  {
    name: "FF6 L1500/W1200",
    width: 1500,
    depth: 1200,
    weight: 1040,
    feets: 6,
    rows: 2,
    rowCount: 3,
    price: 2653.20,
  },
  {
    name: "FF6 L1500/W1500",
    width: 1500,
    depth: 1500,
    weight: 1040,
    feets: 6,
    rows: 2,
    rowCount: 3,
    price: 2900.70,
  },
  {
    name: "FF6 L2000/W1200",
    width: 2000,
    depth: 1200,
    weight: 1040,
    feets: 6,
    rows: 2,
    rowCount: 3,
    price: 2130,
  },
  {
    name: "FF6 L2000/W1500",
    width: 2000,
    depth: 1500,
    weight: 1040,
    feets: 6,
    rows: 2,
    rowCount: 3,
    price: 2663.10,
  },
  {
    name: "FF8 L1500/W1200",
    width: 1500,
    depth: 1200,
    weight: 1680,
    feets: 8,
    rows: 2,
    rowCount: 4,
    price: 3521.10,
  },
  {
    name: "FF8 L1500/W1500",
    width: 1500,
    depth: 1500,
    weight: 1680,
    feets: 8,
    rows: 2,
    rowCount: 4,
    price: 3894,
  },
  {
    name: "FF8 L2000/W1200",
    width: 2000,
    depth: 1200,
    weight: 1680,
    feets: 8,
    rows: 2,
    rowCount: 4,
    price: 3521.10,
  },
  {
    name: "FF8 L2000/W1500",
    width: 200,
    depth: 1500,
    weight: 1680,
    feets: 8,
    rows: 2,
    rowCount: 4,
    price: 3894,
  },
  {
    name: "FF8 L2500/W1200",
    width: 2500,
    depth: 1200,
    weight: 1680,
    feets: 8,
    rows: 2,
    rowCount: 4,
    price: 3676.20,
  },
  {
    name: "FF8 L2500/W1500",
    width: 2500,
    depth: 1500,
    weight: 1680,
    feets: 8,
    rows: 2,
    rowCount: 4,
    price: 4049.10,
  },
  {
    name: "FF8 L3000/W1200",
    width: 3000,
    depth: 1200,
    weight: 1680,
    feets: 8,
    rows: 2,
    rowCount: 4,
    price: 2807,
  },
  {
    name: "FF8 L3000/W1500",
    width: 3000,
    depth: 1500,
    weight: 1680,
    feets: 8,
    rows: 2,
    rowCount: 4,
    price: 4049.10,
  },
  {
    name: "FF9 L2000/W2000",
    width: 2000,
    depth: 2000,
    weight: 1800,
    feets: 9,
    rows: 3,
    rowCount: 3,
    price: 5115,
  },
  {
    name: "FF9 L2500/W2000",
    width: 2500,
    depth: 2000,
    weight: 1800,
    feets: 9,
    rows: 3,
    rowCount: 3,
    price: 4022.70,
  },
  {
    name: "FF10 2000/W1200",
    width: 2000,
    depth: 1200,
    weight: 1900,
    feets: 10,
    rows: 2,
    rowCount: 5,
    price: 4207.50,
  },
  {
    name: "FF10 L2000/W1500",
    width: 2000,
    depth: 1500,
    weight: 1900,
    feets: 10,
    rows: 2,
    rowCount: 5,
    price: 4676.10,
  },
  {
    name: "FF10 L2500/W1200",
    width: 2500,
    depth: 1200,
    weight: 1900,
    feets: 10,
    rows: 2,
    rowCount: 5,
    price: 4342.80,
  },
  {
    name: "FF10 L2500/W1500",
    width: 2500,
    depth: 1500,
    weight: 1900,
    feets: 10,
    rows: 2,
    rowCount: 5,
    price: 4827.90,
  },
  {
    name: "FF10 L3000/W1200",
    width: 3000,
    depth: 1200,
    weight: 1900,
    feets: 10,
    rows: 2,
    rowCount: 5,
    price: 4342.80,
  },
  {
    name: "FF10 L3000/W1500",
    width: 3000,
    depth: 1500,
    weight: 1900,
    feets: 10,
    rows: 2,
    rowCount: 5,
    price: 4827.90,
  },
  {
    name: "FF10 L4000/W1200",
    width: 4000,
    depth: 1200,
    weight: 1900,
    feets: 10,
    rows: 2,
    rowCount: 5,
    price: 4587,
  },
  {
    name: "FF10 L4000/W1500",
    width: 4000,
    depth: 1500,
    weight: 1900,
    feets: 10,
    rows: 2,
    rowCount: 5,
    price: 5072.10,
  },
  {
    name: "FF12 L2000/W1200",
    width: 2000,
    depth: 1200,
    weight: 2000,
    feets: 12,
    rows: 2,
    rowCount: 6,
    price: 5025.90,
  },
  {
    name: "FF12 L2000/W1500",
    width: 2000,
    depth: 1500,
    weight: 2000,
    feets: 12,
    rows: 2,
    rowCount: 6,
    rows: 3,
    rowCount: 4,
    price: 5633.10,
  },
  {
    name: "FF12 L2000/W2000",
    width: 2000,
    depth: 2000,
    weight: 2000,
    feets: 12,
    rows: 3,
    rowCount: 4,
    price: 5055.60,
  },
  {
    name: "FF12 L2500/W1200",
    width: 2500,
    depth: 1200,
    weight: 2000,
    feets: 12,
    rows: 2,
    rowCount: 6,
    price: 5177.70,
  },
  {
    name: "FF12 L2500/W1500",
    width: 2500,
    depth: 1500,
    weight: 2000,
    feets: 12,
    rows: 2,
    rowCount: 6,
    price: 5784.90,
  },
  {
    name: "FF12 L2500/W2000",
    width: 2500,
    depth: 2000,
    weight: 2000,
    feets: 12,
    rows: 3,
    rowCount: 4,
    price: 5270.10,
  },
  {
    name: "FF12 L3000/W1200",
    width: 3000,
    depth: 1200,
    weight: 2000,
    feets: 12,
    rows: 2,
    rowCount: 6,
    price: 5177.70,
  },
  {
    name: "FF12 L3000/W1500",
    width: 3000,
    depth: 1500,
    weight: 2000,
    feets: 12,
    rows: 2,
    rowCount: 6,
    price: 5784.90,
  },
  {
    name: "FF12 L3000/W2000",
    width: 3000,
    depth: 2000,
    weight: 2000,
    feets: 12,
    rows: 3,
    rowCount: 4,
    price: 5270.10,
  },
  {
    name: "FF14 L2500/W1200",
    width: 2500,
    depth: 1200,
    weight: 2200,
    feets: 14,
    rows: 2,
    rowCount: 7,
    price: 6012.60,
  },
  {
    name: "FF14 L2500/W1500",
    width: 2500,
    depth: 1500,
    weight: 2200,
    feets: 14,
    rows: 2,
    rowCount: 7,
    price: 6725.40,
  },
  {
    name: "FF14 L3000/W1200",
    width: 3000,
    depth: 1200,
    weight: 2200,
    feets: 14,
    rows: 2,
    rowCount: 7,
    price: 6012.60,
  },
  {
    name: "FF14 L3000/W1500",
    width: 3000,
    depth: 1500,
    weight: 2200,
    feets: 14,
    rows: 2,
    rowCount: 7,
    price: 6727.50,
  },
  {
    name: "FF14 L4000/W1200",
    width: 4000,
    depth: 1200,
    weight: 2200,
    feets: 14,
    rows: 2,
    rowCount: 7,
    price: 6256.80,
  },
  {
    name: "FF14 L4000/W1500",
    width: 4000,
    depth: 1500,
    weight: 2200,
    feets: 14,
    rows: 2,
    rowCount: 7,
    price: 6966.30,
  },
  {
    name: "FF15 L2000/W2000",
    width: 2000,
    depth: 2000,
    weight: 2300,
    feets: 15,
    rows: 3,
    rowCount: 5,
    price: 6303,
  },
  {
    name: "FF15 L2500/W2000",
    width: 2500,
    depth: 2000,
    weight: 2300,
    feets: 15,
    rows: 3,
    rowCount: 5,
    price: 6514.20,
  },
  {
    name: "FF15 L3000/W2000",
    width: 3000,
    depth: 2000,
    weight: 2300,
    feets: 15,
    rows: 3,
    rowCount: 5,
    price: 6514.20,
  },
  {
    name: "FF16 L3000/W1200",
    width: 3000,
    depth: 1200,
    weight: 2400,
    feets: 16,
    rows: 2,
    rowCount: 8,
    price: 6847.50,
  },
  {
    name: "FF16 L3000/W1500",
    width: 3000,
    depth: 1500,
    weight: 2400,
    feets: 16,
    rows: 2,
    rowCount: 8,
    price: 7682.40,
  },
  {
    name: "FF16 L4000/W1200",
    width: 4000,
    depth: 1200,
    weight: 2400,
    feets: 16,
    rows: 2,
    rowCount: 8,
    price: 7075.20,
  },
  {
    name: "FF16 L5000/W1500",
    width: 5000,
    depth: 1500,
    weight: 2400,
    feets: 16,
    rows: 2,
    rowCount: 8,
    price: 8061.90,
  },
  {
    name: "FF16 L5000/W1200",
    width: 5000,
    depth: 1200,
    weight: 2400,
    feets: 16,
    rows: 2,
    rowCount: 8,
    price: 7227,
  },
  {
    name: "FF18 L2000/W2000",
    width: 2000,
    depth: 2000,
    weight: 2600,
    feets: 18,
    rows: 3,
    rowCount: 6,
    price: 7543.80,
  },
  {
    name: "FF18 L2500/W2000",
    width: 2500,
    depth: 2000,
    weight: 2600,
    feets: 18,
    rows: 3,
    rowCount: 6,
    price: 7771.50,
  },
  {
    name: "FF18 L3000/W2000",
    width: 3000,
    depth: 2000,
    weight: 2600,
    feets: 18,
    rows: 3,
    rowCount: 6,
    price: 7771.50,
  },
  {
    name: "FF18 L4000/W2000",
    width: 4000,
    depth: 2000,
    weight: 2600,
    feets: 18,
    rows: 3,
    rowCount: 6,
    price: 8121.30,
  },
  {
    name: "FF20 L4000/W1200",
    width: 4000,
    depth: 1200,
    weight: 2800,
    feets: 20,
    rows: 2,
    rowCount: 10,
    price: 8745,
  },
  {
    name: "FF20 L4000/W1500",
    width: 4000,
    depth: 1500,
    weight: 2800,
    feets: 20,
    rows: 2,
    rowCount: 10,
    price: 9820.80,
  },
  {
    name: "FF20 L5000/W1200",
    width: 5000,
    depth: 1200,
    weight: 2800,
    feets: 20,
    rows: 2,
    rowCount: 10,
    price: 9893.50,
  },
  {
    name: "FF20 L5000/W1500",
    width: 5000,
    depth: 1500,
    weight: 2800,
    feets: 20,
    rows: 2,
    rowCount: 10,
    price: 9972.60,
  },
  {
    name: "FF20 L6000/W1200",
    width: 6000,
    depth: 1200,
    weight: 2800,
    feets: 20,
    rows: 2,
    rowCount: 10,
    price: 9048.60,
  },
  {
    name: "FF20 L6000/W1500",
    width: 6000,
    depth: 1500,
    weight: 2800,
    feets: 20,
    rows: 2,
    rowCount: 10,
    price: 10111.20,
  },
  {
    name: "FF21 L2500/W2000",
    width: 2500,
    depth: 2000,
    weight: 2900,
    feets: 21,
    rows: 3,
    rowCount: 7,
    price: 9018.90,
  },
  {
    name: "FF21 L3000/W2000",
    width: 3000,
    depth: 2000,
    weight: 2900,
    feets: 21,
    rows: 3,
    rowCount: 7,
    price: 9018.90,
  },
  {
    name: "FF21 L4000/W2000",
    width: 4000,
    depth: 2000,
    weight: 2900,
    feets: 21,
    rows: 3,
    rowCount: 7,
    price: 9368.70,
  },
  {
    name: "FF21 L5000/W2000",
    width: 5000,
    depth: 2000,
    weight: 2900,
    feets: 21,
    rows: 3,
    rowCount: 7,
    price: 9593.10,
  },
  {
    name: "FF22 L4000/W1200",
    width: 4000,
    depth: 1200,
    weight: 3000,
    feets: 22,
    rows: 2,
    rowCount: 11,
    price: 9576.60,
  },
  {
    name: "FF22 L4000/W1500",
    width: 4000,
    depth: 1500,
    weight: 3000,
    feets: 22,
    rows: 2,
    rowCount: 11,
    price: 10761.30,
  },
  {
    name: "FF22 L5000/W1200",
    width: 5000,
    depth: 1200,
    weight: 3000,
    feets: 22,
    rows: 2,
    rowCount: 11,
    price: 9715.20,
  },
  {
    name: "FF22 L5000/W1500",
    width: 5000,
    depth: 1500,
    weight: 3000,
    feets: 22,
    rows: 2,
    rowCount: 11,
    price: 10913.10,
  },
  {
    name: "FF22 L6000/W1200",
    width: 6000,
    depth: 1200,
    weight: 3000,
    feets: 22,
    rows: 2,
    rowCount: 11,
    price: 9867,
  },
  {
    name: "FF22 L6000/W1500",
    width: 6000,
    depth: 1500,
    weight: 3000,
    feets: 22,
    rows: 2,
    rowCount: 11,
    price: 11064.90,
  },
  {
    name: "FF24 L4000/W1200",
    width: 4000,
    depth: 1200,
    weight: 3000,
    feets: 24,
    rows: 2,
    rowCount: 12,
    price: 10389.30,
  },
  {
    name: "FF24 L4000/W1500",
    width: 4000,
    depth: 1500,
    weight: 3000,
    feets: 24,
    rows: 2,
    rowCount: 12,
    price: 11718.30,
  },
  {
    name: "FF24 L4000/W2000",
    width: 4000,
    depth: 2000,
    weight: 3000,
    feets: 24,
    rows: 3,
    rowCount: 8,
    price: 10612.80,
  },
  {
    name: "FF24 L5000/W1200",
    width: 5000,
    depth: 1200,
    weight: 3000,
    feets: 24,
    rows: 2,
    rowCount: 12,
    price: 4610.10,
  },
  {
    name: "FF24 L5000/W1500",
    width: 5000,
    depth: 1500,
    weight: 3000,
    feets: 24,
    rows: 2,
    rowCount: 12,
    price: 11870.10,
  },
  {
    name: "FF24 L5000/W2000",
    width: 5000,
    depth: 2000,
    weight: 3000,
    feets: 24,
    rows: 3,
    rowCount: 8,
    price: 10837.20,
  },
  {
    name: "FF24 L6000/W1200",
    width: 6000,
    depth: 1200,
    weight: 3000,
    feets: 24,
    rows: 2,
    rowCount: 12,
    price: 10721.70,
  },
  {
    name: "FF24 L6000/W1500",
    width: 6000,
    depth: 1500,
    weight: 3000,
    feets: 24,
    rows: 2,
    rowCount: 12,
    price: 12021.90,
  },
  {
    name: "FF24 L6000/W2000",
    width: 6000,
    depth: 2000,
    weight: 3000,
    feets: 24,
    rows: 3,
    rowCount: 8,
    price: 11068.20,
  },
];

export const calculationList = [
  {
    calculationId: 1,
    calculationName: "kalkulacja",
    calculationDate: "01.01.2021",
    depth: 200,
    width: 1200,
    weight: 500,
    result: {
      price: 2000,
    },
  },
  {
    calculationId: 2,
    calculationName: "kalkulacja zamek",
    calculationDate: "23.01.2021",
    depth: 1200,
    width: 1200,
    weight: 649,
    result: {
      price: 2300,
    },
  },
];

export const calculationGroupList = [
  {
    id: 1,
    calculationGroupName: "Browar Perła",
    calculationGroupate: "23.01.2021",
    calculations: [1, 2],
  },
];

export const ffMiniDevices = [
  {
    name: "FF-755",
    minWidth: 120,
    maxWidth: 520,
    minDepth: 450,
    maxDepth: 755,
    weight: 360,
    width: 700,
    price: 528,
  },
  {
    name: "FF-1000",
    minWidth: 120,
    maxWidth: 820,
    minDepth: 690,
    maxDepth: 1250,
    weight: 360,
    width: 1000,
    price: 714,
  },
];

export const ffMiniDevices300 = [
  {
    name: "FF-755-300",
    minWidth: 120,
    maxWidth: 520,
    minDepth: 450,
    maxDepth: 755,
    weight: 360,
    width: 700,
    price: 636,
  },
  {
    name: "FF-1000-300",
    minWidth: 120,
    maxWidth: 820,
    minDepth: 690,
    maxDepth: 1250,
    weight: 360,
    width: 1000,
    price: 744,
  },
];

export const ffMiniDevices500 = [
  {
    name: "FF-755",
    minWidth: 120,
    maxWidth: 520,
    minDepth: 450,
    maxDepth: 755,
    weight: 360,
    width: 700,
    price: 963,
  },
  {
    name: "FF-1000",
    minWidth: 120,
    maxWidth: 820,
    minDepth: 690,
    maxDepth: 1250,
    weight: 360,
    width: 1000,
    price: 1068,
  },
];

export const rbDevices = [
  {
    name: "RB25",
    depth: 250,
    weight: 120,
    width: 130,
    count: 1,
    price: 35,
  },
  {
    name: "RB40",
    depth: 400,
    weight: 180,
    width: 130,
    count: 1,
    price: 60,
  },
  {
    name: "RB60",
    depth: 600,
    weight: 350,
    width: 130,
    count: 1,
    price: 75,
  },
  {
    name: "RB100",
    depth: 1000,
    weight: 350,
    width: 130,
    count: 1,
    price: 138,
  },
  {
    name: "RB25",
    depth: 250,
    weight: 120,
    width: 180,
    count: 1,
    price: 37,
  },
  {
    name: "RB40",
    depth: 400,
    weight: 180,
    width: 180,
    count: 1,
    price: 63,
  },
  {
    name: "RB60",
    depth: 600,
    weight: 350,
    width: 180,
    count: 1,
    price: 79,
  },
  {
    name: "RB100",
    depth: 1000,
    weight: 350,
    width: 180,
    count: 1,
    price: 145,
  },
];
export const rbBDevices = [
  {
    name: "RB25",
    depth: 250,
    weight: 120,
    width: 130,
    count: 1,
    price: 42.88,
  },
  {
    name: "RB40",
    depth: 400,
    weight: 180,
    width: 130,
    count: 1,
    price: 65.68,
  },
  {
    name: "RB60",
    depth: 600,
    weight: 350,
    width: 130,
    count: 1,
    price: 93.68,
  },
  {
    name: "RB100",
    depth: 1000,
    weight: 350,
    width: 130,
    count: 1,
    price: 148.56,
  },
  {
    name: "RB25",
    depth: 250,
    weight: 120,
    width: 180,
    count: 1,
    price: 56.60,
  },
  {
    name: "RB40",
    depth: 400,
    weight: 180,
    width: 180,
    count: 1,
    price: 82.76,
  },
  {
    name: "RB60",
    depth: 600,
    weight: 350,
    width: 180,
    count: 1,
    price: 114.44,
  },
  {
    name: "RB100",
    depth: 1000,
    weight: 350,
    width: 180,
    count: 1,
    price: 172.68,
  },
];
export const rbSANDevices = [
  {
    name: "RB25",
    depth: 250,
    weight: 120,
    width: 130,
    count: 1,
    price: 42,
  },
  {
    name: "RB40",
    depth: 400,
    weight: 180,
    width: 130,
    count: 1,
    price: 72,
  },
  {
    name: "RB60",
    depth: 600,
    weight: 350,
    width: 130,
    count: 1,
    price: 90,
  },
  {
    name: "RB100",
    depth: 1000,
    weight: 350,
    width: 130,
    count: 1,
    price: 165.60,
  },
];

export const rbSANBDevices = [
  {
    name: "RB25",
    depth: 250,
    weight: 120,
    width: 130,
    count: 1,
    price: 46,
  },
  {
    name: "RB40",
    depth: 400,
    weight: 180,
    width: 130,
    count: 1,
    price: 76,
  },
  {
    name: "RB60",
    depth: 600,
    weight: 350,
    width: 130,
    count: 1,
    price: 94,
  },
  {
    name: "RB100",
    depth: 1000,
    weight: 350,
    width: 130,
    count: 1,
    price: 169.60,
  },
];

export const rbSTRDevices = [
  {
    name: "RB25",
    depth: 250,
    weight: 120,
    width: 130,
    count: 1,
    price: 57.56,
  },
  {
    name: "RB40",
    depth: 400,
    weight: 180,
    width: 130,
    count: 1,
    price: 85.32,
  },
  {
    name: "RB60",
    depth: 600,
    weight: 350,
    width: 130,
    count: 1,
    price: 120.20,
  },
  {
    name: "RB100",
    depth: 1000,
    weight: 350,
    width: 130,
    count: 1,
    price: 185.60,
  },
  {
    name: "RB25",
    depth: 250,
    weight: 120,
    width: 180,
    count: 1,
    price: 62.28,
  },
  {
    name: "RB40",
    depth: 400,
    weight: 180,
    width: 180,
    count: 1,
  },
  {
    name: "RB60",
    depth: 600,
    weight: 350,
    width: 180,
    count: 1,
    price: 131.04,
  },
  {
    name: "RB100",
    depth: 1000,
    weight: 350,
    width: 180,
    count: 1,
    price: 195,
  },
];
export const basePadDevices = [
];

export const riserDevices = [
  // {
  //   name: "RB25",
  //   depth: 250,
  //   weight: 120,
  //   width: 130,
  //   count: 1,
  //   price: 123,
  // },
  // {
  //   name: "RB40",
  //   depth: 400,
  //   weight: 180,
  //   width: 130,
  //   count: 1,
  //   price: 145,
  // },
  {
    name: "RB60 + RISER60",
    depth: 600,
    weight: 350,
    width: 130,
    count: 1,
    price: 0,
  },
  {
    name: "RB100 + RISER100",
    depth: 1000,
    weight: 350,
    width: 130,
    count: 1,
    price: 0,
  },
  // {
  //   name: "RB25",
  //   depth: 250,
  //   weight: 120,
  //   width: 180,
  //   count: 1,
  //   price: 300,
  // },
  // {
  //   name: "RB40",
  //   depth: 400,
  //   weight: 180,
  //   width: 180,
  //   count: 1,
  //   price: 350,
  // },
  {
    name: "RB60 + RISER60",
    depth: 600,
    weight: 350,
    width: 180,
    count: 1,
    price: 186,
  },
  {
    name: "RB100 + RISER100",
    depth: 1000,
    weight: 350,
    width: 180,
    count: 1,
    price: 287,
  },
];

export const heights = [
  {
    id: 0,
    value: "",
  },
  {
    id: 1,
    value: "95 mm",
  },
  {
    id: 2,
    value: "200 mm",
  },
  {
    id: 3,
    value: "330-370 mm (regulowany kąt nachylenia)",
  },
  {
    id: 4,
    value: "295-415 mm",
  },
  {
    id: 5,
    value: "745-865 mm",
  },
];

export const resultOptions = [
  {
    id: 0,
    value: "",
  },
  {
    id: 1,
    value: "Rubber Block 130",
  },
  {
    id: 2,
    value: "Rubber Block 180",
  },
  {
    id: 7,
    value: "Rubber Block 130B",
  },
  {
    id: 8,
    value: "Rubber Block 180B",
  },
  {
    id: 9,
    value: "Rubber Block 130STR",
  },
  {
    id: 10,
    value: "Rubber Block 180STR",
  },
  {
    id: 15,
    value: "Rubber Block 130SAN",
  },
  {
    id: 16,
    value: "Rubber Block 130SAN-B",
  },
  {
    id: 11, 
    value: "BASE  PAD 200/145",
  },
  {
    id: 12,
    value: "BASE PAD 300/145",
  },
  {
    id: 3,
    value: "Riser",
  },
  {
    id: 4,
    value: "Frame Foot",
  },
  {
    id: 5,
    value: "Frame Foot DTU-R",
  },
  {
    id: 6,
    value: "Frame Foot mini",
  },
  {
    id: 13,
    value: "Frame Foot mini 300",
  },
  {
    id: 14,
    value: "Frame Foot mini 500",
  },
];
