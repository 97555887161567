export const AccountEdit = ({
    companyName,
    city,
    country,
    postCode,
    street,
    lastname,
    firstname,
    phoneNumber,
    email,
    discount,
    NIP,
    setValue,
    setEditMode,
    updateAccount
}) => (
    <div className="accountForm loginContainer-content accountContainer-content">
          Konto użytkownika
          <div className="accountSection">
            <div className="sectionTitle">Dane firmy</div>
            <div className="accountParam">
              <label className="paramLabel">Nazwa firmy</label>
              <input
                name="companyName"
                type="text"
                value={companyName}
                onChange={setValue}
              />
            </div>
            <div className="accountParam">
              <label className="paramLabel">NIP</label>
              <input name="NIP" type="text" value={NIP} onChange={setValue} />
            </div>
            <div className="accountParam">
              <label className="paramLabel">kraj</label>
              <input
                type="text"
                name="country"
                value={country}
                onChange={setValue}
              />
            </div>
            <div className="accountParam">
              <label className="paramLabel">Miasto</label>
              <input type="text" name="city" value={city} onChange={setValue} />
            </div>
            <div className="accountParam">
              <label className="paramLabel">Ulica</label>
              <input
                type="text"
                name="street"
                value={street}
                onChange={setValue}
              />
            </div>
            <div className="accountParam">
              <label className="paramLabel">kod pocztowy</label>
              <input
                type="text"
                name="postCode"
                value={postCode}
                onChange={setValue}
              />
            </div>
          </div>
          <div className="accountSection">
            <div className="sectionTitle">Dane kontaktowe</div>
            <div className="accountParam">
              <label className="paramLabel">Imię</label>
              <input
                name="firstname"
                type="text"
                value={firstname}
                onChange={setValue}
              />
            </div>
            <div className="accountParam">
              <label className="paramLabel">Nazwisko</label>
              <input
                type="text"
                name="lastname"
                value={lastname}
                onChange={setValue}
              />
            </div>
            <div className="accountParam">
              <label className="paramLabel">Nr tel</label>
              <input
                name="phoneNumber"
                type="text"
                value={phoneNumber}
                onChange={setValue}
              />
            </div>
            <div className="accountParam">
              <label className="paramLabel">Email</label>
              <input
                name="email"
                type="text"
                value={email}
                onChange={setValue}
              />
            </div>
          </div>
          <div className="accountSection">
            <div className="sectionTitle">Dane biznesowe</div>
            <div className="accountParam">
              <label className="paramLabel">Rabat</label>
              <input
                type="text"
                value={discount}
                disabled
                // onChange={e=>this.props.setDiscount(e.target.value)}
              />
            </div>
          </div>
          <div className="accountSection">
            <button onClick={(e)=>{setEditMode(false); updateAccount(e)}}>Zapisz</button>
          </div>
        </div>
)