import * as CONST from '../constans/const';

export function setEmail(email) {
    return {
        type: CONST.SET_EMAIL,
        payload: email,
    }
}
export function setCompanyName(companyName) {
    return {
        type: CONST.SET_COMPANY_NAME,
        payload: companyName,
    }
}
export function setAddress(address) {
    return {
        type: CONST.SET_ADDRESS,
        payload: address,
    }
}
export function setAddress2(address2) {
    return {
        type: CONST.SET_ADDRESS_2,
        payload: address2,
    }
}
export function setPhone(phone) {
    return {
        type: CONST.SET_PHONE,
        payload: phone,
    }
}
export function setPassword(password) {
    return {
        type: CONST.SET_PASSWORD,
        payload: password,
    }
}
export function setConfirmPassword(confirmPassword) {
    return {
        type: CONST.SET_CONFIRM_PASSWORD,
        payload: confirmPassword,
    }
}
export function isLoginView(isLoginView) {
    return {
        type: CONST.IS_LOGIN_VIEW,
        payload: isLoginView,
    }
}
export function logIn(user) {
    return {
        type: CONST.LOG_IN,
        payload: user
    }
}
export function logOut() {
    return {
        type: CONST.LOG_OUT,
    }
}

export function setAccount(user) {
    return {
        type: CONST.SET_USER,
        payload: user
    }
}