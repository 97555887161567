import {frameFoot, frameFootMini, RB, RISER} from './const.js'

const noDeviceMessage = 'Wprowadź inne parametry lub skontaktuj się ze sprzedawcą';

export let calcFrameFoot = (width, depth, weight, ffHeight, ffDevices) => {
    width = parseInt(width)+80;
    depth = parseInt(depth)+80;
    let result = {name: noDeviceMessage};
    const possibleDevices = ffDevices.filter(device => device.weight >= weight);
    // należy w wyszukiwaniach uwzględnić ffHeight
    const found = possibleDevices.find(device => device.width >= width && device.depth >= depth)
    // console.log('calcFrameFoot', found)
    return found ? found : result;
}
export let calcFrameFootMini = (width, depth, weight, ffMiniDevices) => {
    width = parseInt(width)
    depth = parseInt(depth)

    // console.log(width, depth, weight,  ffMiniDevices);
    let result = {
        name: noDeviceMessage,
        rotated: false,
    };
    let acceptable = '';
    let rotated = false;
    //jeżeli el
    if (depth < 450 && depth < width) {
        let temp = depth;
        depth = width;
        width = temp;
        rotated = true;
    }
    let found = ffMiniDevices.find(device => 
        device.minWidth <= width && 
        device.maxWidth >= width && 
        device.minDepth <= depth &&
        device.maxDepth >= depth &&
        device.weight >= weight)
    // console.log('calc ffmini', found)
    if(found) {
        found.rotated = rotated;
    }
    return found ? found : result;
}

export let calcRB = (width, depth, weight, rbWidth, rbDevices) => {
    width = parseInt(width)
    depth = parseInt(depth)
    rbWidth = parseInt(rbWidth)

    let count = Math.floor(width / 1200) + 1;
    if (width % 1200 > 0) {
        count++;  
    };
    // console.log(width, depth, weight, rbDevices);
    let result = {name: noDeviceMessage};
    const found = rbDevices.find(device => 
        device.depth - 80 >= depth && 
        device.weight*count >= weight)
    if(found) {
        found.count = count;
    }
    // console.log('rb ', found)
    return found ? found : result;
}

export let calcRiser = (width, depth, weight, rbWidth, rbDevices) => {
    width = parseInt(width)
    depth = parseInt(depth)
    rbWidth = parseInt(rbWidth)

    let count = Math.floor(width / 1200) + 1;
    if (width % 1200 > 0) {
        count++;  
    };
    // console.log(width, depth, weight, rbDevices);
    let result = {name: noDeviceMessage};
    const found = rbDevices.find(device => 
        device.depth -80 >= depth && 
        device.weight*count >= weight)
    if(found) {
        found.count = count;
    }
    // console.log('rb ', found)
    return found ? found : result;
}

export let calcBasePad = (width, depth, weight, basePadDevices) => {
    return {name: noDeviceMessage};
}