import { useState, useCallback } from 'react'
import { BASE_REQUESTS } from '../../constans/requests';
import { useHistory } from "react-router-dom";

export const initialRegistrationState = {
  lastname: '',
  firstname: '',
  email: '',
  phoneNumber: '',
  city: '',
  country: '',
  street: '',
  postCode: '',
  NIP: '',
  companyName: '',
}

const resultInitialState = {
  success: false,
  error: false,
}

export const RegistrationWrapper = ({ render, ...props }) => {
  const [state, setValue] = useState({ ...initialRegistrationState, confirmPassword: '', password: ''});
  const [resultState, setResultState] = useState(resultInitialState);
  const history = useHistory();

  const setAnyValue = e => {
    setValue({
      ...state,
      [e.target.name]: e.target.value
    })
  }

  const clearState = () => {
    setValue({ ...initialRegistrationState, confirmPassword: '', password: '' });
    setResultState({ ...resultInitialState })
  }


  const onSubmit = useCallback((e) => {
    const body = { ...state };
    delete body.confirmPassword;
    
    e.preventDefault();

    fetch(BASE_REQUESTS.POST.SIGN_UP, {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ ...body })
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          setResultState({ ...resultState, success: data.success });

          // your code here...
          // if you need to set token you can use data.token
          history.push('/calc')

          clearState();
        }

        if (!data.success) {
          setResultState({ ...resultState, error: data.message });
          if(data.clear) {
            sessionStorage.removeItem('SESSION_ID')
            history.push("/")
          }
          // your code here...
        }
      })
      .catch(error => console.log('error', error));

  }, [state, resultState, history]);

  return render({ resultState, state, onSubmit, setAnyValue, clearState, ...props })
}
