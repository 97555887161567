import React from "react";
import useLoginAPIActions from "./services/LoginWrapper";

const Login = (props) => {
  const { email, logIn, password } = props;
  const { onSubmit, setAnyValue } = useLoginAPIActions({ logIn });

  return (
    <form onSubmit={onSubmit}>
      <div>
        <label>Email</label>
        <input
          name="email"
          type="text"
          value={email}
          // placeholder="email"
          onChange={setAnyValue}
        />
      </div>
      <div>
        <label>Hasło</label>
        <input
          name="password"
          type="password"
          value={password}
          // placeholder="hasło"
          onChange={setAnyValue}
        />
      </div>
      {/* <div>
            <input
                name='confirmPassword'
                type="password"
                value={confirmPassword}
                placeholder="powtórz hasło"
                onChange={setAnyValue}
            />
        </div> */}
      <div>
        <button type={"submit"} className="buttonLogin">
          Zaloguj
        </button>
      </div>
    </form>
  );
};

export default Login;
