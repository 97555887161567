import '../style.css'; 
import {drawDevice, drawCube, getHeight} from '../utils/draw'


export const CanvasSmall = ({ width, depth, height}) => {
    // drawDevice("CanvasSmall", width, depth, height);
    // console.log(width, depth, height)
    if(document.getElementById("CanvasSmall")) {
    // drawCube("CanvasSmall",300, 300,  width, depth, height)
    }
    const canvasHeight = getHeight(width, depth, height) + 30;
    return(        
        <div>
            <canvas className="canvas" id="CanvasSmall" width="140" height="120"/>
        </div>
)
}; 