import { useState, useCallback } from "react";
import { useHistory } from "react-router";
import { CUSTOMER_REQUESTS } from "../../constans/requests";
import { getAuthHeaders } from "../../utils/utils";

const initialState = {
  price: "",
  choosenId: "",
  choosenType: "",
  height: "",
  width: "",
  depth: "",
  baseHeight: "",
  weight: "",
  project: "",
  success: false,
  error: false,
};
export const useCalculationAPIActions = (calculations) => {
  const [state, setValue] = useState(initialState);
  const history = useHistory();

  const setAnyValue = (valuesToChange) => {
    setValue({
      ...state,
      ...valuesToChange,
    });
  };

  const clearState = () => {
    setValue({ ...initialState });
  };

  const deleteCalculation = useCallback(
    (calculationId) => {
      fetch(CUSTOMER_REQUESTS.DELETE.DELETE_CALCULATION, {
        method: "DELETE",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: {
          ...getAuthHeaders(),
        },
        body: JSON.stringify({ id: calculationId }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.success) {
            setValue({ ...state, success: data.success });
          }

          if (!data.success) {
            setValue({ ...state, error: data.message });
          }
        })
        .catch((error) => console.log("error", error));
    },
    [state]
  );

  const deleteManyCalculations = useCallback(
    (calculationIds, project, handleSuccess) => {
      fetch(CUSTOMER_REQUESTS.DELETE.DELETE_MANY_CALCULATIONS, {
        method: "DELETE",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: {
          ...getAuthHeaders(),
        },
        body: JSON.stringify({ calculationIds, project }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.success) {
            setValue({ ...state, success: data.success });
            handleSuccess && handleSuccess();
          }

          if (data.token) {
            sessionStorage.setItem("SESSION_ID", data.token);
          }

          if (!data.success) {
            setValue({ ...state, error: data.message });
            if (data.clear) {
              sessionStorage.removeItem("SESSION_ID");
              history.push("/");
            }
          }
        })
        .catch((error) => console.log("error", error));
    },
    [state, history]
  );

  const createCalculationData = useCallback(
    (projectId) => {
      const { currentCalculation, currentProject } = calculations || {};
      const copiedCurrentCalculation = { ...currentCalculation };

      const results = {
        baseName:
          copiedCurrentCalculation && copiedCurrentCalculation.result
            ? copiedCurrentCalculation.result.name
            : "",
        price:
          copiedCurrentCalculation && copiedCurrentCalculation.result
            ? copiedCurrentCalculation.result.price
            : "",
      };
      delete copiedCurrentCalculation.result;
      delete copiedCurrentCalculation.calculationDate;
      delete copiedCurrentCalculation.calculationId;

      if (copiedCurrentCalculation) {
        return {
          ...copiedCurrentCalculation,
          project: projectId || currentProject,
          ...results,
          deviceName: copiedCurrentCalculation.deviceName
            ? copiedCurrentCalculation.deviceName
            : `${copiedCurrentCalculation.height}x${copiedCurrentCalculation.width}x${copiedCurrentCalculation.depth} (mm) / ${copiedCurrentCalculation.weight} kg`,
        };
      }

      return {};
    },
    [calculations]
  );

  const addCalculation = useCallback(
    (projectId) => {
      const calculationsData = createCalculationData(projectId);
      
      fetch(CUSTOMER_REQUESTS.POST.ADD_CALCULATION, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: {
          ...getAuthHeaders(),
        },
        body: JSON.stringify({ ...calculationsData }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.success) {
            setValue({ ...state, success: data.success });
            clearState();
            history.push("/calcList");
          }

          if (data.token) {
            sessionStorage.setItem("SESSION_ID", data.token);
          }

          if (!data.success) {
            setValue({ ...state, error: data.message });
            if (data.clear) {
              sessionStorage.removeItem("SESSION_ID");
              history.push("/");
            }
          }
        })
        .catch((error) => console.log("error", error));
    },
    [state, createCalculationData, history]
  );

  const getUserCalculations = useCallback(
    (take, skip) => {
      fetch(CUSTOMER_REQUESTS.POST.GET_USER_CALCULATION, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: {
          ...getAuthHeaders(),
        },
        body: JSON.stringify({ skip, take }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.success) {
            setValue({ ...state, success: data.success });
          }

          if (!data.success) {
            setValue({ ...state, error: data.message });
            if (data.clear) {
              sessionStorage.removeItem("SESSION_ID");
              history.push("/");
            }
          }
        })
        .catch((error) => console.log("error", error));
    },
    [state, history]
  );

  const getCalculationsOfProject = useCallback(
    (projectId, take, skip) => {
      fetch(CUSTOMER_REQUESTS.POST.GET_ALL_CALLCULATIONS_OF_PROJECT, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ projectId, skip, take }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.success) {
            setValue({ ...state, success: data.success });
          }

          if (!data.success) {
            setValue({ ...state, error: data.message });
            if (data.clear) {
              sessionStorage.removeItem("SESSION_ID");
              history.push("/");
            }
          }
        })
        .catch((error) => console.log("error", error));
    },
    [state, history]
  );

  const updateCalculation = useCallback(
    (currentCalculation) => {
      const copiedCurrentCalculation = { ...currentCalculation };
      delete copiedCurrentCalculation.calculationId;
      delete copiedCurrentCalculation.id;

      const { calculationId } = currentCalculation;
      const optionsToChange = {
        ...createCalculationData("to-remove"),
      };

      delete optionsToChange.project;
      delete optionsToChange.id;

      fetch(CUSTOMER_REQUESTS.PUT.UPDATE_CALCULATION, {
        method: "PUT",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: {
          ...getAuthHeaders(),
        },
        body: JSON.stringify({
          id: calculationId,
          optionsToChange: {
            ...optionsToChange,
          },
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.success) {
            setValue({ ...state, success: data.success });
            history.push("/calcList");
          }

          if (!data.success) {
            setValue({ ...state, error: data.message });
            if (data.clear) {
              sessionStorage.removeItem("SESSION_ID");
              history.push("/");
            }
          }
        })
        .catch((error) => console.log("error", error));
    },
    [createCalculationData, history, state]
  );

  return {
    state,
    addCalculation,
    deleteCalculation,
    setAnyValue,
    clearState,
    getUserCalculations,
    getCalculationsOfProject,
    deleteManyCalculations,
    updateCalculation,
  };
};
