import React from 'react';

export const CalculatorView = ({
    selectProducer,
    producersList,
    selectName,
    namesList,
    selectSeries,
    seriesList,
    selectCode,
    codesList,
    height,
    width,
    depth,
    weight,
    currentResult,
    results,
    heights,
    edit,
}) => (
    <>
<div className="calculatorContent">
          <label className="labelFull">
            Nazwa urządzenia
          </label>
          <div className="devicesDiv">
            <label className="labelSection labelSection-inactive">Wg producenta</label>
            <div className="dropDown">
              <label>Producent</label>
              <label>&nbsp;</label>
            </div>
            <div className="dropDown">
              <label>Nazwa</label>
              <label>&nbsp;</label>
            </div>
            <div className="dropDown">
              <label>Seria</label>
              <label>&nbsp;</label>
            </div>
            <div className="dropDown">
              <label>Kod</label>
              <label>&nbsp;</label>
            </div>
            </div>
          <div className="calculator">
            <label className="labelSection labelSection-inactive">Wg wymiarów</label>
            <div className="input">
              <label className="label">h (mm)</label>
              <label>{height}</label> 
            </div>
            <div className="input">
              <label className="label">a (mm)</label>
              <label>{width}</label>
            </div>
            <div className="input"> 
              <label className="label">b (mm)</label>
              <label>{depth}</label>
            </div>
            <div className="input">
              <label className="label">waga (kg)</label>
              <label>{weight}</label>
            </div>
          </div>
          <label className="labelFull">
            Dobrana podstawa 
          </label>
          {/* <div className="dropDown calcParam">
              <label>Wysokość podstawy</label>
              <label>&nbsp;</label>
            </div> */}
          <div className="dropDown calcParam">
              {/* <label>Rodzaj podstawy</label> */}
              <label>{results.find(result => result.id == currentResult)? results.find(result => result.id == currentResult).value : ''}</label>
            </div>
          </div>
          <div className="dropDown calcParam"></div>  
          {currentResult === "3" ? (
            <div className="extraParams">
              {/* <div className="param">
                <label className="label">szerokość podstawy</label>
                <select
                  className="input"
                  // name="weight"
                  // value={this.props.calculations.weight}
                  onChange={e => this.props.setRbWidth(e.target.value)}
                >
                  <option value="130">RB 130</option>
                  <option value="180">RB 180</option>
                </select>
              </div> */}
              
              {/* {parseInt(depth)>=600 && rbWidth==="180"? 
                <div className="">
                <label className="label">Wysokość podstawy</label>
                <select
                  className="input"
                  // name="weight"
                  // value={this.props.calculations.weight}
                  onChange={e => this.props.setRbHeight(e.target.value)}
                >
                  <option value="1">Standardowa</option>
                  <option value="2">Podwyższona</option>
                </select>
              </div>
              : ''} */}
            </div>
            )
          : null
          }
          {/* <div className="calculate">
            <button onClick={edit}>edytuj</button>
          </div> */}
      </>
);