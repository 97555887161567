import { connect } from "react-redux";
import React, { useEffect } from "react";
import "../style.css";
import { AccountWrapper } from "./../components/services/AccountWrapper";

import { setAccount, logIn } from "../actions/accountAction";
import useLoginAPIActions from "../components/services/LoginWrapper";
import { AccountEdit } from "../components/AccountEdit";
import { AccountView } from "../components/AccountView";

class Account extends React.Component {
  constructor() {
    super();
    this.state = {
      editMode: false,
    };
    this.setEditMode = this.setEditMode.bind(this);
  }

  setEditMode(editMode) {
    this.setState({
      editMode: editMode,
    });
  }

  render() {
    const {
      updateAccount,
      setValue,
      account: {
        companyName,
        city,
        country,
        postCode,
        street,
        lastname,
        firstname,
        phoneNumber,
        email,
        discount,
        NIP,
      },
    } = this.props;

    return (
      <div className="background-account">
        {this.state.editMode ? (
          <AccountEdit
            companyName={companyName}
            city={city}
            country={country}
            postCode={postCode}
            street={street}
            lastname={lastname}
            firstname={firstname}
            phoneNumber={phoneNumber}
            email={email}
            discount={discount}
            NIP={NIP}
            setValue={setValue}
            setEditMode={this.setEditMode}
            updateAccount={updateAccount}
          />
        ) : (
          <AccountView
            companyName={companyName}
            city={city}
            country={country}
            postCode={postCode}
            street={street}
            lastname={lastname}
            firstname={firstname}
            phoneNumber={phoneNumber}
            email={email}
            discount={discount}
            NIP={NIP}
            setValue={setValue}
            setEditMode={this.setEditMode}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    account: state.accountReducer.user,
  };
};

const mapDispatchToProps = {
  setAccount,
  logIn,
};

const AccountWithWrapper = ({ account, setAccount }) => {
  return (
    <AccountWrapper
      setAccount={setAccount}
      user={account}
      render={({ updateAccount, setAnyValue, state }) => (
        <Account
          updateAccount={updateAccount}
          setValue={setAnyValue}
          account={state}
        />
      )}
    />
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountWithWrapper);
