import * as CONST from "../constans/const";

export function setHeight(height) {
  return {
    type: CONST.SET_HEIGHT,
    payload: height,
  };
}
export function setWidth(width) {
  return {
    type: CONST.SET_WIDTH,
    payload: width,
  };
}
export function setDepth(depth) {
  return {
    type: CONST.SET_DEPTH,
    payload: depth,
  };
}
export function setWeight(weight) {
  return {
    type: CONST.SET_WEIGHT,
    payload: weight,
  };
}
export function setRbWidth(rbWidth) {
  return {
    type: CONST.SET_RB_WIDTH,
    payload: rbWidth,
  };
}
export function setRbHeight(rbHeight) {
  return {
    type: CONST.SET_RB_HEIGHT,
    payload: rbHeight,
  };
}
export function setFfHeight(ffHeight) {
  return {
    type: CONST.SET_FF_HEIGHT,
    payload: ffHeight,
  };
}
export function setCurrentResult(currentResult) {
  return {
    type: CONST.SET_CURRENT_RESULT,
    payload: currentResult,
  };
}

export function setDevice(device) {
  return {
    type: CONST.SET_DEVICE,
    payload: device,
  };
}

export function saveCalculation() {
  return {
    type: CONST.SAVE_CALCULATION,
  };
}
export function actualizeCalculation(calculationId) {
  return {
    type: CONST.ACTUALIZE_CALCULATION,
    payload: calculationId,
  };
}

export function createProject(projectName) {
  return {
    type: CONST.CREATE_PROJECT,
    payload: projectName,
  };
}

export function createProjectWithCalculation(projectName) {
  return {
    type: CONST.CREATE_PROJECT_WITH_CALCULATION,
    payload: projectName,
  };
}

export function setCurrentProject(projectId) {
  return {
    type: CONST.SET_CURRENT_PROJECT,
    payload: projectId,
  };
}

export function setCalculationName(name) {
  return {
    type: CONST.SET_CALCULATION_NAME,
    payload: name,
  };
}
export function setCalculationDate(date) {
  return {
    type: CONST.SET_CALCULATION_DATE,
    payload: date,
  };
}

export function setResult(result) {
  return {
    type: CONST.SET_RESULT,
    payload: result,
  };
}

export function selectCalculation(calculation) {
  return {
    type: CONST.SELECT_CALCULATION,
    payload: calculation,
  };
}

export function resetCalculation() {
  return {
    type: CONST.RESET_CALCULATION,
  };
}
export function removeProject(id) {
  return {
    type: CONST.REMOVE_PROJECT,
    payload: id,
  };
}
export function removeCalculations(project, calculations) {
  return {
    type: CONST.REMOVE_CALCULATIONS,
    payload: {
      project: project,
      calculations: calculations,
    },
  };
}

export function setCurrentCalculation(projectId, calculationId) {
  return {
    type: CONST.SET_CURRENT_CALCULATION,
    payload: {
      projectId: projectId,
      calculationId: calculationId,
    },
  };
}

export function saveCalculationToProject(projectId, calculation) {
    return {
        type: CONST.SAVE_CALCULATION_TO_PROJECT,
        payload: {
            projectId: projectId,
            calculation: calculation,
        }
    }
}

export function copyCalculation(calculation) {
  return {
    type: CONST.COPY_CALCULATION,
    payload: calculation,
  };
}

export function loadCustomerProjects(projects) {
  return {
    type: CONST.LOAD_CUSTOMER_PROJECTS,
    payload: projects,
  };
}
